import { TaskType } from '../types/taskCard'

export const taskList: TaskType[] = [
  {
    id: 0,
    platform: 2,
    customer: 'aaa',
    customerId: 122,
    orderId: '23',
    sendDate: '2023-10-18 13:22:44',
    orderName: 'aaa',
    orderNo: 'aaa',
    deliveryTime: '剩余1天',
    hasPaiban: false,
    isNeedBujian: false,
    isInBatchList: false,
    isRefused: false,
    batchNumber: 'aaa',
  },
  {
    id: 1,
    platform: 2,
    customer: 'bbb',
    customerId: 122,
    orderId: '23',
    sendDate: '2023-10-18 13:22:44',
    orderName: 'bbb',
    orderNo: 'bbb',
    deliveryTime: '剩余3天',
    hasPaiban: true,
    isNeedBujian: true,
    isRefused: false,
    isInBatchList: false,
    batchNumber: 'bbb',
  },
  {
    id: 2,
    platform: 2,
    customer: 'ccc',
    customerId: 122,
    orderId: '23',
    sendDate: '2023-10-18 13:22:44',
    orderName: 'ccc',
    orderNo: 'ccc',
    deliveryTime: '剩余4天',
    hasPaiban: true,
    isNeedBujian: false,
    isRefused: true,
    isInBatchList: false,
    batchNumber: 'ccc',
  },
  {
    id: 3,
    platform: 2,
    customer: 'ccc',
    customerId: 122,
    orderId: '23',
    sendDate: '2023-10-18 13:22:44',
    orderName: 'ccc',
    orderNo: 'ccc',
    deliveryTime: '剩余4天',
    hasPaiban: true,
    isNeedBujian: false,
    isRefused: true,
    isInBatchList: false,
    batchNumber: 'ccc',
  },
  {
    id: 4,
    platform: 2,
    customer: 'aaa',
    customerId: 122,
    orderId: '23',
    sendDate: '2023-10-18 13:22:44',
    orderName: 'aaa',
    orderNo: 'aaa',
    deliveryTime: '剩余1天',
    hasPaiban: false,
    isNeedBujian: false,
    isInBatchList: true,
    isRefused: false,
    batchNumber: 'aaa',
  },
  {
    id: 5,
    platform: 2,
    customer: 'ccc',
    customerId: 122,
    orderId: '23',
    sendDate: '2023-10-18 13:22:44',
    orderName: 'ccc',
    orderNo: 'ccc',
    deliveryTime: '剩余4天',
    hasPaiban: true,
    isNeedBujian: false,
    isInBatchList: false,
    isRefused: true,
    batchNumber: 'ccc',
  },
  {
    id: 6,
    platform: 2,
    customer: 'aaa',
    sendDate: '2023-10-18 13:22:44',
    orderName: 'aaa',
    customerId: 122,
    orderId: '23',
    orderNo: 'aaa',
    deliveryTime: '剩余1天',
    hasPaiban: false,
    isNeedBujian: false,
    isInBatchList: false,
    isRefused: false,
    batchNumber: 'aaa',
  },
  {
    id: 7,
    platform: 2,
    customer: 'ccc',
    sendDate: '2023-10-18 13:22:44',
    orderName: 'ccc',
    customerId: 122,
    orderId: '23',
    orderNo: 'ccc',
    deliveryTime: '剩余4天',
    hasPaiban: true,
    isNeedBujian: false,
    isInBatchList: true,
    isRefused: true,
    batchNumber: 'ccc',
  },
  {
    id: 8,
    platform: 2,
    customer: 'aaa',
    sendDate: '2023-10-18 13:22:44',
    orderName: 'aaa',
    orderNo: 'aaa',
    customerId: 122,
    orderId: '23',
    deliveryTime: '剩余1天',
    hasPaiban: false,
    isNeedBujian: false,
    isInBatchList: false,
    isRefused: false,
    batchNumber: 'aaa',
  },
  {
    id: 9,
    platform: 2,
    customer: 'aaa',
    sendDate: '2023-10-18 13:22:44',
    orderName: 'aaa',
    orderNo: 'aaa',
    customerId: 122,
    orderId: '23',
    deliveryTime: '剩余1天',
    hasPaiban: false,
    isNeedBujian: false,
    isInBatchList: false,
    isRefused: false,
    batchNumber: 'aaa',
  },
  {
    id: 10,
    platform: 2,
    customer: 'ccc',
    sendDate: '2023-10-18 13:22:44',
    orderName: 'ccc',
    orderNo: 'ccc',
    deliveryTime: '剩余4天',
    hasPaiban: true,
    isNeedBujian: false,
    customerId: 122,
    orderId: '23',
    isInBatchList: false,
    isRefused: true,
    batchNumber: 'ccc',
  },
  {
    id: 11,
    platform: 2,
    customer: 'aaa',
    sendDate: '2023-10-18 13:22:44',
    orderName: 'aaa',
    orderNo: 'aaa',
    customerId: 122,
    orderId: '23',
    deliveryTime: '剩余1天',
    hasPaiban: false,
    isNeedBujian: false,
    isInBatchList: true,
    isRefused: false,
    batchNumber: 'aaa',
  },
]
