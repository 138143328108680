interface Tips {
  shape: 'rect' | 'round'
  bgc: string
  bc: string
  text: string
}

export const defaultTipsArr: Tips[] = [
  {
    shape: 'rect',
    bgc: '#ffff00',
    bc: '#fff',
    text: '异常部分',
  },
  {
    shape: 'round',
    bgc: '#fff',
    bc: 'red',
    text: '正面孔',
  },
  {
    shape: 'round',
    bgc: '#fff',
    bc: '#0000ff',
    text: '反面孔',
  },
  {
    shape: 'rect',
    bgc: '#fff',
    bc: '#f00',
    text: '正面槽',
  },
  {
    shape: 'rect',
    bgc: '#fff',
    bc: '#0000ff',
    text: '反面槽',
  },
]
