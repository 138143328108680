import { message } from 'ant-design-vue'

/**
 * 用于文本复制
 */
export const copyText = (
  text: string,
  successMsg = '复制成功',
  errMsg = '复制失败'
) => {
  try {
    // 检查 Clipboard API(clipboard只能在https环境中使用，且在某些低版本浏览器也无法使用)
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          message.success(successMsg)
        })
        .catch((err) => {
          console.log(errMsg, err)
          message.error(errMsg)
        })
    } else {
      // 回退方案(使用textarea保留换行符)
      const textarea = document.createElement('textarea')
      document.body.appendChild(textarea)
      textarea.value = text
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
      message.success(successMsg)
    }
  } catch (error: any) {
    throw new Error(error)
  }
}
