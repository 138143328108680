<template>
  <div class="home-page">
    <div class="nav">
      <div class="nav-content">
        <!-- <div class="logo">
          <img :src="require('../assets/image/bluenlogo.svg')" alt="" />
          <div class="logo-info">
            <span>省时</span>
            <span>省料</span>
            <span>省力气</span>
          </div>
        </div> -->
        <div class="logo-box">
          <span>云排版</span>
          <span>省时，省料，省力气</span>
        </div>
        <div class="nav-list">
          <span
            :class="activeNav == item.value ? 'active' : ''"
            v-for="(item, index) in navList"
            :key="index"
            @click="changeNav(item)"
            >{{ item.name }}</span
          >
        </div>
      </div>
    </div>
    <div class="top-bar">
      <div>
        <div class="bluen-intro">
          <span class="title">云排版，机器的智能大脑</span>
          <div class="introduction">
            <div class="circle"></div>
            <span>改革行业的云端在线排版优化软件</span>
          </div>
          <div class="introduction">
            <div class="circle"></div>
            <span>智能排版算法获得板材更高利用率</span>
          </div>
          <div class="introduction">
            <div class="circle"></div>
            <span>支持多种贴标机、开料机、侧孔机、五面PTP、五/六面钻对接</span>
          </div>
        </div>
        <div class="bluen-contribution">
          <div class="title">
            <span>云排版优化</span>
            <span>减少人类森林资源的浪费</span>
          </div>
          <div class="composing-statistics">
            <div>
              <span>今日排版：</span>
              <span>{{ dayCount }}张</span>
            </div>
            <div>
              <span>本月排版：</span>
              <span>{{ allCount }}张</span>
            </div>
            <div>
              <span>平均出材率：</span>
              <span>95%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrap" ref="wrapBox">
      <!-- <div class="product-experience">
        <div class="headline-box">
          <span class="headline">产品体验</span>
          <span class="headline-info">
            在线体验版，感受云排版的高效、精准排版方案，正式版功能更齐全！
          </span>
        </div>
        <div class="content">
          <canvas id="example_canvas"></canvas>
        </div>
      </div> -->
      <div class="product-intro" ref="produceIntro">
        <div class="headline-box">
          <span class="headline">产品介绍</span>
          <span class="headline-info">
            低成本，高产值，云排版采用先进的智能排版算法让板件达到更高的利用率
          </span>
        </div>
        <div class="content">
          <div class="content-item">
            <div class="product-title-box">
              <span class="product-title">云端排版，工厂同步生产</span>
              <span class="product-title-info">
                跨越空间阻碍，随时输出订单，随时同步工厂，设计生产同步完成
              </span>
            </div>
            <div class="product-img">
              <img :src="require('../assets/image/云端.png')" alt="" />
            </div>
          </div>
          <div class="content-item">
            <div class="product-title-box">
              <span class="product-title">兼容多款设计软件</span>
              <span class="product-title-info">
                兼容设计软件，接单更方便，设计生产对接更便捷
              </span>
            </div>
            <div class="product-img">
              <img :src="require('../assets/image/云端.png')" alt="" />
            </div>
          </div>
          <div class="content-item">
            <div class="product-title-box">
              <span class="product-title"
                >强大的对接体系，支持多生产线，多类型设备对接</span
              >
              <span class="product-title-info">
                自动上下料机、贴标机、开料机、侧孔机、五面PTP、五/六面钻设备轻松对接
              </span>
            </div>
            <div class="machine">
              <div>
                <img
                  :src="require('../assets/image/自动上下料机.jpg')"
                  alt=""
                />
                <span>自动上下料机</span>
              </div>
              <div>
                <img :src="require('../assets/image/贴标机.png')" alt="" />
                <span>贴标机</span>
              </div>
              <div>
                <img :src="require('../assets/image/开料机.jpg')" alt="" />
                <span>开料机</span>
              </div>
              <div>
                <img :src="require('../assets/image/侧孔机.jpg')" alt="" />
                <span>侧孔机</span>
              </div>
              <div>
                <img :src="require('../assets/image/ptp.jpg')" alt="" />
                <span>五面PTP</span>
              </div>
              <div>
                <img :src="require('../assets/image/五六面钻.jpg')" alt="" />
                <span>五六面钻</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="solution" ref="solutionMethods">
        <div class="headline-box">
          <span class="headline">解决方案</span>
          <span class="headline-info">
            云排版为您提供高效的生产方案，效率就是金钱！
          </span>
        </div>
        <div class="content">
          <div class="solution-item">
            <div class="left-trapezoid">
              <span class="prev-title">传统生产</span>
              <div class="solution-img">
                <img :src="require('../assets/image/传统生产1.png')" alt="" />
              </div>
              <div class="solution-info">
                <img :src="require('../assets/icon/叉.png')" alt="" />
                <span>设计生产人员必须都在工厂</span>
              </div>
            </div>
            <div class="arrows-box">
              <img
                :src="require('../assets/image/double_right_arrows.png')"
                alt=""
              />
            </div>
            <div class="right-trapezoid">
              <span class="now-title">云排版生产</span>
              <div class="solution-img">
                <img :src="require('../assets/image/班鲁生产1.png')" alt="" />
              </div>
              <div class="solution-info">
                <img :src="require('../assets/icon/勾.png')" alt="" />
                <span>远程接收生产订单工厂直接生产</span>
              </div>
            </div>
          </div>
          <div class="solution-item">
            <div class="left-trapezoid">
              <span class="prev-title">传统生产</span>
              <div class="solution-img">
                <img :src="require('../assets/image/传统生产2.png')" alt="" />
              </div>
              <div class="solution-info">
                <img :src="require('../assets/icon/叉.png')" alt="" />
                <span>多生产线时切换设置繁杂无比</span>
              </div>
            </div>
            <div class="arrows-box">
              <img
                :src="require('../assets/image/double_right_arrows.png')"
                alt=""
              />
            </div>
            <div class="right-trapezoid">
              <span class="now-title">云排版生产</span>
              <div class="solution-img special">
                <img :src="require('../assets/image/班鲁生产2.png')" alt="" />
              </div>
              <div class="solution-info">
                <img :src="require('../assets/icon/勾.png')" alt="" />
                <span>生产线切换一键搞定</span>
              </div>
            </div>
          </div>
          <div class="solution-item">
            <div class="left-trapezoid">
              <span class="prev-title">传统生产</span>
              <div class="solution-img">
                <img :src="require('../assets/image/传统生产3.png')" alt="" />
              </div>
              <div class="solution-info">
                <img :src="require('../assets/icon/叉.png')" alt="" />
                <span>板材利用率低浪费严重</span>
              </div>
            </div>
            <div class="arrows-box">
              <img
                :src="require('../assets/image/double_right_arrows.png')"
                alt=""
              />
            </div>
            <div class="right-trapezoid">
              <span class="now-title">云排版生产</span>
              <div class="solution-img">
                <img :src="require('../assets/image/班鲁生产3.png')" alt="" />
              </div>
              <div class="solution-info">
                <img :src="require('../assets/icon/勾.png')" alt="" />
                <span>智能排版算法让板材不再浪费</span>
              </div>
            </div>
          </div>
          <div class="solution-item">
            <div class="left-trapezoid">
              <span class="prev-title">传统生产</span>
              <div class="solution-img">
                <img :src="require('../assets/image/传统生产3.png')" alt="" />
              </div>
              <div class="solution-info">
                <img :src="require('../assets/icon/叉.png')" alt="" />
                <span>下料切割慢，且易跑板</span>
              </div>
            </div>
            <div class="arrows-box">
              <img
                :src="require('../assets/image/double_right_arrows.png')"
                alt=""
              />
            </div>
            <div class="right-trapezoid">
              <span class="now-title">云排版生产</span>
              <div class="solution-img specials">
                <img :src="require('../assets/image/班鲁生产4.png')" alt="" />
              </div>
              <div class="solution-info specials">
                <img :src="require('../assets/icon/勾.png')" alt="" />
                <span>专业切割方案，让路径更短，切割更稳</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="equipment-brand">
        <div class="headline-box">
          <span class="headline">已对接设备品牌</span>
          <span class="headline-info"> 对接品牌覆盖90%国内工厂常用设备 </span>
        </div>
        <div class="content">
          <img :src="require('../assets/image/南兴.png')" alt="" />
          <img :src="require('../assets/image/excitech.png')" alt="" />
          <img :src="require('../assets/image/拓雕.png')" alt="" />
          <img :src="require('../assets/image/桦桦数控.png')" alt="" />
          <img :src="require('../assets/image/豪德.png')" alt="" />
          <img :src="require('../assets/image/极东.png')" alt="" />
          <img :src="require('../assets/image/sandar.png')" alt="" />
          <img :src="require('../assets/image/耐锐.png')" alt="" />
        </div>
      </div>
      <div class="cooperation">
        <div class="headline-box">
          <span class="headline">合作案例</span>
          <span class="headline-info">
            {{ cooperationCount }}位商家与我们同行
          </span>
        </div>
        <div class="content">
          <img
            :src="require('../' + items)"
            alt=""
            v-for="(items, indexs) in brandList"
            :key="indexs + 2"
          />
        </div>
      </div>
      <div class="service">
        <div v-for="(item, index) in serviceList" :key="index">
          <div class="service-circle">
            <img :src="require('../' + item.icon)" alt="" />
          </div>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <bluenFooter></bluenFooter>
  </div>
</template>

<script>
import bluenFooter from '@/components/footer.vue'
import axios from 'axios'

export default {
  components: {
    bluenFooter,
  },
  data() {
    return {
      serviceList: [
        { name: '专业团队维护', icon: 'assets/icon/团队.png' },
        { name: '持续更新迭代', icon: 'assets/icon/云上传.png' },
        { name: '一对一售后服务', icon: 'assets/icon/客服.png' },
        { name: '提供功能定制服务', icon: 'assets/icon/钻石.png' },
      ],
      // 导航栏
      navList: [
        { name: '首页', path: '/', value: 'home' },
        { name: '产品介绍', path: '', value: 'intro' },
        { name: '解决方案', path: '', value: 'solution' },
        { name: '关于我们', path: '', value: 'about' },
      ],
      brandList: [
        'assets/cooperation/brand(17).png',
        'assets/cooperation/brand(2).png',
        'assets/cooperation/brand(3).png',
        'assets/cooperation/brand(4).png',
        'assets/cooperation/brand(5).png',
        'assets/cooperation/brand(6).png',
        'assets/cooperation/brand(7).png',
        'assets/cooperation/brand(8).png',
        'assets/cooperation/brand(9).png',
        'assets/cooperation/brand(10).png',
        'assets/cooperation/brand(11).png',
        'assets/cooperation/brand(12).png',
        'assets/cooperation/brand(13).png',
        'assets/cooperation/brand(14).png',
        'assets/cooperation/brand(15).png',
      ],
      // 记录每日排版数量
      dayCount: 0,
      // 记录总共排版数量
      allCount: 0,
      // 画布
      exampleCanvas: {},
      // 记录商家合作数量
      cooperationCount: 0,
      // 记录选中的导航栏
      activeNav: 'home',
    }
  },
  methods: {
    // 切换导航
    changeNav(item) {
      if (item.value == 'home') {
        // return
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
      if (item.value == 'intro') {
        let proIntro = this.$refs.produceIntro
        window.scrollTo({
          top: proIntro.offsetTop - 60,
          behavior: 'smooth',
        })
      }
      if (item.value == 'solution') {
        let solution = this.$refs.solutionMethods
        window.scrollTo({
          top: solution.offsetTop - 60,
          behavior: 'smooth',
        })
      }
      if (item.value == 'about') {
        this.$message({
          message: '开发中',
          offset: 80,
        })
        return
      }
      this.activeNav = item.value
    },
    // 获取每日和当月的排版数量
    getPaibanData() {
      let date = new Date()
      let nowDay = date.getDate()
      // 你可能会迷惑, 为啥要这样处理, 因为接口最多只能统计30天
      let nowDate = 0
      if (nowDay == 31) {
        nowDate = 30
      } else {
        nowDate = nowDay
      }
      this.$core
        .get('https://ggtools.thinkerx.com/statistics_paiban', {
          params: {
            uid: 0,
            recent_day: nowDay,
          },
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.allCount = res.data.data.paiban_count
          }
        })
      this.$core
        .get('https://ggtools.thinkerx.com/statistics_paiban', {
          params: {
            uid: 0,
            recent_day: 1,
          },
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.dayCount = res.data.data.paiban_count
          }
        })
    },
    // 获取合作商家数量
    getCooperation() {
      this.$core
        .get('https://guigui.admin.thinkerx.com/eggrj/user_count')
        .then((res) => {
          this.cooperationCount = res.data.data.res
        })
    },
    drawExample() {
      this.exampleCanvas = new fabric.Canvas('example_canvas', {})
      this.drawPart()
    },
    drawPart() {},
  },
  mounted() {
    this.getPaibanData()
    this.drawExample()
    this.getCooperation()
  },
}
</script>

<style scoped lang="less">
.home-page {
  width: 100%;
  min-width: 1280px;
  background: #eaf1f7;
  .nav {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    background: #eaf1f7;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.15);
    z-index: 9999;
    .nav-content {
      width: 1280px;
      margin: 0 auto;
      display: flex;
    }
    .logo {
      width: 120px;
      height: 60px;
      display: flex;
      flex-direction: column;
      margin-right: 87px;
      img {
        width: 100%;
      }
      .logo-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
      }
    }
    .logo-box {
      margin-right: 87px;
      span {
        display: block;
        color: #2e2e2e;
      }
      span:first-child {
        font-size: 20px;
        color: #2e2e2e;
        font-weight: 600;
      }
      span:last-child {
        font-size: 12px;
        color: #2e2e2e;
      }
    }
    .nav-list {
      span {
        line-height: 48px;
        display: inline-block;
        margin-right: 38px;
        font-size: 16px;
        font-weight: 600;
        width: 90px;
        border-bottom: 4px solid transparent;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
      }
      .active {
        color: #18a8c7;
        border-color: #18a8c7;
      }
    }
  }
  .top-bar {
    width: 100%;
    min-width: 1280px;
    height: 486px;
    // background: linear-gradient(168deg, #47e0c5, #14bfc7);
    background: url('../assets/image/banner.jpg') no-repeat;
    background-size: 100% 100%;
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 1280px;
      width: 1280px;
      margin: 0 auto;
      padding-top: 82px;
      box-sizing: border-box;
      .bluen-intro {
        .title {
          font-size: 40px;
          color: #fff;
        }
        .introduction {
          display: flex;
          align-items: center;
          .circle {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            border: 1px solid #fff;
            margin-right: 6px;
            background: transparent;
          }
          span {
            font-size: 20px;
            color: #fff;
          }
        }
      }
      .bluen-contribution {
        width: 320px;
        height: 280px;
        border-radius: 8px;
        border: 2px solid #fff;
        background: rgba(255, 255, 255, 0.2);
        text-align: center;
        .title {
          font-size: 20px;
          color: #fff;
          margin-top: 18px;
          & > span {
            display: block;
            &:last-child {
              font-size: 14px;
              margin-bottom: 8px;
            }
          }
        }
        .composing-statistics {
          width: 280px;
          height: 162px;
          background: #fff;
          border-radius: 8px;
          margin: 0 auto;
          padding: 16px 15px 0 16px;
          & > div {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            span:first-child {
              display: inline-block;
              text-align: right;
              width: 84px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.6);
              font-weight: 500;
            }
            span:last-child {
              display: inline-block;
              text-align: left;
              font-size: 24px;
              color: #18a8c7;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .wrap {
    min-width: 1280px;
    width: 1280px;
    // overflow: hidden;
    margin: 0 auto;
    padding: 40px 0;
    .headline-box {
      width: 100%;
      margin-bottom: 24px;
      .headline {
        color: #000;
        font-size: 34px;
        font-weight: 600;
        display: block;
        margin-bottom: 8px;
      }
      .headline-info {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500;
      }
    }
    .product-experience {
      width: 100%;
      .content {
        width: 100%;
        height: 354px;
        margin-bottom: 40px;
        background: #fff;
        border-radius: 4px;
        canvas {
          width: 100%;
          height: 100%;
        }
      }
    }
    .product-intro {
      width: 100%;
      .content {
        width: 100%;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        .content-item {
          width: 625px;
          background: #fff;
          padding: 32px;
          box-sizing: border-box;
          &:last-child {
            width: 100%;
            height: 355px;
            margin-top: 32px;
            .machine {
              display: flex;
              align-items: center;
              justify-content: space-between;
              & > div {
                display: flex;
                flex-direction: column;
                text-align: center;
                img {
                  width: 184px;
                  height: 150px;
                  margin-bottom: 24px;
                }
                span {
                  font-size: 18px;
                  color: #000;
                  font-weight: 500;
                }
              }
            }
          }
          .product-title-box {
            margin-bottom: 16px;
            .product-title {
              font-size: 22px;
              color: rgba(0, 0, 0, 0.8);
              font-weight: 500;
              display: block;
              margin-bottom: 8px;
            }
            .product-title-info {
              font-size: 14px;
              color: rgba(0, 0, 0, 0.6);
            }
          }
          .product-img {
            height: 376px;
          }
        }
      }
    }
    .solution {
      width: 100%;
      .content {
        width: 100%;
        margin-bottom: 8px;
        .solution-item {
          position: relative;
          min-height: 432px;
          margin-bottom: 32px;
          .left-trapezoid {
            width: 661px;
            min-height: 353px;
            position: absolute;
            left: 0;
            top: 0;
            padding: 24px;
            box-sizing: border-box;
            background: url('../assets/image/leftTrapezoid.svg') no-repeat;
            .prev-title {
              font-size: 24px;
              color: rgba(0, 0, 0, 0.6);
              margin-bottom: 20px;
              display: inline-block;
            }
            .solution-img {
              padding-left: 125px;
              margin-bottom: 24px;
            }
            .solution-info {
              width: 400px;
            }
          }
          .arrows-box {
            position: absolute;
            top: 50%;
            left: 45%;
            transform: translate(-50%, -50%);
            width: 100px;
            height: 70px;
            z-index: 2;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .right-trapezoid {
            width: 781px;
            min-height: 381px;
            position: absolute;
            right: 0;
            top: 54px;
            padding: 24px 48px;
            box-sizing: border-box;
            background: url('../assets/image/rightTrapezoid.png') no-repeat;
            text-align: right;
            background-size: 100% 100%;
            .now-title {
              font-size: 24px;
              color: #18a8c7;
              margin-bottom: 30px;
              display: inline-block;
            }
            .solution-img {
              box-sizing: border-box;
              margin-bottom: 32px;
              text-align: center;
              &.special {
                margin-left: 70px;
                img {
                  margin-bottom: 43px;
                }
              }
              &.specials {
                margin-left: 135px;
                img {
                  height: 200px;
                }
              }
            }
            .solution-info {
              text-align: center;
              &.specials {
                padding-left: 70px;
                box-sizing: border-box;
              }
            }
          }
          .solution-info {
            color: rgba(0, 0, 0, 0.8);
            font-size: 24px;
            font-weight: 500;
            display: inline-block;
            box-sizing: border-box;
            text-align: center;
            width: 100%;
            box-sizing: border-box;
            img {
              width: 24px;
              height: 24px;
              margin-right: 16px;
            }
          }
        }
      }
    }
    .equipment-brand {
      width: 100%;
      .content {
        height: 342px;
        margin-bottom: 40px;
        background: #fff;
        display: flex;
        flex-wrap: wrap;
      }
    }
    .cooperation {
      width: 100%;
      .content {
        margin-bottom: 40px;
        background: #fff;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        padding: 16px;
        box-sizing: border-box;
        img {
          width: 210px;
          // margin: 5px 20px;
          margin: 10px 0;
        }
      }
    }
    .service {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      & > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        .service-circle {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background: #fff;
          display: inline-block;
          text-align: center;
          margin-bottom: 16px;
          box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.1);
          padding-top: 23px;
          box-sizing: border-box;
          img {
            width: 40px;
            height: 34px;
          }
        }
        .service-name {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.8);
          font-weight: 500;
        }
      }
    }
  }
}
</style>
