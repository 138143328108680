<template>
  <div class="knife-table">
    <div class="main-table">
      <el-table
        :data="tableData"
        :row-style="changeStyle"
        :cell-style="columnStyle"
        :header-cell-style="headerStyle"
        :header-row-style="headerRowStyle"
        @row-click="choseKnife"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
        @cell-click="cellClick"
      >
        <el-table-column
          v-for="(knife, indexs) in knivesColumns"
          :key="indexs + '1'"
          :label="knife.label"
          :prop="(l = knife.props)"
          :width="knife.width"
        >
          <template slot-scope="scope">
            <div v-if="knife.props === 'offsetY'" class="knife-purpose">
              <a-checkbox-group v-model="knifePurpose">
                <a-checkbox value="打孔">打孔</a-checkbox>
                <a-checkbox value="拉槽">拉槽</a-checkbox>
                <a-checkbox value="铣孔">铣孔</a-checkbox>
                <a-checkbox value="铣槽">铣槽</a-checkbox>
              </a-checkbox-group>
            </div>
            <div v-else-if="knife.props == 'offsetX'">
              <a-checkbox
                v-model="scope.row[knife.props]"
                @change="onlyOneChecked"
              ></a-checkbox>
            </div>
            <div
              v-else-if="
                scope.row.index === rowIndex &&
                scope.column.index === columnIndex &&
                knife.props !== 'offsetY' &&
                knife.props !== 'offsetX'
              "
            >
              <el-input
                v-model="scope.row[knife.props]"
                ref="editInput"
                type="string"
                size="mini"
                @blur="inputBlur(scope.row)"
                @change="inputBlur(scope.row)"
                @input="handleInputCheck(scope.row, knife.props)"
              />
            </div>
            <div
              class="knives_type"
              v-else-if="knife.props !== 'offsetY' && knife.props !== 'offsetX'"
            >
              {{ scope.row[knife.props] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="operation-btn">
      <a-button @click="addNewKnife">插入行</a-button>
      <a-button @click="deleteKnife">删除行</a-button>
    </div>
  </div>
</template>

<script>
import { genUniId } from '@/util/commonFuncs'
import { webId } from '@/util/configure'
import { regReplaceCuttingParamsDiameter } from '@/util/regReplace'

export default {
  props: {
    knifeTable: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      // 记录刀库列
      knivesColumns: [
        { label: '编号', props: 'id', width: 70 },
        { label: '名称', props: 'type', width: 90 },
        { label: '刀直径Φ', props: 'diameter', width: 90 },
        { label: '开料', props: 'offsetX', width: 70 },
        { label: '用途', props: 'offsetY', width: 140 },
        { label: 'Z轴快速移动速度', props: 'offsetZ', width: 130 },
        { label: 'Z轴加工速度', props: 'offsetNo' },
        { label: 'XY轴快速移动速度', props: 'drillVelocity', width: 140 },
        { label: 'XY轴加工速度', props: 'cutVelocity' },
        { label: '换刀开始代码', props: 'rotationlSpeed' },
        { label: '换刀结束代码', props: 'aa' },
        { label: '预启动代码', props: 'bb' },
      ],
      rowIndex: -1,
      columnIndex: -1,
      currentRow: {},
      knifePurpose: [],
    }
  },
  computed: {
    tableData() {
      return this.knifeTable
    },
  },
  methods: {
    // 更改行样式
    changeStyle({ row, rowIndex }) {
      if (this.currentRow.index == rowIndex) {
        return {
          height: '54px',
          'border-top': '1px solid #EEEEEE',
          'font-size': '14px',
          color: '#000000',
          'font-weight': '400',
          background: 'rgba(24, 168, 199, 0.2)',
        }
      }
      return {
        height: '54px',
        'border-top': '1px solid #EEEEEE',
        'font-size': '14px',
        color: '#000000',
        'font-weight': '400',
      }
    },
    // 更改单元格样式
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'id') {
        return 'padding-left: 16px'
      }
    },
    // 更改表头单元格样式
    headerStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'id') {
        return 'padding-left: 16px; box-sizing: border-box;background: #fafafa'
      }
      return 'background: #fafafa'
    },
    // 更改表头行样式
    headerRowStyle({ row, rowIndex }) {
      return {
        height: '54px',
        'font-size': '14px',
        color: '#000000',
        'font-weight': '500',
        'border-bottom': 'none',
      }
    },
    cellClick(row, column, cell, event) {
      this.rowIndex = row.index
      this.columnIndex = column.index
      if (column.property != 'offsetY' && column.property != 'offsetX') {
        this.$nextTick(() => {
          this.$refs['editInput'][0].focus()
        })
      }
    },
    // 修改刀具
    modifyKnife() {
      this.rowIndex = -1
      this.columnIndex = -1
      this.$emit('modifyKnife', this.tableData)
    },
    // 修改其他值
    inputBlur(row) {
      this.rowIndex = -1
      this.columnIndex = -1
      this.$emit('modifyKnife', this.tableData)
      // 失去焦点判断刀直径是否为0
      if (row['diameter'] === 0) {
        row['diameter'] = ''
      }
      if (row['diameter']) {
        row['diameter'] = Number(row['diameter'])
      }
    },
    // 添加刀具
    addNewKnife() {
      this.tableData.push({
        id: '',
        [webId]: genUniId(),
        type: '',
        diameter: '',
        offsetX: '',
        offsetY: '',
        offsetZ: '',
        offsetNo: '',
        drillVelocity: '',
        cutVelocity: '',
        rotationlSpeed: '',
      })
      this.rowIndex = this.tableData.length - 1
      this.columnIndex = 0
      this.$nextTick(() => {
        this.$refs['editInput'][0].focus()
      })
      this.$emit('modifyKnife', this.tableData)
    },
    // 删除刀具
    deleteKnife() {
      this.tableData.splice(
        this.tableData.findIndex((v) => v.index == this.currentRow.index),
        1
      )
      this.$emit('modifyKnife', this.tableData)
    },
    // 选中刀具
    choseKnife(row, column, event) {
      this.currentRow = row
    },
    //把每一行的索引加到行数据中
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    //把每一列的索引加到列数据中
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex
    },
    onlyOneChecked() {
      // this.tableData
    },
    // 输入校验
    handleInputCheck(row, prop) {
      // 刀径只能输入数字且不能为负数和0
      if (prop === 'diameter') {
        row[prop] = regReplaceCuttingParamsDiameter(row[prop])
      }
    },
  },
}
</script>

<style scoped lang="less">
.knife-table {
  .main-table {
    max-height: 407px;
    padding: 24px;
    box-sizing: border-box;
  }
  .operation-btn {
    padding: 24px;
    box-sizing: border-box;
  }
  .knife-purpose {
    /deep/.ant-checkbox-group {
      display: flex;
      flex-wrap: wrap;
      .ant-checkbox-wrapper {
        margin-left: 0;
        &:first-child {
          margin-right: 8px;
        }
        &:nth-child(3) {
          margin-right: 8px;
        }
      }
      .ant-checkbox + span {
        padding-right: 0;
      }
    }
  }
}
</style>
