<template>
  <div class="msg-dialog w100 h100 t0 l0 fixed">
    <div class="dialog-box br2 bg-white fixed">
      <div class="title relative">
        <span class="iconfont icon-warn"></span>

        <a-icon
          type="close"
          class="close-icon fs18 absolute"
          @click="handleClose"
        />
        <span class="warn-title bold" v-if="isClickSeeDetails"
          >无法加工的板件详情
        </span>
        <span class="warn-title bold fs17" v-else>温馨提示</span>
      </div>
      <div v-if="isClickSeeDetails" class="flex">
        <span class="banjian ml20">板件名称:</span
        ><span class="mark-number p3 relative"
          >{{ lackKnivesKeysList[num] }}
          <i class="bb r6 t4 absolute"
            >{{ num + 1 }}/{{ lackKnivesKeysList.length }}</i
          >
        </span>
      </div>
      <div class="msg-main fs14" v-else>
        <a-tooltip placement="top">
          <template slot="title">
            <span
              >检测到{{
                lackKnivesValueString
              }}未匹配到可用刀具，请前往添加刀具</span
            >
          </template>
          <div class="hint-text ellipsis2">
            检测到{{ lackKnivesValueString }}未匹配到可用刀具，请前往添加刀具
          </div>
        </a-tooltip>

        <p
          style="color: aqua"
          class="checkBabnjian mt15 cursor-pointer"
          @click="handleSeeDetails"
        >
          查看板件详情
        </p>
      </div>
      <div
        class="msg-bottom br2 mr10 flex flex-main--right color-f"
        v-if="isClickSeeDetails"
      >
        <div class="msg-bottom-btn-second flex w100 flex-main--justify">
          <a-button class="cancel-btn br2" @click="handleBack"
            ><span class="color-0">返回</span></a-button
          >
          <a-button class="confirm-btn br2 color-f" @click="handleLast"
            ><span class="color-f">上一个</span></a-button
          >
          <a-button class="confirm-btn br2 color-f" @click="handleNext"
            ><span class="color-f">下一个</span></a-button
          >
        </div>
      </div>
      <div class="msg-bottom br2 mr10 flex flex-main--right" v-else>
        <div class="msg-bottom-btn">
          <a-button class="cancel-btn br2 mr10" @click="handleCancel"
            ><span class="color-0">取消</span></a-button
          >
          <a-button class="confirm-btn br2" @click="handleAdd"
            ><span class="color-f">前往添加</span></a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['lackKnivesValueString', 'lackKnivesKeysList'],
  data() {
    return {
      //是否点击查看详情
      isClickSeeDetails: false,
      //板件显示初始下标
      num: 0,
    }
  },
  methods: {
    handleAdd() {
      this.$router.push({
        path: '/sideHole',
        query: {
          setting_id: this.$store.state.ncSetting.process_setting_id,
          line: this.$store.state.ncSetting.process_setting_name,
          equipType: 'side',
        },
      })
    },
    handleCancel() {
      this.$emit('handleCancle')
    },
    handleSeeDetails() {
      this.isClickSeeDetails = true
    },
    handleBack() {
      this.isClickSeeDetails = false
    },
    handleLast() {
      this.num -= 1
      if (this.num === -1) {
        this.num = this.lackKnivesKeysList.length - 1
      }
      this.$emit('search', this.lackKnivesKeysList[this.num])
    },
    handleNext() {
      this.num += 1
      if (this.num === this.lackKnivesKeysList.length) {
        this.num = 0
      }
      this.$emit('search', this.lackKnivesKeysList[this.num])
    },
    handleClose() {
      if (this.isClickSeeDetails) {
        this.isClickSeeDetails = false
      } else {
        this.$emit('handleCancle')
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('search', this.lackKnivesKeysList[0])
    })
  },
}
</script>

<style scoped>
.msg-dialog {
  z-index: 1000;
}
.dialog-box {
  width: 424px;
  height: 210px;
  margin-top: 80px;
  margin-left: 1050px;
  padding: 33px 32px 24px 33px;
  box-shadow: 3px 5px 8px rgb(190, 190, 190);
}
.dialog-box .title {
  margin-bottom: 12px;
}
.title {
  .close-icon {
    top: -15px;
    right: -5px;
  }
}
.dialog-box .title .warn-title {
  color: rgba(0, 0, 0, 0.85);
}
.dialog-box .title .icon-warn {
  color: rgba(250, 173, 20, 1);
  font-size: 16px;
}
.msg-main {
  margin-bottom: 24px;
  margin-left: 39px;
  color: rgba(0, 0, 0, 0.65);
}
.msg-bottom {
  .msg-bottom-btn-second {
    margin-top: 42px;
  }
}
.msg-bottom .cancel-btn {
  height: 32px;
}
.msg-bottom .confirm-btn {
  height: 32px;
  background: rgba(24, 168, 199, 1);
}
.banjian {
  width: 65px;
  line-height: 30px;
}
.mark-number {
  width: 270px;
  border: 1px solid rgb(174, 174, 174);
}
</style>
