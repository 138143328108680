<template>
  <div class="home-page" ref="containerBox">
    <header class="nav">
      <section class="left">
        <div class="logo">
          <img src="../assets/image/homeLogo.svg" alt="" />
        </div>
        <!-- <div class="logo-box">
          <div>
            <span>云排版</span>
            <span>省时，省料，省力气</span>
          </div>
        </div> -->
        <ul>
          <li
            v-for="(el, index) in navList"
            :key="index"
            :class="{ active: activeNav === el.refName }"
            @click="changeNav(el)"
          >
            <a>{{ el.name }}</a>
          </li>
        </ul>
      </section>
      <span class="color-f">投诉电话：400-1567-080</span>
      <section class="right" id="home_goto_bluen_btn" @click="gotoPaiBan">
        <div class="right-content">
          <span>进入云排版</span>
          <i class="iconfont icon-right"></i>
        </div>
        <div class="right-bg-border"></div>
      </section>
    </header>
    <section class="banner" ref="home">
      <swiper ref="swiper" :options="swiperOption">
        <swiper-slide>
          <img src="../assets/image/banner4.jpg" alt="" srcset="" />
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/image/banner1.png" alt="" srcset="" />
          <div class="describe extra-info">
            <div class="btn">
              <p>全新升级版</p>
            </div>
            <div class="title">
              云排版<span class="highlight">机器的智能大脑</span>
            </div>
            <div class="info">
              <p>改革行业的云端在线排版优化软件</p>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/image/banner2.png" alt="" srcset="" />
          <div class="describe">
            <div class="title">
              柜体生产<span class="highlight">技术革新</span>
            </div>
            <div class="info">
              <p>支持多种贴标机、开料机、侧孔机、五面PTP、五/六面钻对接</p>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/image/banner3.png" alt="" srcset="" />
          <div class="describe">
            <div class="title">
              工业大脑<span class="highlight">解决方案</span>
            </div>
            <div class="info">
              <p>智能排版算法获得板材更高利用率</p>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <section class="data">
        <div>
          <p>{{ dayCount.toLocaleString() }}</p>
          <span>今日排版量</span>
        </div>
        <div>
          <p>{{ allCount.toLocaleString() }}</p>
          <span>当月排版量</span>
        </div>
        <div>
          <p>95%</p>
          <span>平均出材率</span>
        </div>
        <div>
          <p>{{ formatUserCount }}</p>
          <span>累计用户</span>
        </div>
      </section>
    </section>
    <section class="intro" ref="intro">
      <h3 class="title">企业级大数据 云端在线排版软件</h3>
      <p class="subtitle">
        洞察行业态势，聚焦客户需求，赋能企业数字化转型升级，推动互联网家具生产自动化产业变革
        针对现有板式家具行业生产场景，推出AI辅助算法，持续为企业实现贡献新增长
      </p>
      <main class="box">
        <div class="up">
          <section
            class="item"
            :class="{ active: curDataIndex === 1 }"
            @click="curDataIndex = 1"
          >
            <div>
              <img src="../assets/icon/online.png" alt="" />
              <span>在线排版</span>
            </div>
            <p>任何时刻均可排版</p>
          </section>
          <section
            class="item"
            :class="{ active: curDataIndex === 2 }"
            @click="curDataIndex = 2"
          >
            <div>
              <img src="../assets/icon/line.png" alt="" />
              <span>多生产线</span>
            </div>
            <p>生产线快速切换</p>
          </section>
          <section
            class="item"
            :class="{ active: curDataIndex === 3 }"
            @click="curDataIndex = 3"
          >
            <div>
              <img src="../assets/icon/production.png" alt="" />
              <span>精细生产</span>
            </div>
            <p>精细排版，精细参数设置</p>
          </section>
          <section
            class="item"
            :class="{ active: curDataIndex === 4 }"
            @click="curDataIndex = 4"
          >
            <div>
              <img src="../assets/icon/custom.png" alt="" />
              <span>自定义生产</span>
            </div>
            <p>自定义标签、五六面钻、余料形状</p>
          </section>
        </div>
        <div class="content">
          <div class="item" v-show="curDataIndex === 1">
            <div class="img">
              <img src="../assets/image/A.png" alt="" />
              <img src="../assets/image/online.png" alt="" />
            </div>
            <div class="text">
              <h2>在线排版</h2>
              <p>无需下载软件，任何时刻均可排版,远程操控不必依赖于电脑</p>
            </div>
          </div>
          <div class="item" v-show="curDataIndex === 2">
            <div class="img">
              <img src="../assets/image/B.png" alt="" />
              <img src="../assets/image/line.png" alt="" />
            </div>
            <div class="text">
              <h2>多生产线</h2>
              <p>支持多条生产线同时快速加工，生产线之间可以进行快速切换</p>
            </div>
          </div>
          <div class="item" v-show="curDataIndex === 3">
            <div class="img">
              <img src="../assets/image/C.png" alt="" />
              <img src="../assets/image/production.png" alt="" />
            </div>
            <div class="text">
              <h2>精细生产</h2>
              <p>精细排版，精细参数设置，让板件生产变得更加精确化</p>
            </div>
          </div>
          <div class="item" v-show="curDataIndex === 4">
            <div class="img">
              <img src="../assets/image/D.png" alt="" />
              <img src="../assets/image/custom.png" alt="" />
            </div>
            <div class="text">
              <h2>自定义生产</h2>
              <p>自定义标签，自定义五六面钻，自定义余料形状，组合设备加工</p>
            </div>
          </div>
        </div>
      </main>
    </section>
    <section class="resolve" ref="resolve">
      <h3 class="title">行业专业解决方案</h3>
      <p class="subtitle">
        低成本，高产值，智能算法提高板件利用率，兼容多种设计软件，为用户提供最优生产方案
      </p>
      <div class="content">
        <div class="item">
          <img src="../assets/image/resolve1.png" alt="" />
          <section>
            <h4>线上接单生产一体化</h4>
            <p>
              远程线上操作，跨越空间阻碍，随时输出订单同步工厂，设计生产同步完成
            </p>
          </section>
        </div>
        <div class="item">
          <img src="../assets/image/resolve2.png" alt="" />
          <section>
            <h4>一键切换生产线</h4>
            <p>自定义生产线，支持多生产线，多类型设备对接</p>
          </section>
        </div>
        <div class="item">
          <img src="../assets/image/resolve3.png" alt="" />
          <section>
            <h4>智能算法提高板材利用率</h4>
            <p>传统算法板材利用率低，智能算法精细参数设置，定制生产方案</p>
          </section>
        </div>
        <div class="item">
          <img src="../assets/image/resolve4.png" alt="" />
          <section>
            <h4>专业切割方案</h4>
            <p>优化切割顺序、下刀点，让路径更短切割更稳</p>
          </section>
        </div>
      </div>
    </section>
    <section class="about-me" ref="aboutMe">
      <div class="box">
        <aside class="left">
          <h3>新格尔人居科技</h3>
          <h3>家具生产业变革创领者</h3>
          <p>新格尔企业，专注实现传统家具生产业的互联网变革</p>
          <ul>
            <li>
              <span class="bold">企业宗旨</span>
              <span>帮助工厂，三倍增长</span>
            </li>
            <li>
              <span class="bold">文化价值观</span>
              <span>以客户为中心，以奋斗者为本，艰苦奋斗，造福人民</span>
            </li>
            <li>
              <span class="bold">指导思想</span>
              <span>群众路线，搭建具有销售力、产品力、组织力的精英团队</span>
            </li>
          </ul>
          <section class="box">
            <div class="item">
              <img src="../assets/image/about1.png" alt="" />
              <span>专业团队维护</span>
            </div>
            <div class="item">
              <img src="../assets/image/about2.png" alt="" />
              <span>持续更新迭代</span>
            </div>
            <div class="item">
              <img src="../assets/image/about3.png" alt="" />
              <span>一对一售后服务</span>
            </div>
            <div class="item">
              <img src="../assets/image/about4.png" alt="" />
              <span>功能定制服务</span>
            </div>
          </section>
        </aside>
        <aside class="right">
          <img src="../assets/image/about-right.png" alt="" srcset="" />
        </aside>
      </div>
    </section>
    <section class="future">
      <h3 class="title">精诚合作 共创未来</h3>
      <p class="subtitle">
        对接设品牌覆盖90%国内共产常用设备，107554位商家与我们同行
      </p>
      <div class="content">
        <img src="../assets/image/furture.png" alt="" />
      </div>
    </section>
    <bluenFooter></bluenFooter>
  </div>
</template>

<script>
import bluenFooter from '@/components/footer.vue'
import axios from 'axios'
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    bluenFooter,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      // 导航栏
      navList: [
        { name: '产品介绍', refName: 'intro', scrollTop: 0 },
        { name: '解决方案', refName: 'resolve', scrollTop: 0 },
        { name: '关于我们', refName: 'aboutMe', scrollTop: 0 },
        { name: '机器设备', path: '/equipEnvironment' },
      ],
      // 记录每日排版数量
      dayCount: 0,
      // 记录总共排版数量
      allCount: 0,
      // 画布
      exampleCanvas: {},
      // 记录商家合作数量
      cooperationCount: 0,
      // 记录选中的导航栏
      activeNav: 'home',
      swiperOption: {
        //轮播图
        spaceBetween: 0,
        // 设置分页器
        pagination: {
          el: '.swiper-pagination',
          // 设置点击可切换
          clickable: true,
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        // 设置轮播可循环
        loop: true,
      },
      curDataIndex: 1, //当前展示的企业级大数据的下标
      userCount: 0, //累计用户数量
    }
  },
  computed: {
    formatUserCount() {
      return Math.floor(Number(this.userCount) / 10000) + 'W+'
    },
  },
  methods: {
    // 点击切换导航
    changeNav(el) {
      if (el.path) {
        let routeData = this.$router.resolve({
          path: el.path,
        })
        window.open(routeData.href, '_blank')
        return
      }
      const curOffsetTop =
        this.$refs[el.refName] && this.$refs[el.refName].offsetTop
      window.scrollTo({
        top: curOffsetTop - 80,
        behavior: 'smooth',
      })
      this.activeNav = el.refName
    },
    // 获取每日和当月的排版数量
    getPaibanData() {
      let date = new Date()
      let nowDay = date.getDate()
      // 你可能会迷惑, 为啥要这样处理, 因为接口最多只能统计30天
      let nowDate = 0
      if (nowDay == 31) {
        nowDate = 30
      } else {
        nowDate = nowDay
      }
      this.$core
        .get('https://ggtools.thinkerx.com/statistics_paiban', {
          params: {
            uid: 0,
            recent_day: nowDay,
          },
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.allCount = res.data.data.paiban_count
          }
        })
      this.$core
        .get('https://ggtools.thinkerx.com/statistics_paiban', {
          params: {
            uid: 0,
            recent_day: 1,
          },
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.dayCount = res.data.data.paiban_count
          }
        })
    },
    // 获取合作商家数量
    getCooperation() {
      this.$core
        .get('https://guigui.admin.thinkerx.com/eggrj/user_count')
        .then((res) => {
          this.cooperationCount = res.data.data.res
        })
    },
    //获取用户数量
    getUserCount() {
      axios
        .get('https://ggtools.thinkerx.com/user_num_statistics')
        .then((res) => {
          this.userCount = res.data.data.res
        })
    },
    drawExample() {
      this.exampleCanvas = new fabric.Canvas('example_canvas', {})
    },
    gotoPaiBan() {
      //进入云排版
      window.open(this.$router.resolve('/login').href, '_blank')
    },
    monitorRoll() {
      //监听滚动
      const pageScrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      for (let i = this.navList.length - 1; i > -1; i--) {
        if (this.navList[i].path) continue
        if (pageScrollTop >= this.navList[i].scrollTop) {
          this.activeNav = this.navList[i].refName
          break
        }
      }
    },
  },
  created() {
    this.getPaibanData()
    this.getCooperation()
    this.getUserCount()
  },
  mounted() {
    this.drawExample()

    const newNavList = [...this.navList]
    for (let el of newNavList) {
      el.scrollTop =
        this.$refs[el.refName] && this.$refs[el.refName].offsetTop - 80
    }
    this.navList = [...newNavList]
    window.addEventListener('scroll', this.monitorRoll) //监听页面滚动
  },
}
</script>

<style scoped lang="less">
.home-page {
  width: 100%;
  min-width: 1280px;
  padding-top: 80px;
  .logo-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
    margin-right: 152px;
    span {
      display: block;
      color: #fff;
    }
    span:first-child {
      color: #fff;
      font-weight: 600;
      font-size: 20px;
    }
    span:last-child {
      color: #fff;
      font-size: 12px;
    }
  }
  li {
    list-style: none;
  }
  h3.title {
    color: #000;
    font-weight: 500;
    font-size: 24px;
    font-family: 'PingFangSC-Medium', 'PingFang SC';
    line-height: 33px;
    text-align: center;
  }
  p.subtitle {
    width: 672px;
    margin: 0 auto;
    color: #666;
    font-weight: 400;
    font-size: 16px;
    font-family: 'PingFangSC-Regular', 'PingFang SC';
    line-height: 22px;
    text-align: center;
  }
  //导航栏样式
  header.nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 1200px;
    height: 80px;
    padding: 0 48px;
    background: #191919;
    section {
      &.left {
        display: flex;
        height: 100%;
        div.logo {
          padding-right: 162px;
          line-height: 80px;
          img {
            width: 110px;
            height: 47px;
          }
        }
        ul {
          display: flex;
          height: 100%;
          li {
            width: 151px;
            line-height: 80px;
            text-align: center;
            cursor: pointer;
            a {
              color: #fff;
              font-weight: 500;
              font-size: 18px;
              font-family: 'PingFangSC-Medium', 'PingFang SC';
            }
            &.active {
              // background: #FFFFFF;
              // opacity: 0.04;
              background: rgba(255, 255, 255, 0.04);
              a {
                color: #24c7e8;
              }
            }
          }
        }
      }
      &.right {
        position: relative;
        width: 107px;
        height: 30px;
        .right-content {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background: linear-gradient(155deg, #22a2ca 0%, #187eb0 100%);
          border-radius: 5px;
          cursor: pointer;
        }
        .right-bg-border {
          position: absolute;
          top: -1px;
          right: -1px;
          bottom: -1px;
          left: -1px;
          background: linear-gradient(
            180deg,
            rgba(150, 232, 255, 1),
            rgba(96, 225, 255, 1)
          );
          border-radius: 5px;
        }
        span {
          color: #fff;
          font-weight: 500;
          font-size: 12px;
          font-family: 'PingFangSC-Regular', 'PingFang SC';
        }
        i {
          padding-left: 9px;
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
  //轮播图样式
  section.banner {
    position: relative;
    width: 100%;
    height: 600px;
    div.swiper-container-horizontal {
      width: 100%;
      height: 600px;
      border: none;
      div.swiper-wrapper {
        div.swiper-slide {
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    div.describe {
      //描述信息
      position: absolute;
      top: 150px;
      left: 50%;
      z-index: 1000;
      width: 1200px;
      border: none;
      transform: translateX(-50%);
      &.extra-info {
        top: 99px;
      }
      div.btn {
        margin-bottom: 27px;
        p {
          width: 138px;
          height: 24px;
          margin: 0;
          padding: 0;
          color: #000;
          font-weight: 500;
          font-size: 18px;
          font-family: 'PingFangSC-Medium', 'PingFang SC';
          line-height: 24px;
          text-align: center;
          background: #28dbff;
          border-radius: 18px;
        }
      }
      div.title {
        color: #ffffff;
        font-weight: normal;
        font-size: 45px;
        font-family: 'FZDHTJW--GB1-0', 'FZDHTJW--GB1';
        line-height: 69px;
        letter-spacing: 5px;
        span.highlight {
          color: #23c1e1;
        }
      }
      div.info {
        padding-top: 11px;
        p {
          margin: 0;
          padding: 0;
          color: #ffffff;
          font-weight: 400;
          font-size: 16px;
          font-family: 'PingFangSC-Regular', 'PingFang SC';
          line-height: 29px;
        }
      }
    }
    section.data {
      //数据
      position: absolute;
      bottom: 0;
      left: calc(50% - 600px);
      z-index: 1000;
      display: flex;
      justify-content: space-around;
      box-sizing: border-box;
      width: 1200px;
      height: 138px;
      padding: 41px 0 24px;
      background-color: #f9fafd;
      div {
        p {
          max-width: 5em;
          margin-bottom: 0;
          padding-bottom: 9px;
          color: #333333;
          font-weight: bold;
          font-size: 36px;
          font-family: 'DINAlternate-Bold', 'DINAlternate';
          line-height: 1;
        }
        span {
          color: #666666;
          font-weight: 600;
          font-size: 16px;
          font-family: 'PingFangSC-Regular', 'PingFang SC';
          line-height: 1;
          // line-height: 22px;
        }
      }
    }
  }
  //企业级大数据 云端在线排版软件 样式
  section.intro {
    padding: 72px 0 55px;
    h3.title {
      padding-bottom: 12px;
    }
    p.subtitle {
      padding-bottom: 72px;
    }
    main.box {
      div.up {
        display: flex;
        justify-content: center;
        section.item {
          box-sizing: border-box;
          width: 261px;
          height: 102px;
          padding: 24px 0 0 24px;
          background: #ffffff;
          border-radius: 6px;
          box-shadow: 0px 2px 12px 0px rgba(0, 26, 53, 0.15);
          cursor: pointer;
          transition: all 0.3s;
          &.active {
            border-bottom: solid 5px #236ff0;
          }
          &:not(:first-child) {
            margin-left: 15px;
          }
          div {
            img {
              width: 25px;
              height: 25px;
            }
            span {
              display: inline-block;
              max-width: 10em;
              padding-left: 8px;
              overflow: hidden;
              color: #333333;
              font-weight: 500;
              font-size: 16px;
              font-family: 'PingFangSC-Medium', 'PingFang SC';
              white-space: nowrap;
              text-overflow: ellipsis;
              vertical-align: middle;
            }
          }
          p {
            max-width: 16em;
            padding-top: 10px;
            overflow: hidden;
            color: #999999;
            font-weight: 400;
            font-size: 14px;
            font-family: 'PingFangSC-Regular', 'PingFang SC';

            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      div.content {
        padding: 79px 0 55px;
        div.item {
          position: relative;
          width: 874px;
          margin: 0 auto;
          div.img {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
              &:first-child {
                width: 165px;
                height: 179px;
              }
              &:last-child {
                width: 293px;
                height: 299px;
              }
            }
          }
          div.text {
            position: absolute;
            top: 73px;
            left: 74px;
            h2 {
              margin-bottom: 16px;
              color: #333333;
              font-weight: 500;
              font-size: 36px;
              font-family: 'PingFangSC-Medium', 'PingFang SC';
            }
            p {
              width: 309px;
              margin: 0;
              color: #999999;
              font-weight: 400;
              font-size: 16px;
              font-family: 'PingFangSC-Regular', 'PingFang SC';
            }
          }
        }
      }
    }
  }
  //行业解决方案样式
  section.resolve {
    padding: 86px 0 70px;
    background-image: url('../assets/image/resolve-bg.png');
    background-size: 100% 100%;
    h3.title {
      margin-bottom: 24px;
      color: #ffffff;
    }
    p.subtitle {
      padding-bottom: 72px;
      color: rgba(255, 255, 255, 0.7);
    }
    div.content {
      display: flex;
      align-items: center;
      justify-content: center;
      div.item {
        width: 287px;
        height: 402px;
        background-color: #fff;
        &:not(:first-child) {
          margin-left: 26px;
        }
        img {
          display: block;
          width: 100%;
          height: 275px;
        }
        section {
          box-sizing: border-box;
          padding: 24px 34px 0 15px;
          h4 {
            margin-bottom: 7px;
            overflow: hidden;
            color: #000000;
            font-weight: 500;
            font-size: 18px;
            font-family: 'PingFangSC-Medium', 'PingFang SC';
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          p {
            display: -webkit-box;
            height: 40px;
            margin-bottom: 0;

            overflow: hidden;
            color: #666666;
            font-weight: 400;
            font-size: 14px;
            font-family: 'PingFangSC-Regular', 'PingFang SC';
            line-height: 20px;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2; /*两行*/
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
  //新格尔人居科技 家具生产业变革创领者
  section.about-me {
    width: 100%;
    padding: 87px 0;
    background-image: url('../assets/image/about-bg.png');
    background-size: 100% 100%;
    div.box {
      display: flex;
      justify-content: center;
      height: 523px;
      aside.left {
        box-sizing: border-box;
        width: 660px;
        padding: 39px 0 0 46px;
        background: rgba(255, 255, 255, 0.83);
        h3 {
          margin-bottom: 0;
          color: #333333;
          font-weight: 600;
          font-size: 30px;
          font-family: 'PingFangSC-Semibold', 'PingFang SC';
          line-height: 42px;
        }
        p {
          padding: 24px 0 58px;
          color: #666666;
          font-weight: 400;
          font-size: 16px;
          font-family: 'PingFangSC-Regular', 'PingFang SC';
        }
        ul {
          li {
            position: relative;
            list-style: none;
            &:not(:first-child) {
              margin-top: 13px;
            }
            &::before {
              position: absolute;
              top: calc(50% - 5px);
              left: 0;
              width: 10px;
              height: 10px;
              background: linear-gradient(180deg, #b8f9ff 0%, #75bcce 100%);
              border-radius: 10px;
              content: '';
            }
            span {
              color: #666666;
              font-size: 16px;
              font-family: 'PingFangSC-Regular', 'PingFang SC';
              vertical-align: middle;
              &:last-child {
                padding-left: 10px;
              }
              &.bold {
                padding-left: 22px;
                color: #333333;
                font-weight: 600;
                font-family: 'PingFangSC-Medium', 'PingFang SC';
              }
            }
          }
        }
        section.box {
          display: flex;
          margin-top: 33px;
          div.item {
            &:not(:first-child) {
              padding-left: 43px;
            }
            img {
              display: block;
              width: 77px;
              height: 78px;
            }
            span {
              color: #666666;
              font-weight: 400;
              font-size: 12px;
              font-family: 'PingFangSC-Regular', 'PingFang SC';
            }
          }
        }
      }
      aside.right {
        width: 629px;
        height: 523px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  //精诚合作，共创未来样式
  section.future {
    padding: 70px 0 137px;
    h3.title {
      margin-bottom: 21px;
    }
    p.subtitle {
      margin-bottom: 70px;
    }
    div.content {
      width: 1132px;
      height: 299px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
<style lang="less">
section.banner {
  //轮播图样式i
  div.swiper-container-horizontal {
    > div.swiper-pagination-bullets {
      bottom: 205px;
      box-sizing: border-box;
      width: 1200px;
      margin-left: 50%;
      text-align: left;
      transform: translateX(-50%);
      span.swiper-pagination-bullet {
        display: inline-block;
        width: 42px;
        height: 4px;
        background: rgba(216, 216, 216, 0.47);
        border-radius: 2px;
        &.swiper-pagination-bullet-active {
          background: #fff;
        }
      }
    }
  }
}
</style>
