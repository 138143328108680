import { fetchTaskList } from '@/apis/taskList'
import store from '@/store'

import { GetTaskListPayload, TaskType } from '../types/taskCard'

function taskDataFromApi(data: any): {
  total: number
  taskList: TaskType[]
  produce_time: number
} {
  if (data.data) {
    store.commit('setProduceTime', data.produce_time)
    return {
      total: data.total,
      produce_time: data.produce_time,
      taskList: dealTaskListFromApi(data.data),
    }
  } else {
    return {
      total: 0,
      taskList: [],
      produce_time: 0,
    }
  }
}

export function dealTaskListFromApi(data: any[]): TaskType[] {
  return data.map((task: any): TaskType => {
    return {
      id: task.id,
      orderId: task.oid,
      orderNo: task.factory_num,
      orderName: task.buyer_address
        ? task.buyer_address
        : task.plank_json
        ? JSON.parse(task.plank_json).orderName
          ? JSON.parse(task.plank_json).orderName
          : '--'
        : '--',
      customer: task.sender_info
        ? task.sender_info.sender_name
        : task.sender_name,
      customerId: task.sender,
      sendDate: task.create_time,
      isInBatchList: task.current_step == 2,
      isRefused: task.current_step == 1,
      hasPaiban: Boolean(task.paiban_json),
      isHistory: task.current_step == 4,
      isNeedBujian: false,
      deliveryTime: task.delivery_time == 'None' ? '--' : task.delivery_time,
      batchNumber: task.task_batch,
      logoUrl: task.sender_info ? task.sender_info.logo_url : task.logo_url,
      batch_id: task.batch_id,
      paiban_json: task.paiban_json,
      plank_json: task.plank_json,
      platform: task.platform,
      refuse_uid: task.refuse_info?.uid,
      refuse_time: task.refuse_info?.refuse_time,
      refuse_reason: task.refuse_info?.refuse_reason,
      current_step: task.current_step,
      roomIds: task.order_all_rooms
        ? task.order_all_rooms.split(',').map((id: any) => Number(id))
        : [],
    }
  })
}

export function dealHistoryDataFromApi(data: any[]) {
  return data.map((history) => ({
    id: history.batch_id,
    batchNumber: history.task_batch,
    createTime: history.archive_time,
    material: '多层实木T01',
    taskList: dealTaskListFromApi(history.task_info),
  }))
}

export async function getTaskList(payload: GetTaskListPayload) {
  const res = await fetchTaskList(payload)

  return taskDataFromApi(res.data)
}
