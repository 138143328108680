const tips = [
  {
    shape: 'rect',
    bgc: '#e5ae67',
    bc: '#fff',
    text: '冲突部分',
  },
  {
    shape: 'round',
    bgc: '#fff',
    bc: 'red',
    text: '正面孔',
  },
  {
    shape: 'round',
    bgc: '#fff',
    bc: '#0000ff',
    text: '反面孔',
  },
  {
    shape: 'rect',
    bgc: '#fff',
    bc: '#f00',
    text: '正面槽',
  },
  {
    shape: 'rect',
    bgc: '#fff',
    bc: '#0000ff',
    text: '反面槽',
  },
]

export { tips }
