import { translate } from '@/util/commonFun'

const tryProductionColumns = [
  {
    // title: translate('common.productionLine'),
    dataIndex: 'process_name',
    scopedSlots: { customRender: 'line', title: 'lineTitle' },
    ellipsis: true,
  },
  {
    // title: translate('historyPage.trailProduction.date'),
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time', title: 'createTimeTitle' },
    width: '200px',
  },
  {
    // title: translate('historyPage.trailProduction.outputFrom'),
    dataIndex: 'from',
    scopedSlots: { customRender: 'expert', title: 'expertTitle' },
    width: '120px',
  },
  {
    // title: translate('common.operation'),
    dataIndex: 'operate',
    scopedSlots: { customRender: 'operate', title: 'operateTitle' },
    width: '150px',
  },
]

export { tryProductionColumns }
