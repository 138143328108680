const bjPlankMock = [
  {
    big_plate_num: 0,
    buyer_address: 'AAA',
    buyer_phone: '',
    count: 0,
    create_time: '2021-05-13 16:00:29',
    creator: '',
    customer_id: 0,
    customer_name: '',
    delivery_time: '0000-00-00 00:00:00',
    designer: '',
    expand_area: '0.0000',
    factory_num: '0513-755',
    ggid: '00000^K~',
    install_time: '2021-05-28 08:00:00',
    measure_time: '2021-05-13 00:00:00',
    oid: 588311,
    order_code: '0513-755',
    plates: [
      {
        color: 'T01',
        create_time: '2021-05-14 17:21:54',
        delivery_time: '0000-00-00 00:00:00',
        ggid: 'f5LZ002',
        id: 884054,
        isChoose: true,
        plankId: 2,
        plate_info: {
          color: 'T01',
          matCode: '多层实木',
          plKId: 2,
          size: '600*797.92*18',
        },
        render_url:
          'https://eggrj.oss-cn-hangzhou.aliyuncs.com/renderJSON/16209840250650.json',
        rid: 1426313,
        room_name: '主卧',
        size: '600*797.92*18',
        type: '多层实木',
      },
    ],
    projective_area: '0.0000',
    received_price: '0.00',
    remaining_price: '0.00',
    remark: '',
    room_ids: '1426313,1453818,1467154',
    setting: '',
    status: 0,
    status_update_time: '0000-00-00 00:00:00',
    total_price: '0.00',
    uid: 5313,
    update_time: '2021-05-20 16:42:31',
    plankSource: 'patchPlank',
  },
  {
    big_plate_num: 0,
    buyer_address: 'AAA',
    buyer_phone: '',
    count: 0,
    create_time: '2021-10-26 09:50:38',
    creator: '',
    customer_id: 0,
    customer_name: '',
    delivery_time: '',
    designer: '',
    expand_area: '0.0000',
    factory_num: '211026-158',
    ggid: '00000_y:',
    install_time: '2021-11-10 08:00:00',
    measure_time: '2021-10-26 00:00:00',
    oid: 638010,
    order_code: '211026-158',
    plates: [
      {
        color: 'T01',
        create_time: '2021-11-18 09:57:37',
        delivery_time: '',
        ggid: 'ge*q005',
        id: 1025873,
        isChoose: true,
        plankId: 5,
        plate_info: {
          color: 'T01',
          matCode: '多层实木',
          plKId: 5,
          size: '600*546.47*18',
        },
        render_url:
          'https://eggrj.oss-cn-hangzhou.aliyuncs.com/renderJSON/16352130509171.json',
        rid: 1554667,
        room_name: '主卧',
        size: '600*546.47*18',
        type: '多层实木',
      },
      {
        color: 'T01',
        create_time: '2021-11-18 09:57:46',
        delivery_time: '',
        ggid: 'ge*q004',
        id: 1025875,
        isChoose: true,
        plankId: 4,
        plate_info: {
          color: 'T01',
          matCode: '多层实木',
          plKId: 4,
          size: '600*428.76*18',
        },
        render_url:
          'https://eggrj.oss-cn-hangzhou.aliyuncs.com/renderJSON/16352130509171.json',
        rid: 1554667,
        room_name: '主卧',
        size: '600*428.76*18',
        type: '多层实木',
      },
    ],
    projective_area: '0.0000',
    received_price: '0.00',
    remaining_price: '0.00',
    remark: '',
    room_ids: '1554667,1554697',
    setting: '',
    status: 0,
    status_update_time: '0000-00-00 00:00:00',
    total_price: '0.00',
    uid: 5313,
    update_time: '2021-10-28 09:57:05',
    plankSource: 'patchPlank',
  },
]
export default {
  bjPlankMock,
}
