var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"history-form"},_vm._l((_setup.titleKeys),function(key,index){return _c('div',{key:key,staticClass:"history-form-item",class:{
      mb20: index !== _setup.titleKeys.length - 1,
    }},[_c('p',{staticClass:"history-form-title-text fs16 bold"},[_vm._v(" "+_vm._s(_setup.dealTitleStr(key))+" ")]),_c('div',{staticClass:"history-form-item-content fs14"},_vm._l((Object.keys(_setup.historyData[key])),function(insideKey,index){return _c('p',{key:index,staticClass:"history-form-item-content-text",style:({
          display:
            insideKey === 'custom_special_hole_slot_side' ? 'flex' : '',
        })},[(_setup.dealSettingStr(insideKey))?[_c('span',{staticClass:"inline-block mr5"},[_vm._v(_vm._s(_setup.dealSettingStr(insideKey))+"：")]),(insideKey === 'custom_special_hole_slot_side')?_c('div',[_c('span',[_vm._v(_vm._s(_setup.isSpecialRule ? _setup.translateLang('preLayoutSetting.on') : _setup.translateLang('preLayoutSetting.off')))]),_c('br'),_vm._l((_setup.dealValueStr(
                _setup.props.historyDetail[key][insideKey],
                key
              )),function(it,i){return _c('div',{key:i,staticClass:"flex"},[(_setup.isSpecialRule)?_c('span',[_vm._v(_vm._s(_setup.typeMap.get(it.key) + ': ' + _setup.surfaceMap.get(it.value))),_c('br')]):_vm._e()])})],2):_c('span',[_vm._v(_vm._s(_setup.dealValueStr(_setup.historyData[key][insideKey], key)))])]:_vm._e()],2)}),0)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }