interface Test {
  aa: string
  bb: number
  __proto__: any
}

type Haha = keyof Test

function fn(test: Test) {
  test.__proto__ = {
    cc: '123',
    dd: '333',
  }

  type AA = keyof Test
  // for (const key in test) {
  //   test[key] = 123
  // }
  Object.keys(test).forEach((key) => {
    if (key === 'aa') {
      test[key] = '123'
    }
  })
}
