import { PartType } from '@/partTypes'
import { loopDrawData } from '@/util/plankCommonFuncs'

/**
 * @description 获取当前板件,所在的绘制组最大的index
 * @param existIndexArr 还需要排除的index，如果生成的最大index还是存在相同则再加1
 * @returns
 */
export function getPlankMaxIndexByFinalDrawData(existIndexArr: number[] = []) {
  const indexArr: number[] = []
  // 获取所有板件的index
  const getIndex = (plank: PartType) => {
    if (plank.index || plank.index === 0) {
      indexArr.push(plank.index)
    }
  }
  loopDrawData(getIndex)
  // 排除异常的index
  const arr = indexArr.map((idx) => {
    if (idx === Infinity || idx === -Infinity || !idx) {
      return 0
    }
    return +idx
  })
  let max = Math.max(...arr, 0) + 1
  // 如果是已存在的index则将最大的index再加1
  while (existIndexArr.includes(max)) {
    max += 1
  }
  return max
}
