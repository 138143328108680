var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-nav"},[_c('div',{staticClass:"top-nav-content"},[_c('div',{staticClass:"content-left"},[_c('div',{staticClass:"logo-box"},[_c('span',[_vm._v(_vm._s(_vm.showTiangong ? '天工' : '云排版'))]),_c('span',[_vm._v("省时，省料，省力气")])]),_c('div',{staticClass:"nav-list"},_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,class:[
            'nav-list-item',
            _vm.activeNav == item.name ? 'nav-active-item' : '',
          ],on:{"click":function($event){return _vm.changeNav(item)}}},[(item.name == 'detailedlist')?_c('a-popover',{attrs:{"placement":"bottomRight","overlayClassName":"material-nav-popover"}},[_c('template',{slot:"content"},[_c('div',{staticClass:"material-nav"},_vm._l((_vm.extraNav),function(extra){return _c('span',{key:extra.id,class:[
                    'extra-nav',
                    _vm.extraActiveNav == extra.name ? 'active-extra' : '',
                  ],on:{"click":function($event){return _vm.gotoExtraNav(extra)}}},[_vm._v(" "+_vm._s(extra.title)+" ")])}),0)]),_c('span',{staticClass:"nav-item-title"},[_vm._v(_vm._s(item.title))]),(item.name == 'detailedlist')?_c('span',{staticClass:"nav-item-title iconfont icon-xia"}):_vm._e()],2):_c('span',{staticClass:"nav-item-title"},[_vm._v(_vm._s(item.title))])],1)}),0)]),_c('div',{staticClass:"content-right"},[_c('div',{staticClass:"official-website"},[_c('span',{on:{"click":_vm.gotoOfficialWebsite}},[_vm._v("进入官网")])]),_c('div',{staticClass:"helpUsing"},[_c('span',{on:{"click":_vm.gotoHelpingDocument}},[_vm._v("帮助")])]),(!_vm.showTiangong)?_c('div',{staticClass:"user-box",on:{"click":function($event){$event.stopPropagation();return _vm.showPersonInfo.apply(null, arguments)}}},[_c('div',{staticClass:"user-head"},[_c('img',{attrs:{"src":_vm.userInfo.logo_url,"alt":""}})]),_c('div',{staticClass:"user_name"},[_c('span',[_vm._v(_vm._s(_vm.userInfo.name))]),(!_vm.showPerson)?_c('i',{staticClass:"iconfont icon-zhankai",staticStyle:{"fontsize":"12px"}}):(_vm.showPerson)?_c('i',{staticClass:"iconfont icon-arrow",staticStyle:{"fontsize":"12px"}}):_vm._e()])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }