import { translate } from '@/util/commonFun'
import moment from 'moment'

const searchTypeList = [
  // 板件条码
  { label: 'common.plankNum', value: '1' },
  // 板号
  { label: 'common.plankNo', value: '2' },
  // 项目地址
  { label: 'common.orderAddress', value: '3' },
  // 客户
  { label: 'common.customer', value: '4' },
]
const pickerOptions = {
  shortcuts: [
    {
      // 最近一个月
      text: translate('common.lastMonth'),
      onClick(picker: any) {
        const start = moment().subtract(1, 'months').format('YYYY-MM-DD')
        const end = moment().format('YYYY-MM-DD')
        picker.$emit('pick', [start, end])
      },
    },
    {
      // 最近半年
      text: translate('common.lastSixMonth'),
      onClick(picker: any) {
        const end = moment().format('YYYY-MM-DD')
        const start = moment().subtract(6, 'months').format('YYYY-MM-DD')
        picker.$emit('pick', [start, end])
      },
    },
    {
      // 最近一年
      text: translate('common.lastYear'),
      onClick(picker: any) {
        const end = moment().format('YYYY-MM-DD')
        const start = moment().subtract(1, 'years').format('YYYY-MM-DD')
        picker.$emit('pick', [start, end])
      },
    },
    {
      // 全部
      text: translate('common.all'),
      onClick(picker: any) {
        picker.$emit('pick', [])
      },
    },
  ],
}

export { searchTypeList, pickerOptions }
