<template>
  <div class="editPwd">
    <div class="warp">
      <div class="bg_img">
        <img src="../assets/image/loginimg.svg" alt="" />
      </div>
      <div class="form_warp">
        <div class="form_title">
          <div>忘记密码</div>
          <span @click="backLogin"
            ><i class="iconfont icon-left"></i>返回登录</span
          >
        </div>
        <div class="form">
          <div>
            <div class="title">手机号</div>
            <div class="flex">
              <a-select
                v-model="areaCode"
                class="mr8"
                show-search
                showArrow
                :filter-option="
                  (input, option) =>
                    option.componentOptions.children[0].text
                      .toLowerCase()
                      .trim()
                      .includes(input.toLowerCase())
                "
                id="editPwd_area_code_select"
              >
                <a-select-option
                  v-for="(item, index) in areaCodeOptions"
                  :key="index"
                  :value="item.area_code"
                  >{{ item.country }}</a-select-option
                >
              </a-select>
              <input
                class="input inline pl5"
                type="text"
                name=""
                id=""
                v-model="loginMsg.phone"
                placeholder="请输入手机号"
              />
            </div>
          </div>
          <div>
            <div class="title">验证码</div>
            <div class="input code">
              <input
                type="text"
                name=""
                id=""
                v-model="loginMsg.code"
                placeholder="请输入验证码"
              />
              <div
                :style="{ color: isGetCode ? '#999999' : '#18A8C7' }"
                @click="getCode"
              >
                {{ isGetCode ? `${time}后重新获取` : '获取验证码' }}
              </div>
            </div>
          </div>
          <div>
            <div class="title">密码</div>
            <div class="input">
              <input
                type="password"
                name=""
                id=""
                v-model="loginMsg.pwd"
                placeholder="请输入密码"
              />
            </div>
          </div>
          <div>
            <div class="title">确认密码</div>
            <div class="input">
              <input
                type="password"
                name=""
                id=""
                v-model="loginMsg.surePwd"
                placeholder="请确认密码"
              />
            </div>
          </div>
        </div>
        <div class="btns">
          <div class="current_btn" @click="editPwd">修改密码</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { areaCodeOptions } from '@/util/area_code'

import commonFun from '../util/commonFun'

let md5 = require('md5')

export default {
  data() {
    return {
      loginMsg: {
        phone: '',
        pwd: '',
        surePwd: '',
        code: '',
      },
      isGetCode: false,
      time: 60,
      getCodeTimeout: null,
      isRegister: false,
      areaCode: '+86',
      areaCodeOptions,
    }
  },
  mounted() {},
  methods: {
    backLogin() {
      this.$router.push({
        path: '/login',
      })
    },
    getCode() {
      if (this.isGetCode) return
      if (!this.loginMsg.phone.match(/^1[3|4|7|5|8|6|9][0-9]\d{4,8}$/)) {
        this.$message({
          message: '手机号码格式不正确',
          type: 'error',
          duration: 2000,
        })
        return
      }
      this.isGetCode = true
      let key = {
        phone: this.loginMsg.phone,
        sms_type: 'reset_password',
        area_code: this.areaCode,
      }
      let RSAKey = commonFun.calcKeyByRSA(key)
      this.$post(
        '/send_code',
        key,
        (res) => {
          if (res.status == 1) {
            this.$message({
              message: '发送成功',
              type: 'success',
              duration: 2000,
            })
            this.getCodeTimeout = setInterval(() => {
              this.time -= 1
              if (!this.time) {
                this.isGetCode = false
                clearInterval(this.getCodeTimeout)
                this.time = 60
              }
            }, 1000)
          } else {
            this.$message({
              message: res.msg,
              type: 'warning',
              duration: 2000,
            })
          }
        },
        { sign: RSAKey }
      )
    },
    editPwd() {
      if (this.isRegister) return
      if (!this.loginMsg.phone.match(/^1[3|4|7|5|8|6|9][0-9]\d{4,8}$/)) {
        this.$message({
          message: '手机号码格式不正确',
          type: 'error',
          duration: 2000,
        })
        return
      }
      if (!this.loginMsg.pwd) {
        this.$message({
          message: '请输入密码',
          type: 'error',
          duration: 2000,
        })
        return
      }
      if (this.loginMsg.pwd != this.loginMsg.surePwd) {
        this.$message({
          message: '两次密码输入不一致',
          type: 'error',
          duration: 2000,
        })
        return
      }
      if (!this.loginMsg.code) {
        this.$message({
          message: '请输入验证码',
          type: 'error',
          duration: 2000,
        })
        return
      }
      let key = {
        password: md5(this.loginMsg.pwd),
        phone: this.loginMsg.phone,
        repeat_password: md5(this.loginMsg.surePwd),
        sms_code: this.loginMsg.code,
        area_code: this.areaCode,
      }
      let RSAKey = commonFun.calcKeyByRSA(key)
      this.$post(
        '/reset_password',
        key,
        (res) => {
          if (res.status == 1) {
            this.$message({
              type: 'success',
              message: '修改成功，即将跳转登录页面',
              duration: 2000,
            })
            this.isRegister = true
            setTimeout(() => {
              this.$router.push({
                path: '/login',
              })
            }, 1000)
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
              duration: 2000,
            })
          }
        },
        { sign: RSAKey }
      )
    },
  },
  beforeDestroy() {
    clearInterval(this.getCodeTimeout)
  },
}
</script>

<style lang="less">
.editPwd {
  width: 100%;
  height: 100vh;
  color: #333;
  background-color: #fff;
  .warp {
    position: relative;
    top: 50%;
    display: flex;
    justify-content: space-between;
    width: 900px;
    height: 500px;
    margin: 0 auto;
    transform: translateY(-50%);
    .bg_img {
      width: 441px;
      height: 491px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .form_warp {
      width: 332px;
      .form_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        > div {
          font-weight: bold;
          font-size: 32px;
        }
        span {
          display: flex;
          align-items: center;
          color: #333333;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .form {
        > div {
          margin-bottom: 24px;
          .title {
            margin-bottom: 8px;
            font-size: 14px;
          }
          .input {
            display: flex;
            flex-grow: 1;
            align-items: center;
            height: 40px;
            padding-right: 16px;
            border: 1px solid #cccccc;
            border-radius: 4px;
            outline: none;
            input {
              width: 100%;
              text-indent: 16px;
              border: none;
              outline: none;
              &::-webkit-input-placeholder {
                color: #b0b0b0;
              }
            }
          }
          .code {
            display: flex;
            align-items: center;
            justify-content: space-between;
            input {
              width: 60%;
            }
            div {
              color: #18a8c7;
              cursor: pointer;
            }
          }
        }
      }
      .btns {
        margin-top: 24px;
        .current_btn {
          width: 332px;
          height: 48px;
          color: #ffffff;
          font-size: 16px;
          line-height: 48px;
          letter-spacing: 4px;
          text-align: center;
          background: #18a8c7;
          border-radius: 4px;
          cursor: pointer;
        }
        .to_btn {
          display: flex;
          justify-content: space-between;
          margin-top: 16px;
          color: #18a8c7;
          font-size: 14px;
          > span {
            cursor: pointer;
          }
        }
      }
    }
  }
  .ant-select-selection--single {
    min-width: 140px;
    height: 40px;
  }
  .ant-select-selection-selected-value {
    line-height: 40px;
  }
}
</style>
