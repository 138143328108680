import { deepCompare } from '@/views/materialsList/util/preLayoutDeal'

// 生成比较用的表单数据
export function genCompareFormData(
  current: any,
  ori: any,
  compareMsg: any,
  compareOptions: { formTextObj: any; formValueObj: any }
) {
  return {
    type: 'form',
    current,
    ori,
    compareMsg,
    formTextObj: compareOptions.formTextObj,
    formValueObj: compareOptions.formValueObj,
  }
}

export function genCompareFormRecord(
  current: any,
  ori: any,
  compareMsg: any,
  compareOptions: { formTextObj: any; formValueObj: any }
) {
  const re = deepCompare(current, ori, true)
  const recordKeys = Object.keys(re)
  const finalRecordList = recordKeys.map((key) => {
    const result = { key: '', value: [] as any[] }
    const findItem = compareMsg.find((item: any) => item.keys.includes(key))
    if (findItem) {
      result.key = findItem.title
    }
    const secondKey = compareOptions.formTextObj[key]
    let oldValue = re[key][1]
    let newValue = re[key][0]
    if (Object.keys(compareOptions.formValueObj).includes(key)) {
      oldValue = compareOptions.formValueObj[key][oldValue]
      newValue = compareOptions.formValueObj[key][newValue]
    }
    result.value.push({
      key: secondKey,
      oldValue,
      value: newValue,
    })
    return result
  })
  return finalRecordList
}
