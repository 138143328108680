export const preLayoutSettingConfigMap = {
  // knife: '刀直径设置',
  diameter: '刀直径',
  // holeFaceSetting: '孔槽集中面设置',
  holeConcentrated: '孔集中面',
  prorate_holes_slots_of_both_side: '按比例分配正反面孔槽',
  apertureSlotConcentrated: '槽集中面',
  // processChainAndHoleOnBigPlaneBack:
  //   '拉直器、孔槽加工设置-拉直器和铰链杯孔放到大板反面加工',
  custom_special_hole_slot_side: '特殊孔槽规则加工面',
  throughHoleSlotSameSide:
    '拉直器、孔槽加工设置-打穿孔槽所在面跟随该小板上未穿孔槽',
  percentage_of_back_hole_slot: '反面孔槽占比',
  // highgloss_setting: '高光板设置',
  highgloss_direction: '高光板排版方向',
  // edgePlankSlotDirection: '靠边板槽设置',
  sideBoardPlacing: '靠边板槽方向',
  // countertopSetting: '台面设置',
  xyReverse: 'XY轴互换',
  startPosition: '工位起始点',
  // longPlankCuttingSetting: '长板加工设置',
  longPlankCuttingEnable: '长板加工',
  vacuumPumpPauseWay: '真空关闭方式',
  longPlankShortExpandedSize: '短边放量',
  longPlankExpandedSize: '长边放量',
  longPlankMinLength: '长板分两次切割条件-长边超过',
  longPlankShortMinLength: '长板分两次切割条件-短边超过',
  generalPlankThick: '二次切割对象-常规班厚度≥',
  cutSingleDoor: '二次切割对象-门板',
  cutGeneralPlank: '二次切割对象-常规板',
  // surplusSetting: '余料摆放位置设置',
  surplusPosition: '余料摆放位置',
  // decimalSetting: '小数保留位数设置',
  decimal: '小数保留位数',
  // boardGapsSetting: '排版间隙设置',
  boardGap: '排版间隙',
  // barCodeSetting: '板件条码设置',
  uniqueBarCode: '板件条码唯一',
  genSimpleLabelBarcode: '生成简易条码',
  throughTowSideToCut: '通孔加工方式',
  through_slot_two_side_cut: '通槽加工方式',
  mentaoji_enable: '启用锯切雕刻机',
  sawToLayout: '启用锯片厚度排版',
  horizontalWidth: '锯片横切刀直径',
  edgeNoSaw: '启用已封好不再切的大板边',
  edgeNoSawSide: '已封好不再切的大板边值',
}

const switchObj = {
  true: '开启',
  false: '关闭',
}

export const preLayoutSettingConfigValues = {
  holeConcentrated: {
    front: '正面',
    back: '反面',
  },
  apertureSlotConcentrated: {
    front: '正面',
    back: '反面',
  },
  sideBoardPlacing: {
    default: '系统默认',
    inside: '大板内侧',
    outside: '大板外侧',
  },
  longPlankCuttingEnable: switchObj,
  vacuumPumpPauseWay: {
    after_one_plank: '单块长板切割后',
    after_all_plank: '整张大板切割后',
  },
  cutSingleDoor: switchObj,
  cutGeneralPlank: switchObj,
  surplusPosition: {
    topLeft: '左上角',
    topRight: '右上角',
    bottomLeft: '左下角',
    bottomRight: '右下角',
  },
  uniqueBarCode: switchObj,
  genSimpleLabelBarcode: switchObj,
  prorate_holes_slots_of_both_side: switchObj,
  throughTowSideToCut: {
    true: '分两次打穿',
    false: '一次打穿',
  },
  through_slot_two_side_cut: {
    true: '分两次打穿',
    false: '一次打穿',
  },
  mentaoji_enable: switchObj,
  sawToLayout: switchObj,
}

export const preLayoutSettingConfigCompareList = [
  {
    title: '开料刀直径',
    keys: ['diameter'],
  },
  {
    title: '孔槽集中面',
    keys: [
      'holeConcentrated',
      'prorate_holes_slots_of_both_side',
      'apertureSlotConcentrated',
      // 'processChainAndHoleOnBigPlaneBack',
      'throughHoleSlotSameSide',
      'percentage_of_back_hole_slot',
    ],
  },
  {
    title: '高光板设置',
    keys: ['highgloss_direction'],
  },
  {
    title: '靠边板槽放置',
    keys: ['sideBoardPlacing'],
  },
  {
    title: '台面设置',
    keys: ['xyReverse', 'startPosition'],
  },
  {
    title: '长板加工',
    keys: [
      'longPlankCuttingEnable',
      'vacuumPumpPauseWay',
      'longPlankShortExpandedSize',
      'longPlankExpandedSize',
      'longPlankMinLength',
      'longPlankShortMinLength',
      'generalPlankThick',
      'cutSingleDoor',
      'cutGeneralPlank',
    ],
  },
  {
    title: '余料设置',
    keys: ['surplusPosition'],
  },
  {
    title: '小数保留位数',
    keys: ['decimal'],
  },
  {
    title: '排版间隙设置',
    keys: ['boardGap'],
  },
  {
    title: '板件条码设置',
    keys: ['uniqueBarCode', 'genSimpleLabelBarcode'],
  },
  {
    title: '切割打孔方式',
    keys: ['throughTowSideToCut', 'through_slot_two_side_cut'],
  },
  {
    title: '防跑板设置',
    keys: ['scattered_layout', 'safe_length'],
  },
  {
    title: '锯切雕刻机设置',
    keys: [
      'mentaoji_enable',
      'sawToLayout',
      'horizontalWidth',
      'edgeNoSaw',
      'edgeNoSawSide',
    ],
  },
]
