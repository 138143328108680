// 普通的请求
export function generateRequestWithHost(request) {
  return {
    // 获取用户信息
    getUserInfo: (payload) => {
      return request
        .get(`http://guigui.thinkerx.com/user/data/${payload}`)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 获取电子锯标签池
    getElectronicSawTags: (payload) => {
      return request
        .get(`/get_electronic_saw_label`, payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 获取合作商家数量
    getCooperationCount: () => {
      return request
        .get(`https://guigui.admin.thinkerx.com/eggrj/user_count`)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 获取每日排版数据
    getYunRankTotal(payload) {
      return request
        .get('https://guicloud.thinkerx.com/py/statistics_paiban', payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
  }
}

// 需要请求头携带token的请求
export function generateHeaderWithHost(request) {
  return {
    // 获取用户生产线
    getUserLine: () => {
      return request
        .get(`/get_production_line_setting`)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 修改生产线名字
    updateProductionLineName: (payload) => {
      return request
        .post('/update_production_line_name', payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 删除生产线
    deleteProductionLine: (payload) => {
      return request
        .post('/delete_production_line', payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 保存生产线设置
    saveProductionLineSetting: (payload) => {
      return request
        .post('/save_production_line_setting', payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 添加生产线
    createProductionLineSetting: (payload) => {
      return request
        .post('/create_production_line_setting', payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 获取ptp设置
    loadPtpSetting: (payload) => {
      return request
        .post('/load_ptp_setting', payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 保存ptp设置
    savePtpSetting: (payload) => {
      return request
        .post('/save_ptp_setting', payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 获取电子锯配置
    getElectronicSawSetting: (payload) => {
      return request
        .post('/load_electronic_saw_setting', payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 保存电子锯配置
    saveElectronicSawSetting: (payload) => {
      return request
        .post('/save_electronic_saw_setting', payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 保存雕刻机配置
    saveEngravingSetting: (payload) => {
      return request
        .post('/save_engraving_setting', payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 读取雕刻机配置
    getEngravingSetting: (payload) => {
      return request
        .post('/load_engraving_setting', payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 读取六面钻设置
    loadSixFace: (payload) => {
      return request
        .post(`/load_dock6F_setting`, payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 保存六面钻设置
    saveSixFace: (payload) => {
      return request
        .post(`/save_dock6F_setting`, payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 读取五面钻设置
    loadFiveFace: (payload) => {
      return request
        .post(`/load_dock5F_setting`, payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 保存五面钻设置
    saveFiveFace: (payload) => {
      return request
        .post(`/save_dock5F_setting`, payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 获取生产线nc设置
    getLineNcSetting: (payload) => {
      return request
        .get(`/get_production_nc_setting?setting_id=${payload}`)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 获取需要排版的数据
    getMaterialTransData: (payload) => {
      return request
        .get(`/get_material_trans_data?id=${payload}`)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 根据token获取用户信息
    getUserInfoByToken: () => {
      return request
        .post(`/get_user_info`)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 获取排版预处理数据
    getPrePaibanData: (payload) => {
      return request
        .post(`/get_layout_data`, payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
  }
}
