<template>
  <div class="cutting-select mt20">
    <div class="standard_slab_head">
      <div class="standard-slab-title">
        <div class="bluelable"></div>
        <span class="small-title mr10">原片下料刀选择</span>
        <span class="cutting-tip"
          >(在需要不用材质和厚度的大板用不同下料刀时才使用此功能)</span
        >
      </div>
      <el-button
        icon="el-icon-plus"
        @click="createBlankData"
        size="small"
        type="primary"
        >添加</el-button
      >
    </div>
    <div class="special-slab-list">
      <el-table
        border
        :header-cell-style="{ backgroundColor: '#f5f5f5' }"
        :data="blankList"
        :row-class-name="blankRowClassName"
        @row-click="blankRowClick"
        :row-style="blankSelectedRowStyle"
      >
        <el-table-column prop="matCode" label="板材材质">
          <template slot-scope="{ row, $index }">
            <div
              v-if="row.isSave"
              class="ml10 cursor-pointer"
              @click="confirmOrEditBlankItem($index, 'matCode')"
            >
              {{ row.matCode }}
            </div>
            <el-input
              v-else
              placeholder="请输入板材材质"
              :ref="`matCode${$index}`"
              v-model="row.matCode"
              class="cutom-style"
              maxlength="12"
              show-word-limit
              @input="
                (event) => handleBlankInput(event, row, 'matCode', $index)
              "
              @blur="handleBlankBlur(row, 'matCode', $index)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="thick" label="板材厚度(mm)">
          <template slot-scope="{ row, $index }">
            <div
              v-if="row.isSave"
              class="ml10 cursor-pointer"
              @click="confirmOrEditBlankItem($index, 'thick')"
            >
              {{ row.thick }}
            </div>
            <el-input
              v-else
              placeholder="请输入板材厚度"
              :ref="`thick${$index}`"
              v-model="row.thick"
              class="cutom-style"
              @input="(event) => handleBlankInput(event, row, 'thick', $index)"
              @blur="handleBlankBlur(row, 'thick', $index)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="knife" label="下料刀具">
          <template slot-scope="{ row, $index }">
            <el-select
              v-model="row.knife"
              class="cutom-style"
              @visible-change="(e) => handleBlankVisible(e, $index)"
            >
              <el-option
                v-for="(item, index) in blankSelectOptins"
                :key="index"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row, $index }">
            <div>
              <el-button
                type="text"
                v-if="!row.isSave"
                @click="confirmOrEditBlankItem($index)"
                :disabled="!(row.matCode && row.thick)"
                >保存</el-button
              >
              <el-button
                type="text"
                v-if="row.cancel"
                @click="handleCancelBlank($index)"
                >取消</el-button
              >
              <el-button
                type="text"
                @click="deleteBlankData($index)"
                v-if="row.haveSaved"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { verifyInputNumber } from '@/util/commonFuncs'

export default {
  data() {
    return {
      // todo
      blankList: [], //新的原片下料刀选择列表
      oldBlankList: [], //老的原片下料刀选择列表
      // todo
      blankSelectOptins: [], //下料刀options
      // 下料刀的列索引
      blankTableRow: null,
    }
  },
  mounted() {
    // 初始化下料刀options todo
    this.initBlankSelectOptins()
  },
  methods: {
    handleBlankVisible(ev, $index) {
      if (ev && this.blankList[$index].haveSaved) {
        this.blankList[$index].isSave = false
      }
    },
    // 添加下料刀行索引
    blankRowClassName({ row, rowIndex }) {
      row.rowIndex = rowIndex
    },
    // 下料刀选中行样式更改
    blankSelectedRowStyle({ rowIndex }) {
      if (this.blankTableRow === rowIndex) {
        return {
          'background-color': 'rgba(24, 168, 199, 0.2)',
        }
      }
    },
    // 下料刀点击行操作
    blankRowClick(row) {
      this.blankTableRow = row.rowIndex
    },
    // 下料刀取消操作
    handleCancelBlank($index) {
      // 保存过后的取消操作
      if (this.blankList[$index].haveSaved) {
        for (const key in this.oldBlankList[$index]) {
          if (Object.hasOwnProperty.call(this.oldBlankList[$index], key)) {
            this.blankList[$index][key] = this.oldBlankList[$index][key]
          }
        }
        this.blankList[$index].isSave = true
        this.blankList[$index].cancel = false
      } else {
        // 未保存的
        this.deleteBlankData($index)
      }
    },
    // 下料刀input校验
    handleBlankInput(val, row, prop, $index) {
      let data
      if (prop == 'thick') {
        data = verifyInputNumber(val, row, [prop], '2-1')
      }
      this.handleBlankInputVerify(prop, $index, row)
    },
    // 下料刀blur校验
    handleBlankBlur(row, prop, $index) {
      let disabled
      // 空值校验
      disabled =
        this.blankList[$index].matCode == '' ||
        this.blankList[$index].thick == ''
      this.handleBlankInputVerify(prop, $index, row, disabled)
    },
    // blankInput校验样式event
    handleBlankInputVerify(prop, $index, row) {
      const inputRefs = `${prop}${$index}`
      let input = this.$refs[inputRefs].$refs.input
      let flag
      row[prop] ? (flag = true) : (flag = false)
      if (flag) {
        input.style.borderColor = ''
      } else {
        input.style.borderColor = 'red'
      }
    },
    // 下料刀保存或者编辑
    confirmOrEditBlankItem($index, type) {
      let edit = true
      // 切换  isSave ->  编辑 || !isSave -> 保存
      this.blankList[$index].isSave = !this.blankList[$index].isSave
      !this.blankList[$index].isSave &&
        this.handleBlankInputfocus($index, edit, type)
      // 点击保存后  取消应消失 点击编辑出现
      this.blankList[$index].isSave
        ? (this.blankList[$index].cancel = false)
        : (this.blankList[$index].cancel = true)

      //todo 保存发送接口
      let data = JSON.parse(JSON.stringify(this.blankList[$index]))
      this.handleBlankDataValue(data)
      // 1-> 未保存过走 create接口 post
      // 2-> 保存过走 update接口 put
      if (!this.blankList[$index].haveSaved) {
        this.blankList[$index].isSave && this.createBlankRequeset(data, $index)
      } else {
        this.blankList[$index].isSave && this.updateBlankRequeset(data, $index)
      }
    },
    // 删除下料刀不需要的参数
    handleBlankDataValue(data) {
      delete data.isSave
      delete data.cancel
      delete data.haveSaved
      delete data.rowIndex
    },
    // todo新建保存Blank接口发送
    async createBlankRequeset(data, $index) {
      let isRepeat = this.handleRepeat(data, $index),
        edit = true
      if (isRepeat) {
        // 保存失败
        this.$message.error('已存在相同的数据')
        this.blankList[$index].isSave = false
        this.blankList[$index].cancel = true
        this.handleBlankInputfocus($index, edit)
      } else {
        // 保存一份下料刀数据
        this.oldBlankList = JSON.parse(JSON.stringify(this.blankList))
        this.handleBlankDataValue(this.oldBlankList[$index])
        // const res = await this.$token('/plane_knife_maps', data)
        // 成功
        this.$message.success('保存成功')
        this.blankList[$index].haveSaved = true
      }
    },
    // 校验下料刀选择是否重复
    handleRepeat(data, $index) {
      let isRepeat, cloneBlankList
      // 去重
      if (this.blankList.length > 1) {
        cloneBlankList = JSON.parse(JSON.stringify(this.blankList)).filter(
          (_, index) => index != $index
        )
        cloneBlankList.forEach((item) => {
          if (
            item['matCode'] == data['matCode'] &&
            item['thick'] == data['thick']
          ) {
            isRepeat = true
          } else {
            isRepeat = false
          }
        })
      }
      return isRepeat
    },
    // todo编辑Blank接口发送
    async updateBlankRequeset(data, $index) {
      let isRepeat = this.handleRepeat(data, $index),
        edit = true
      if (isRepeat) {
        // 保存失败
        this.$message.error('重复了请重新填写')
        this.blankList[$index].isSave = false
        this.blankList[$index].cancel = true
        this.handleBlankInputfocus($index, edit)
      } else {
        // 保存一份下料刀数据
        this.oldBlankList = JSON.parse(JSON.stringify(this.blankList))
        this.handleBlankDataValue(this.oldBlankList)
        // 成功
        this.$message.success('修改成功')
      }
    },
    // 删除下料刀具row
    deleteBlankData($index) {
      if ($index != 0 && !this.blankList[$index - 1].isSave) {
        let refKey = `matCode${$index - 1}`
        const inputRefs = this.$refs[refKey]
        inputRefs.focus()
      }
      if (this.blankList[$index].haveSaved) {
        this.$confirm('操作删除不可恢复,您还要继续吗?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            // 调用删除接口
            this.blankList.splice($index, 1)
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      } else {
        // 取消
        this.blankList.splice($index, 1)
      }
    },
    // todo
    // 新建下料刀具
    createBlankData() {
      const defaultBlankList = {
        matCode: '',
        thick: '',
        knife: 'default',
        isSave: false, //控制 是否显示保存按钮
        cancel: true, //控制 取消 是否展示
        haveSaved: false, //新增的 即为未保存 默认false
      }
      this.blankList.push(defaultBlankList)
      this.handleBlankInputfocus()
      this.blankTableRow = this.blankList.length - 1
    },
    // 新建下料刀具input聚焦
    handleBlankInputfocus($index, edit = false, type) {
      // 编辑聚焦当前row的材质
      if (edit) {
        this.$nextTick(() => {
          let refKey = `${type}${$index}`
          const inputRefs = this.$refs[refKey]
          inputRefs.focus()
        })
      } else {
        // 添加或删除聚焦最后一条的材质
        this.$nextTick(() => {
          let len = this.blankList.length - 1
          let refKey = `matCode${len}`
          const inputRefs = this.$refs[refKey]
          inputRefs.focus()
        })
      }
    },
    // 初始化下料刀options数据
    initBlankSelectOptins() {
      for (let index = 0; index < 20; index++) {
        this.blankSelectOptins[index] = {
          label: 'T' + (index + 1),
          value: 'T' + (index + 1),
        }
      }
      this.blankSelectOptins.unshift({
        value: 'default',
        label: '系统默认',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
// 原片下料刀选择
.cutting-select {
  .standard_slab_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;
    background: #fff;
    .standard-slab-title {
      display: flex;
      .bluelable {
        width: 4px;
        height: 14px;
        margin: 19px 16px;
        background: #18a8c7;
        border-radius: 1px;
      }
      .cutting-tip {
        align-self: center;
      }
    }
  }
}
</style>
