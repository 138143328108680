/** 文件用于处理不影响板件绘制的其他额外绘制或操作 */
import type { Conflict, ConflictType, PartType } from '@/partTypes'
import { dealBigPlank, dealSlotPoly } from '@/util/dealPaibanData'
import { getSlotBasicInfo } from '@/util/plankCommonFuncs'
import { calcIntersectPoint } from '@/util/polygonRelation'

import { toDecimal } from './commonFuncs'

type Point = { x: number; y: number }
type LineType = { x1: number; y1: number; x2: number; y2: number }
type DistanceResultType = ReturnType<typeof dealCurrentPointDistance>
type DetailInfo = { msg: string; color?: string }[]
enum ScaleType {
  ScaleUp,
  ScaleDown,
}
function getHoleSlotConflictMap(type: ConflictType, side: number) {
  const typeMap = {
    identicalIntersect: `同在${side == 1 ? '正面' : '反面'}并且相交`,
    differentIntersect: '互在正反面,深度相加超过板厚,并且相交',
    beyond: '超出板件',
  }
  return typeMap[type]
}
const CANVAS_TEXT_LINE_COLOR = '#0033FF'
/** 板件空白位置距离操作 start */
// 一些需要多个方法使用的变量
let ScalePercent = 1
let DefaultScale = 5
// 数据初始化生成具体起始点
function initPlankDir(
  bigPart: any,
  deviation: number,
  padding: number,
  scalePercent: number,
  defaultScale: number,
  page: 'paiban' | 'jingxi'
) {
  const [bigPartStartX, bigPartStartY] =
    page === 'jingxi'
      ? [bigPart.subtleStartX, bigPart.subtleStartY]
      : [bigPart.startX, bigPart.startY]
  const bigPartLeft = bigPartStartX + deviation
  const bigPartTop = bigPartStartY + deviation + padding
  // 初始化数据
  ScalePercent = scalePercent
  DefaultScale = defaultScale
  return [bigPartLeft, bigPartTop]
}
/**
 *
 * @param bigPart 大板信息
 * @param point 当前需要计算的点位
 * @param deviation 四周距离
 * @param padding 顶部距离
 * @param scalePercent 缩放
 * @param defaultScale 默认缩放
 */
export function dealCurrentPointDistance(
  bigPart: any,
  point: Point,
  deviation: number,
  padding: number,
  scalePercent: number,
  defaultScale: number,
  page: 'paiban' | 'jingxi'
) {
  // 大板的起始点
  const [bigPartLeft, bigPartTop] = initPlankDir(
    bigPart,
    deviation,
    padding,
    scalePercent,
    defaultScale,
    page
  )
  const leftDistances: number[] = []
  const bottomDistances: number[] = []
  const rightDistances: number[] = []
  const topDistances: number[] = []
  // 保存所有最终需要计算的线段
  const finalLineArr: LineType[] = []
  if (!bigPart.bigPlankShrinkPolygon) {
    // 找不到bigPlankShrinkPolygon，说明订单是之前存在的，并且未重新排版,需要重新生成bigPlankShrinkPolygon
    dealBigPlank(bigPart)
  }
  // 将大板轮廓处理为线段
  dealPointToLine(
    bigPart.bigPlankShrinkPolygon,
    finalLineArr,
    bigPartLeft,
    bigPartTop
  )
  // const targetData = bigPart.parts.concat(bigPart.clampHandInfo ?? [])
  const targetData = bigPart.parts.concat([])
  for (const part of targetData) {
    // 判断当前板件是否是可以使用的
    const result = compareRectOrPoint(part, point, bigPartLeft, bigPartTop)
    if (!result) continue
    const { path, startX, startY } = part
    if (path) {
      // 将板件路径处理为线段
      dealPointToLine(
        path[0],
        finalLineArr,
        dealPointScale(startX) + bigPartLeft,
        dealPointScale(startY) + bigPartTop
      )
    } else {
      dealPointToLine(result, finalLineArr)
    }
  }
  // 处理最终的所有线段，获取到最小的四边距离
  finalLineArr.forEach((it) => {
    if (it.x1 === it.x2 && dealLineContainPoint(it.y1, it.y2, point.y)) {
      // 垂直边
      if (it.x1 < point.x) {
        // 左边
        leftDistances.push(Math.abs(point.x - it.x1))
      } else {
        // 右边
        rightDistances.push(Math.abs(point.x - it.x1))
      }
    } else if (it.y1 === it.y2 && dealLineContainPoint(it.x1, it.x2, point.x)) {
      // 水平边
      if (it.y1 < point.y) {
        // 上边
        topDistances.push(Math.abs(point.y - it.y1))
      } else {
        // 下边
        bottomDistances.push(Math.abs(point.y - it.y1))
      }
    } else {
      // 斜率存在的边
      const b = { x: point.x, y: point.y }
      const c = { x: it.x1, y: it.y1 }
      const d = { x: it.x2, y: it.y2 }
      // 左边
      if (it.x1 < point.x && dealLineContainPoint(it.y1, it.y2, point.y)) {
        const a = { x: 0, y: point.y }
        dealSlashDistance('horizontal', a, b, c, d, leftDistances, point)
      }
      // 右边
      if (it.x1 > point.x && dealLineContainPoint(it.y1, it.y2, point.y)) {
        const a = { x: 0, y: point.y }
        dealSlashDistance('horizontal', a, b, c, d, rightDistances, point)
      }
      // 上边
      if (it.y1 < point.y && dealLineContainPoint(it.x1, it.x2, point.x)) {
        const a = { x: point.x, y: 0 }
        dealSlashDistance('vertical', a, b, c, d, topDistances, point)
      }
      // 下边
      if (it.y1 > point.y && dealLineContainPoint(it.x1, it.x2, point.x)) {
        const a = { x: point.x, y: 0 }
        dealSlashDistance('vertical', a, b, c, d, bottomDistances, point)
      }
    }
  })
  // 获取最小距离
  const leftMin = Math.min(...leftDistances)
  const bottomMin = Math.min(...bottomDistances)
  const rightMin = Math.min(...rightDistances)
  const topMin = Math.min(...topDistances)
  // 如出现Infinity表示点击到了板件外，则返回false
  const isHaveInfinity = [leftMin, bottomMin, rightMin, topMin].some(
    (it) => it === Infinity
  )
  return isHaveInfinity
    ? false
    : {
        left: leftMin,
        bottom: bottomMin,
        right: rightMin,
        top: topMin,
      }
}
// 处理不是水平或垂直的线段距离
function dealSlashDistance(
  type: 'horizontal' | 'vertical',
  a: Point,
  b: Point,
  c: Point,
  d: Point,
  collect: number[],
  point: Point
) {
  const calcResult = calcIntersectPoint(a, b, c, d)
  if (calcResult) {
    switch (type) {
      case 'horizontal':
        collect.push(Math.abs(point.x - calcResult.x))
        break
      case 'vertical':
        collect.push(Math.abs(point.y - calcResult.y))
        break
    }
  }
}
// 线段是否包含某一点位
function dealLineContainPoint(d1: number, d2: number, value: number) {
  const [min, max] = [d1, d2].sort((a, b) => a - b)
  return value >= min && value <= max
}
// 将点位处理成线段并添加到最终使用的数组内
function dealPointToLine(
  pointArr: Point[],
  collect: any[],
  startX?: number,
  startY?: number
) {
  const length = pointArr.length
  const flag = !!(startX && startY)
  pointArr.forEach((it, idx: number) => {
    const nextPoint = pointArr[(idx + 1) % length]
    const { x: x1, y: y1 } = it
    const { x: x2, y: y2 } = nextPoint
    const line = {
      x1: flag ? startX! + dealPointScale(x1) : x1,
      y1: flag ? startY! + dealPointScale(y1) : y1,
      x2: flag ? startX! + dealPointScale(x2) : x2,
      y2: flag ? startY! + dealPointScale(y2) : y2,
    }
    collect.push(line)
  })
}
// 比较当前板件是否是可以使用
function compareRectOrPoint(
  part: any,
  point: Point,
  bigPartStartX: number,
  bigPartStartY: number
) {
  // eslint-disable-next-line prefer-const
  let { rect, startX, startY } = part
  startX = dealPointScale(startX) + bigPartStartX
  startY = dealPointScale(startY) + bigPartStartY
  // 将rect处理成路径
  const path = [
    { x: startX, y: startY },
    { x: startX + dealPointScale(rect.width), y: startY },
    {
      x: startX + dealPointScale(rect.width),
      y: startY + dealPointScale(rect.height),
    },
    { x: startX, y: startY + dealPointScale(rect.height) },
  ]
  const length = 4
  // 出现线段包含点位则此板件可用
  const flag = path.some((it, idx) => {
    const nextPoint = path[(idx + 1) % length]
    let xFlag = false,
      yFlag = false
    if (it.x === nextPoint.x) {
      yFlag = dealLineContainPoint(it.y, nextPoint.y, point.y)
    } else if (it.y === nextPoint.y) {
      xFlag = dealLineContainPoint(it.x, nextPoint.x, point.x)
    }
    return xFlag || yFlag
  })
  return flag ? path : flag
}
// 处理缩放与恢复
function dealPointScale(
  value: number,
  scaleType: ScaleType = ScaleType.ScaleDown
) {
  return scaleType === ScaleType.ScaleDown
    ? (value / DefaultScale) * ScalePercent
    : (value / ScalePercent) * DefaultScale
}
// 绘制空白位置距离
export function drawPlankEmptyDistance(
  ctx: CanvasRenderingContext2D,
  distances: DistanceResultType,
  point: Point
) {
  if (!distances) return
  Object.keys(distances).forEach((key: any) => {
    const cutDistance = Math.abs(distances[key as keyof DistanceResultType])
    switch (key) {
      case 'left':
        drawLine(ctx, point, { x: point.x - cutDistance, y: point.y })
        break
      case 'bottom':
        drawLine(ctx, point, { x: point.x, y: point.y + cutDistance })
        break
      case 'right':
        drawLine(ctx, point, { x: point.x + cutDistance, y: point.y })
        break
      case 'top':
        drawLine(ctx, point, { x: point.x, y: point.y - cutDistance })
        break
    }
  })

  const left = dealPointScale(distances.left, ScaleType.ScaleUp)
  const bottom = dealPointScale(distances.bottom, ScaleType.ScaleUp)
  const right = dealPointScale(distances.right, ScaleType.ScaleUp)
  const top = dealPointScale(distances.top, ScaleType.ScaleUp)
  ctx.save()
  const text = `${+(+left + +right).toFixed(2) * 1}×${
    +(+bottom + +top).toFixed(2) * 1
  }`
  ctx.textAlign = left > right ? 'right' : 'left'
  ctx.fillStyle = CANVAS_TEXT_LINE_COLOR
  ctx.textBaseline = 'bottom'
  ctx.fillText(text, point.x, point.y)
  ctx.restore()
}
// 绘制一条直线
function drawLine(
  ctx: CanvasRenderingContext2D,
  startPoint: Point,
  endPoint: Point
) {
  ctx.beginPath()
  ctx.save()
  ctx.strokeStyle = CANVAS_TEXT_LINE_COLOR
  ctx.moveTo(startPoint.x, startPoint.y)
  ctx.lineTo(endPoint.x, endPoint.y)
  ctx.stroke()
  ctx.restore()
}
/** 板件空白位置距离操作 end */

/** 板件孔槽详细信息绘制 start */
export function drawActivePlankOtherInfo(
  bigPart: any,
  point: Point,
  plank: PartType,
  setting: Record<
    'deviation' | 'padding' | 'scalePercent' | 'defaultScale',
    number
  >,
  page: 'paiban' | 'jingxi'
) {
  const { deviation, padding, scalePercent, defaultScale } = setting
  // 大板的起始点
  const [bigPartLeft, bigPartTop] = initPlankDir(
    bigPart,
    deviation,
    padding,
    scalePercent,
    defaultScale,
    page
  )
  const startX = dealPointScale(plank.startX) + bigPartLeft
  const startY = dealPointScale(plank.startY) + bigPartTop
  const activePoint = dealWHToPoint(
    {
      width: plank.rect.width,
      height: plank.rect.height,
      startX,
      startY,
    },
    'X'
  )

  const isPointInsidePlank = window.ClipperLib.Clipper.PointInPolygon(
    { X: point.x, Y: point.y },
    activePoint
  )
  if (!isPointInsidePlank) return []
  // 生成需要显示的数据
  const finalInfo: DetailInfo[] = [
    ...genHoleDetailInfo(plank, point, startX, startY),
    ...genSlotDetailInfo(plank, point, bigPartLeft, bigPartTop),
    ...genCurveHolesDetailInfo(plank, point, startX, startY),
  ].filter((info) => info.length)
  // 处理最终需要显示的数据
  const len = finalInfo.length
  return finalInfo
    .map((info, idx) => {
      if (idx < len - 1 || (!idx && len > 2)) {
        info.push({ msg: 'split' })
      }
      return info
    })
    .flat(2)
}
function dealWHToPoint(
  args: Record<'width' | 'height' | 'startX' | 'startY', number>,
  pointKey: 'x' | 'X' = 'x'
) {
  // eslint-disable-next-line prefer-const
  let { width, height, startX, startY } = args
  const pointKeyY = pointKey === 'x' ? 'y' : 'Y'
  width = dealPointScale(width)
  height = dealPointScale(height)
  return [
    { [pointKey]: startX, [pointKeyY]: startY },
    { [pointKey]: startX + width, [pointKeyY]: startY },
    {
      [pointKey]: startX + width,
      [pointKeyY]: startY + height,
    },
    { [pointKey]: startX, [pointKeyY]: startY + height },
  ]
}
// 绘制孔槽详细信息卡片
export function drawPlankHSDetailInfo(
  ctx: CanvasRenderingContext2D,
  detailTipInfo: DetailInfo,
  point: Point
) {
  if (!detailTipInfo?.length) return
  // 文字大小
  const fontSize = 12
  // 内边距
  const padding = 10
  // 行高
  const lineHeight = fontSize * 1.5
  // 字体样式
  ctx.font = `bold ${fontSize}px Arial`
  // 获取最长的文字宽度
  const maxTextInfo = detailTipInfo.reduce(
    (acc, next) => {
      const text = next.msg
      const tWidth = ctx.measureText(text).width
      if (tWidth > acc.width) {
        return { width: tWidth, text }
      }
      return acc
    },
    { width: 0, text: '' }
  )
  // 卡片大小
  const mainRectWidth = maxTextInfo.width + padding
  const mainRectHeight = detailTipInfo.length * lineHeight + padding
  // 生成分隔符，同时保证分隔符分割整个绘制的框体
  let splitText = '-'.repeat(maxTextInfo.text.length)
  while (ctx.measureText(splitText).width < mainRectWidth) {
    splitText += '-'
  }
  // 替换分隔符
  detailTipInfo = detailTipInfo.map((it) =>
    it.msg.includes('split') ? { msg: splitText } : it
  )
  const offset = 5
  const mainRectX = point.x - mainRectWidth / 2
  const mainRectY = point.y - mainRectHeight - offset
  // ctx.fillStyle = 'rgba(0, 0, 0, 0.75)' // antd-vue背景色
  ctx.fillStyle = '#303133' // element-ui背景色
  ctx.save()
  // 位置偏移
  ctx.translate(mainRectX, mainRectY)
  // 绘制整个卡片
  drawRoundRectPath(ctx, mainRectWidth, mainRectHeight, 4)
  ctx.fill()
  ctx.restore()
  // 绘制文字
  detailTipInfo.forEach((it, idx) => {
    // 分隔符会完整的分割整个卡片大小
    const { msg, color } = it
    ctx.fillStyle = color ?? '#ffffff'
    const x =
      point.x +
      padding / 2 -
      mainRectWidth / 2 -
      (msg.includes('-') ? padding / 2 : 0)
    const y = point.y + lineHeight * (idx + 1) - mainRectHeight - offset
    ctx.fillText(msg, x, y)
  })
}
function drawRoundRectPath(
  cxt: CanvasRenderingContext2D,
  width: any,
  height: any,
  radius: any
) {
  cxt.beginPath()
  //从右下角顺时针绘制，弧度从0到1/2PI
  cxt.arc(width - radius, height - radius, radius, 0, Math.PI / 2)
  //矩形下边线
  cxt.lineTo(radius, height)
  //左下角圆弧，弧度从1/2PI到PI
  cxt.arc(radius, height - radius, radius, Math.PI / 2, Math.PI)
  //矩形左边线
  cxt.lineTo(0, radius)
  //左上角圆弧，弧度从PI到3/2PI
  cxt.arc(radius, radius, radius, Math.PI, (Math.PI * 3) / 2)
  //上边线
  cxt.lineTo(width - radius, 0)
  //右上角圆弧
  cxt.arc(width - radius, radius, radius, (Math.PI * 3) / 2, Math.PI * 2)
  //右边线
  cxt.lineTo(width, height - radius)
  cxt.closePath()
}
/**
 * 生成长圆孔详细信息
 * @param plank 板件信息
 * @param point 鼠标位置
 * @param startX X起始点
 * @param startY Y起始点
 * @returns
 */
function genCurveHolesDetailInfo(
  plank: PartType,
  point: Point,
  startX: number,
  startY: number
) {
  const allInfo: DetailInfo[] = []
  plank.curveHoles?.forEach((hole) => {
    if (!hole.path) return
    const polyArr = hole.path.map((it) => ({
      X: dealPointScale(it.x) + startX,
      Y: dealPointScale(it.y) + startY,
    }))
    const isPointInsidePlank = window.ClipperLib.Clipper.PointInPolygon(
      { X: point.x, Y: point.y },
      polyArr
    )
    if (!isPointInsidePlank) return
    const conflictTextArr: DetailInfo = []
    const isHoleThrough = +hole.deep + 0.00001 > plank.thick
    const type = '异形槽类型：' + checkHoleType(hole)
    const size = '异形槽深度：' + hole.deep + ' mm'
    const face = '正反面：' + (hole.side === 1 ? '正面' : '反面')
    const through = '是否打穿：' + (isHoleThrough ? '打穿' : '未打穿')
    conflictTextArr.push(
      { msg: type },
      { msg: size },
      { msg: face },
      { msg: through }
    )
    allInfo.push(conflictTextArr)
  })
  return allInfo
}

function checkHoleType(hole: any) {
  if (hole.knifeName) {
    if (hole.disType) {
      return hole.disType
    } else {
      return '牛角拉手'
    }
  } else {
    return hole.holeType ?? hole.disType
  }
}

/**
 * 生成孔详细信息
 * @param plank 板件信息
 * @param point 鼠标位置
 * @param startX X起始点
 * @param startY Y起始点
 * @returns
 */
function genHoleDetailInfo(
  plank: PartType,
  point: Point,
  startX: number,
  startY: number
) {
  const allInfo: DetailInfo[] = []
  plank.holes.forEach((hole) => {
    let {
      center: { x, y },
    } = hole
    x = dealPointScale(x) + startX
    y = dealPointScale(y) + startY
    const radius = dealPointScale(hole.diameter) / 2
    const distance = Math.sqrt(
      Math.pow(point.x - x, 2) + Math.pow(point.y - y, 2)
    )
    if (distance > radius) return
    const conflictTextArr: DetailInfo = []
    const isHoleThrough = hole.deep + 0.00001 > plank.thick
    const holeType = '孔类型：' + hole.holeType
    const symbolType = '连接件类型：' + hole.symbol
    const holeSize = '孔大小：' + hole.diameter + ' mm'
    const holeDeep = '孔深：' + hole.deep + ' mm'
    const holeFace = '正反面：' + (hole.side === 1 ? '正面' : '反面')
    const through = '是否打穿：' + (isHoleThrough ? '打穿' : '未打穿')
    const location =
      '位置：' +
      `[${toDecimal(hole.ocenter.x, 3)}, ${toDecimal(hole.ocenter.y, 3)}]`
    conflictTextArr.push(
      { msg: holeType },
      { msg: symbolType },
      { msg: holeSize },
      { msg: holeDeep },
      { msg: holeFace },
      { msg: through },
      { msg: location }
    )
    if (hole.conflict) {
      const tipText = { msg: '孔槽产生冲突，原因如下：', color: 'orange' }
      conflictTextArr.push(tipText)
      hole.conflict.forEach((it, idx) => {
        const info = genConflictText(it, idx, hole.side, '孔')
        conflictTextArr.push(info)
      })
    }
    // 每次添加看是否有对穿孔 比对位置和大小信息(临时写，看情况会优化这块)
    const target = allInfo.find(
      (info) =>
        info[2].msg === conflictTextArr[2].msg &&
        info[6].msg === conflictTextArr[6].msg &&
        info[4].msg !== conflictTextArr[4].msg &&
        +info[3].msg.replace(/[^\d]/g, '') +
          +conflictTextArr[3].msg.replace(/[^\d]/g, '') +
          0.00001 >
          plank.thick
    )
    if (target) {
      target[5].msg = target[5].msg + '（对穿孔）'
      conflictTextArr[5].msg = conflictTextArr[5].msg + '（对穿孔）'
    }
    allInfo.push(conflictTextArr)
  })
  return allInfo
}
/**
 * 获取
 * @param plank 板件信息
 * @param point 鼠标位置
 * @param bigPartLeft X起始点
 * @param bigPartTop Y起始点
 * @returns
 */
function genSlotDetailInfo(
  plank: PartType,
  point: Point,
  bigPartLeft: number,
  bigPartTop: number
) {
  const allInfo: DetailInfo[] = []
  plank.slots.forEach((slot) => {
    const arr = dealSlotPoly(slot, plank.startX, plank.startY, false)
    const polyArr = arr.map((it: any) => ({
      X: dealPointScale(it.X) + bigPartLeft,
      Y: dealPointScale(it.Y) + bigPartTop,
    }))
    const isPointInsidePlank = window.ClipperLib.Clipper.PointInPolygon(
      { X: point.x, Y: point.y },
      polyArr
    )
    if (!isPointInsidePlank) return
    const conflictTextArr: DetailInfo = []
    const slotInfo = getSlotBasicInfo(slot)
    const isSlotThrough = slot.deep + 0.00001 > plank.thick
    const slotType = '槽类型：' + slot.symbol
    const slotWidth = '槽宽：' + toDecimal(slotInfo.width, 3) + ' mm'
    const slotLong = '槽长：' + toDecimal(slotInfo.height, 3) + ' mm'
    const slotDeep = '槽深：' + slot.deep + ' mm'
    const slotFace = '正反面：' + (slot.side === -1 ? '反面' : '正面')
    const through = '是否打穿：' + (isSlotThrough ? '打穿' : '未打穿')
    const location =
      '位置：' + `[${toDecimal(slotInfo.x, 3)}, ${toDecimal(slotInfo.y, 3)}]`
    conflictTextArr.push(
      { msg: slotType },
      { msg: slotWidth },
      { msg: slotLong },
      { msg: slotDeep },
      { msg: slotFace },
      { msg: through },
      { msg: location }
    )
    if (slot.conflict) {
      const tipText = { msg: '孔槽产生冲突，原因如下：', color: 'orange' }
      conflictTextArr.push(tipText)
      slot.conflict.forEach((it, idx) => {
        const info = genConflictText(it, idx, slot.side, '槽')
        conflictTextArr.push(info)
      })
    }
    allInfo.push(conflictTextArr)
  })
  return allInfo
}
/**
 * 生成冲突信息
 * @param obj 孔槽中保存的冲突对象
 * @param idx 下标
 * @param side 面
 * @param currentType 孔还是槽
 * @returns
 */
function genConflictText(
  obj: Conflict[0],
  idx: number,
  side: number,
  currentType: '孔' | '槽'
) {
  // 孔还是槽
  const conflictObjType = obj.conflictObjType === 'hole' ? '孔' : '槽'
  let result: unknown = {}
  idx += 1
  // 冲突的原因
  const conflictText = getHoleSlotConflictMap(
    obj.conflictType as ConflictType,
    side
  )
  // 超出板件
  if (obj.conflictType === 'beyond') {
    result = {
      msg: `${idx}. 当前${currentType}已${conflictText}`,
      color: 'orange',
    }
  } else {
    // 获取冲突孔槽的位置
    let { x, y } =
      obj.conflictObjType === 'hole'
        ? obj.conflictObj.ocenter
        : getSlotBasicInfo(obj.conflictObj)
    x = toDecimal(x, 3)
    y = toDecimal(y, 3)
    result = {
      msg: `${idx}. 当前${currentType}与位置 [${x}, ${y}] 的${conflictObjType},两者${conflictText}`,
      color: 'orange',
    }
  }
  return result as DetailInfo[0]
}
/** 板件孔槽详细信息绘制 end */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// 此功能用冲突canvas 缩放和移动以及框图上
class DrawConflict {
  offset = { x: 0, y: 0 } // 拖动偏移
  curOffset = { x: 0, y: 0 } // 记录上一次的偏移量
  mousePosition = { x: 0, y: 0 } // 记录鼠标滚轮点击时的坐标位置
  maxScale = Infinity
  minScale = 0.5
  scaleStep = 0.1
  scale = 1
  preScale = 1
  x = 0 // 记录鼠标点击Canvas时的横坐标
  y = 0 // 记录鼠标点击Canas时的纵坐标
  canvas: HTMLCanvasElement
  width: number
  height: number
  // 矩形的位置
  left?: number
  top?: number
  right?: number
  botton?: number
  // 冲突list
  list?: any[]
  // 是否关闭冲突框
  flag: boolean
  ctx: CanvasRenderingContext2D | null
  cb: () => void
  constructor(
    id: string,
    options = {
      width: 500,
      height: 600,
    }
  ) {
    this.canvas = document.querySelector('#' + id) as HTMLCanvasElement
    this.width = options.width
    this.height = options.height
    this.canvas.width = options.width
    this.canvas.height = options.height
    this.flag = true
    this.ctx = this.canvas.getContext('2d')

    this.onMousedown = this.onMousedown.bind(this)
    this.onMousemove = this.onMousemove.bind(this)
    this.onMouseup = this.onMouseup.bind(this)
    this.onMousewheel = this.onMousewheel.bind(this)
    this.canvas.addEventListener('mousewheel', this.onMousewheel)
    this.canvas.addEventListener('mousedown', this.onMousedown)
    this.cb = () => ({})
  }
  // 滚轮缩放
  onMousewheel(e: any) {
    e.preventDefault()
    if (!e.ctrlKey) return
    this.mousePosition.x = e.offsetX // 记录当前鼠标点击的横坐标
    this.mousePosition.y = e.offsetY // 记录当前鼠标点击的纵坐标
    if (e.wheelDelta > 0) {
      // 放大
      this.scale = parseFloat((this.scaleStep + this.scale).toFixed(2)) // 解决小数点运算丢失精度的问题
      if (this.scale > this.maxScale) {
        this.scale = this.maxScale
        return
      }
    } else {
      // 缩小
      let data = 0
      data = parseFloat((this.scale - this.scaleStep).toFixed(2)) // 解决小数点运算丢失精度的问题
      this.scale = data < this.minScale ? this.minScale : data
    }

    this.zoom()
  }
  zoom() {
    this.offset.x =
      this.mousePosition.x -
      ((this.mousePosition.x - this.offset.x) * this.scale) / this.preScale
    this.offset.y =
      this.mousePosition.y -
      ((this.mousePosition.y - this.offset.y) * this.scale) / this.preScale

    this.paint()
    this.preScale = this.scale
    this.curOffset.x = this.offset.x
    this.curOffset.y = this.offset.y
  }
  onMousedown(e: MouseEvent) {
    if (e.button === 0) {
      // 鼠标左键
      this.x = e.x
      this.y = e.y
      window.addEventListener('mousemove', this.onMousemove)
      window.addEventListener('mouseup', this.onMouseup)
    }
  }
  // 移动
  onMousemove(e: MouseEvent) {
    this.offset.x = this.curOffset.x + (e.x - this.x)
    this.offset.y = this.curOffset.y + (e.y - this.y)
    this.paint()
  }
  onMouseup() {
    this.curOffset.x = this.offset.x
    this.curOffset.y = this.offset.y
    window.removeEventListener('mousemove', this.onMousemove)
    window.removeEventListener('mouseup', this.onMouseup)
  }
  // 放大
  zoomIn() {
    this.scale += this.scaleStep
    if (this.scale > this.maxScale) {
      this.scale = this.maxScale
      return
    }
    this.mousePosition.x = this.width / 2
    this.mousePosition.y = this.height / 2
    this.zoom()
  }
  // 缩放
  zoomOut() {
    this.scale -= this.scaleStep
    if (this.scale < this.minScale) {
      this.scale = this.minScale
      return
    }
    this.mousePosition.x = this.width / 2
    this.mousePosition.y = this.height / 2
    this.zoom()
  }
  // 重置
  reset() {
    this.clear()
    this.scale = 1 // 当前缩放
    this.preScale = 1 // 上一次缩放
    this.offset = { x: 0, y: 0 } // 拖动偏移
    this.curOffset = { x: 0, y: 0 } // 当前偏移
    this.mousePosition = { x: 0, y: 0 }
    this.draw()
  }
  // 改变选中状态
  changeStatus(flag: boolean) {
    this.flag = flag
    if (!flag) {
      this.reset()
    }
  }
  draw(cb?: () => void) {
    if (cb) {
      this.cb = cb
    }
    this.cb()
  }
  clear() {
    this.canvas.width = this.width
  }
  paint(cb?: () => void) {
    this.clear()
    this.ctx!.translate(this.offset.x, this.offset.y)
    this.ctx!.scale(this.scale, this.scale)
    setTimeout(() => {
      this.flag && this.drawDashRect(null)
    }, 0)
    this.draw(cb)
  }
  // 画虚线矩形
  drawDashRect(list: any) {
    this.list = list ?? this.list
    this.list?.forEach((item: any) => {
      const info = {
        top: Math.min(...item.top),
        left: Math.min(...item.left),
        right: Math.max(...item.right),
        bottom: Math.max(...item.bottom),
      }
      const { left, top, bottom, right } = info
      this.left = left
      this.top = top
      this.right = right
      this.botton = bottom
      this.drawReactLine()
    })
  }
  drawReactLine() {
    const { left, top, right, botton } = this
    if (left && top && right && botton) {
      this.drawDashLine([left, top], [right, top], [3, 3])
      this.drawDashLine([right, top], [right, botton], [3, 3])
      this.drawDashLine([right, botton], [left, botton], [3, 3])
      this.drawDashLine([left, botton], [left, top], [3, 3])
    }
  }
  drawDashLine(start: [number, number], end: [number, number], dash: number[]) {
    const deltX = end[0] - start[0]
    const deltY = end[1] - start[1]
    const totalDis = Math.sqrt(deltX * deltX + deltY * deltY)
    const getPos = function (pos: [number, number], dis: number) {
      const deltXS = pos[0] - start[0]
      const deltYS = pos[1] - start[1]
      const baseDis = Math.sqrt(deltXS * deltXS + deltYS * deltYS)
      const percent = (dis + baseDis) / totalDis
      const x = start[0] + deltX * percent
      const y = start[1] + deltY * percent
      return [x, y]
    }

    let dis = 0
    for (let i = 0; i < dash.length; i++) {
      dis += dash[i]
    }
    const count = Math.ceil(totalDis / dis)
    let _start = start.concat([]) as any
    this.ctx!.lineWidth = 1
    this.ctx!.strokeStyle = '#18a8c7'
    this.ctx!.lineCap = 'round'
    this.ctx!.lineJoin = 'round'
    for (let i = 0; i < count; i++) {
      for (let j = 0; j < dash.length; j++) {
        if (j % 2 === 0) {
          this.ctx!.beginPath()
          this.ctx!.moveTo(_start[0], _start[1])
          _start = getPos(_start, dash[j])
          this.ctx!.lineTo(_start[0], _start[1])
          this.ctx!.stroke()
        } else {
          _start = getPos(_start, dash[j])
        }
      }
    }
  }
  // 获取缩放比例
  getScaleRate() {
    return this.scale
  }
}
export default DrawConflict
