import { translate } from '@/util/commonFun'

export function dealBatchBujian(part: any, plankUrl: any) {
  return {
    plate_info: {
      plKId: part.plankID,
      from: 'bluen',
      plankNum: part.plankNum,
      matCode: part.matCode,
      color: part.texture,
      size: `${part.oRect.height}*${part.oRect.width}*${part.thick}`,
      new: true,
      address: part.address,
      customer_name: part.customer_name || '',
    },
    render_url: plankUrl,
  }
}
