import store from '@/store'

export function dealPlankDataForLbl() {
  const orderObj = store.state.preLayoutData
    .filter((part) => part.name && part.name == '导入的板件')
    .reduce((result, currentItem) => {
      const orderNo = String(currentItem.orderNo) + String(currentItem.address)
      if (result[orderNo]) {
        result[orderNo].push(currentItem)
      } else {
        result[orderNo] = [currentItem]
      }
      return result
    }, {})
  const resultPlankData = []
  for (const key in orderObj) {
    resultPlankData.push(orderObj[key])
  }

  return resultPlankData
}
