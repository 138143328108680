import { translate } from './commonFun'
import { dealTaskLoading } from './plankCommonFuncs'

type defultLifeType = {
  checkData: any
  loadSetting: any
  dealNCData: any
  ncloadPreferences: any
  ncdealTemp: any
  genZip: any
  genFile?: any
}

/** 翻译函数 只处理标签页翻译 */
function t(k: string, val?: any) {
  return translate('arrangedPage.ncDownloadLifeText.' + k, val)
}

// 创建下载NC执行过程中的生命周期函数
export const initNcLifeObj = (
  that: any,
  hasExport: string,
  isFollowExportTag: boolean
) => {
  const fileType =
    hasExport === '导出PDF'
      ? translate('common.pdfTag')
      : translate('common.tagImage')
  const defultLifeObj: defultLifeType = {
    checkData: dealTaskLoading(
      t('checkData'),
      t('checkDone'),
      t('checkDataText'),
      that,
      'ncTaskList'
    ),
    loadSetting: dealTaskLoading(
      t('loadSetting'),
      t('settingLoadDone'),
      t('loadSettingText'),
      that,
      'ncTaskList'
    ),
    dealNCData: dealTaskLoading(
      t('downloadNc'),
      t('ncDownloadDone'),
      t('downloadText'),
      that,
      'ncTaskList'
    ),
    ncloadPreferences: dealTaskLoading(
      t('loadPreferenceSetting'),
      t('PreferenceSettingLoadDone'),
      t('loadPreferenceText'),
      that,
      'ncTaskList'
    ),
    ncdealTemp: dealTaskLoading(
      t('dealTemp'),
      t('TempDealDone'),
      t('dealTempText'),
      that,
      'ncTaskList'
    ),
    genFile: isFollowExportTag
      ? dealTaskLoading(
          t('beBeingGenTagFile', { fileType }),
          t('genTagFileDone', { fileType }),
          t('genTagFileText', { fileType }),
          that,
          'ncTaskList'
        )
      : null,
    genZip: dealTaskLoading(
      t('zip'),
      t('zipDone'),
      t('genZipText'),
      that,
      'ncTaskList'
    ),
  }
  return { ...defultLifeObj }
}
