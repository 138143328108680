var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"m-conflict-table"},[_c('div',{staticClass:"table-title flex"},_vm._l(([
        _setup.translateLang('preLayoutSetting.prolineName'),
        _setup.props.oriLine,
        _setup.props.targetLine,
      ]),function(item,index){return _c('div',{key:index,staticClass:"title-cell flex flex-cross--center pl10 bold color-0",class:{ 'first-cell': !index }},[_vm._v(" "+_vm._s(item == '默认生产线' ? _setup.translateLang('common.defaultProduction') : item)+" ")])}),0),_vm._l((_setup.getkeys(_setup.data)),function(item){return _c('div',{key:item,staticClass:"table-row"},[_c('div',{staticClass:"sub-title cursor-pointer flex flex-cross--center pl10 bold",on:{"click":function($event){return _setup.handleExpandTitle(item)}}},[_c('span',{staticClass:"iconfont icon-right inline-block arrow align-center",class:{ 'expaned-arrow': _setup.expandedArr.includes(item) }}),_vm._v(_vm._s(_setup.translateLang(_setup.keyMap.get(item)))+" ")]),_vm._l((_setup.getkeys(_setup.data[item])),function(i){return _c('div',{key:i,staticClass:"flex",class:{ 'row-content': true, expanded: _setup.expandedArr.includes(item) }},_vm._l((3),function(j,index){return _c('div',{key:j,staticClass:"align-left row-cell flex flex-cross--center pl10",class:{ 'first-cell': !index, err: index == 2 }},[(!index && false)?_c('span',{staticClass:"iconfont icon-enlarge enlarge align-center inline-block mr8",staticStyle:{"color":"#e34d59"}}):_vm._e(),(i === 'custom_special_hole_slot_side')?_c('div',{staticStyle:{"max-width":"150px","display":"flex"},attrs:{"title":_setup.translateLang(_setup.keyMap.get(i))}},[_c('span',[_vm._v(_vm._s(_setup.fillContent(index, i, item)))]),_c('br'),(_setup.isShowSpecialRule)?_c('span',_vm._l((_setup.fillSpecialHoleSlot(
                _setup.data[item][i],
                index
              )),function(specialSlotHole){return _c('p',{staticClass:"ellipsis",staticStyle:{"max-width":"200px","margin":"0"},attrs:{"title":specialSlotHole}},[_vm._v(" "+_vm._s(specialSlotHole)),_c('br')])}),0):_vm._e()]):_c('span',{staticClass:"ellipsis",staticStyle:{"max-width":"150px"},attrs:{"title":!index
              ? _setup.translateLang(_setup.keyMap.get(i))
              : ([
                  'through_slot_two_side_cut',
                  'throughTowSideToCut',
                ].includes(i)
                  ? _setup.spcialKeyMap
                  : _setup.keyMap
                ).get(String(_setup.data[item][i][index - 1]))
              ? _setup.translateLang(
                  ([
                    'through_slot_two_side_cut',
                    'throughTowSideToCut',
                  ].includes(i)
                    ? _setup.spcialKeyMap
                    : _setup.keyMap
                  ).get(String(_setup.data[item][i][index - 1]))
                )
              : _setup.data[item][i][index - 1]}},[_vm._v(_vm._s(_setup.fillContent(index, i, item)))])])}),0)})],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }