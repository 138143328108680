<template>
  <div class="container" v-if="showSurplusTemp">
    <div class="temp_head">
      <div class="head_l">{{ $t('arrangedPage.surplusCommonTemp') }}</div>
      <div class="head_r">
        <span @click="openAddModel">{{ $t('common.add') }}</span>
        <!-- <span @click="handleShowSurplus">关闭</span> -->
      </div>
    </div>
    <div class="temp_main" @scroll="loadingData">
      <div
        class="surplus_temp_item"
        v-for="(item, idx) in surPlusData"
        :key="item.id"
        :class="[item.id == active.id ? 'active' : '']"
        @click="handleClickSurPlusItem(item)"
      >
        <span class="index">{{ idx + 1 }}</span>
        <i
          class="close el-icon-close"
          v-show="item.id == active.id"
          @click="delSurPlusTemp(item)"
        ></i>
        <div class="plank">
          <img
            @mousedown="tempOnDown($event, item, idx)"
            :src="item.src"
            alt="暂无图片"
            class="plank_image"
            :style="{ width: item.w, height: item.h }"
          />
        </div>
        <div class="handle">
          <div class="size">
            <span v-show="changeId != item.id">
              {{ parseFloat(item.width) }}
            </span>
            ×
            <span v-show="changeId != item.id">
              {{ parseFloat(item.length) }}
            </span>
            <i
              class="el-icon-edit-outline"
              v-if="item.id == active.id"
              @click.stop="changeInput(item)"
            ></i>
          </div>
          <div class="info">
            {{ item.thick ? parseFloat(item.thick) : '' }}{{ item.matCode
            }}{{ item.texture }}
          </div>
        </div>
      </div>
    </div>
    <addTemp
      :visible.sync="visible"
      :editData="editData"
      :isEdit="isEdit"
      @addTemp="addTemp"
    ></addTemp>
  </div>
</template>

<script>
import addTemp from '@/components/addSurplusTemp'
import { regReplaceSurPlusTempHandleInputVal } from '@/util/regReplace'

// 默认缩放
const defaultScale = 5
// 保存模板基础数据，数据修改后对比是否修改，防止请求频繁
let defaultSurPlusTempData = null

export default {
  props: {
    // 缩放
    scalePercent: {
      type: [Number, String],
      default: 1,
    },
    // 是否显示余料模板
    showSurplusTemp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      // 模板数据
      surPlusData: [],
      // 判断活跃模板
      active: {},
      changeId: -1,
      // 当前拖动的图片
      activeTempImg: null,
      imgPosition: {},
      imgRealSize: {},
      mouseMoveEvent: null,
      mouseE: null,
      img: {},
      // 模板分页
      page: {
        page: 1,
        limit: 6,
      },
      total: 1,
      // 修改数据
      editData: {},
      isEdit: false,
    }
  },
  components: {
    addTemp,
  },
  methods: {
    // 初始化数据
    async initData() {
      const c = document.createElement('canvas')
      const ctx = c.getContext('2d')
      let d = []
      this.surPlusData.forEach((item, idx) => {
        const width = item.width / defaultScale
        const height = item.length / defaultScale
        c.width = width + 10
        c.height = height + 10
        ctx.fillStyle = '#fbcfcf'
        const x = (c.width - width) / 2
        const y = (c.height - height) / 2
        ctx.fillRect(x, y, width, height)
        item.src = c.toDataURL('image/png', 1)
        let scaleW = Number(item.width) / 100
        let scaleH = Number(item.length) / 140
        if (item.width <= 100 && item.length <= 140) {
          if (scaleW >= scaleH) {
            item.w = '100%'
          } else {
            item.h = '100%'
          }
        }
        if (item.width > 100 && item.length > 140) {
          if (scaleW >= scaleH) {
            item.w = Number(item.width) / scaleW + 'px'
            item.h = Number(item.length) / scaleW + 'px'
          } else {
            item.w = Number(item.width) / scaleH + 'px'
            item.h = Number(item.length) / scaleH + 'px'
          }
        }
        if (item.width <= 100 && item.length > 140) {
          item.w = Number(item.width) / scaleH + 'px'
          item.h = Number(item.length) / scaleH + 'px'
        }
        if (item.width > 100 && item.length <= 140) {
          item.w = Number(item.width) / scaleW + 'px'
          item.h = Number(item.length) / scaleW + 'px'
        }
      })
    },
    // 激活活跃样式
    handleClickSurPlusItem(item) {
      this.active = item
      if (item.id != this.changeId && this.changeId != -1) {
        this.changeId = -1
      }
    },
    openAddModel() {
      this.editData = {
        width: '',
        length: '',
        texDir: 'notcare',
        matCode: '',
        texture: '',
        thick: '',
      }
      this.isEdit = false
      this.visible = true
    },
    changeInput(item) {
      this.isEdit = true
      this.visible = true
      this.editData = {
        width: item.width,
        length: item.length,
        texDir: item.texDir,
        matCode: item.matCode,
        texture: item.texture,
        thick: item.thick,
        id: item.id,
      }
    },
    // 获取模板信息
    getSurPlusTempData(page) {
      this.$token('/surplus_model_list', page, (res) => {
        if (res.status === 1) {
          res.data.data.forEach((item) => {
            const isExists = this.surPlusData.find(
              (item1) => item1.id == item.id
            )
            if (!isExists) {
              this.surPlusData.push(item)
            }
          })
          this.total = res.data.total
          this.initData()
          defaultSurPlusTempData = JSON.parse(JSON.stringify(res.data.data))
        } else {
          this.$message.error(res.status)
        }
      })
    },
    loadingData(e) {
      let scrollTop = e.target.scrollTop
      let scrollHeight = e.target.scrollHeight
      let offsetHeight = Math.ceil(e.target.getBoundingClientRect().height)
      let currentHeight = scrollTop + offsetHeight
      if (
        currentHeight >= scrollHeight &&
        this.page.page < Math.ceil(this.total / this.page.limit)
      ) {
        this.page.page += 1
        this.getSurPlusTempData(this.page)
      }
    },
    addTemp(result) {
      const { data, res } = result
      if (!this.isEdit) {
        this.surPlusData.unshift(data)
      } else {
        const temp = this.surPlusData.find((it) => it.id == data.id)
        Object.keys(data).forEach((key) => {
          temp[key] = data[key]
        })
      }
      this.total = res.data.total
      this.initData()
    },
    delSurPlusTemp(temp) {
      if (!temp.id) return this.$message.error('请选择需要删除的模板!')
      const that = this
      this.$antdConfirm({
        title: () => '温馨提示',
        content: () => '确定删除此数据项吗?',
        centered: true,
        onOk() {
          that.$token('/delete_surplus_model', { id: temp.id }, (res) => {
            if (res.status != 1) {
              that.$message.error(res.msg)
            } else {
              that.$message.success('删除成功')
              that.surPlusData.splice(
                that.surPlusData.findIndex((item) => item.id == temp.id),
                1
              )
            }
          })
        },
        onCancel() {},
      })
    },
    // 数据验证
    /**
     * 前两个数据验证增加 后两个数据验证页面上修改  使用是对应的不为null就行
     */
    handleInputVal(obj, key, item, itemKey, isMinus = false) {
      const Reg = isMinus
        ? /(^\-?)\D*(\d{0,5})\d*(\.?)(\d{0,2})\d*/
        : /\D*(\d{0,5})\d*(\.?)(\d{0,2})\d*/
      const repStr = isMinus ? '$1$2$3$4' : '$1$2$3'
      if (item) {
        item[itemKey] = regReplaceSurPlusTempHandleInputVal(
          item[itemKey],
          Reg,
          repStr
        )
      } else {
        this[obj][key] = regReplaceSurPlusTempHandleInputVal(
          this[obj][key],
          Reg,
          repStr
        )
      }
    },
    handleShowSurplus() {
      this.$emit('update:showSurplusTemp', false)
    },

    // 图片拖动生成处理
    tempOnDown(e, item) {
      const currentDom = e.target
      const currentDomPos = currentDom.getBoundingClientRect()
      this.$nextTick(() => {
        e.preventDefault()
        // 触发模板选中
        this.handleClickSurPlusItem(item)
        this.imgPosition = {
          x: e.offsetX / currentDomPos.width,
          y: e.offsetY / currentDomPos.height,
        }
        // 记录现在鼠标相对于浏览器的位置
        let screenPos = {
          x: e.clientX,
          y: e.clientY,
        }
        // 点击的时候, 在鼠标位置生成原始大小的板件图片
        this.activeTempImg = null
        let img = null
        if (this.activeTempImg) {
          img = this.activeTempImg
        } else {
          img = new Image()
        }
        img.src = item.src
        new Promise((resolve) => {
          img.onload = function () {
            resolve({
              x: this.width,
              y: this.height,
            })
          }
        }).then((res) => {
          img.width = res.x * this.scalePercent
          img.height = res.y * this.scalePercent

          this.imgRealSize = res
          let left = e.clientX - (this.imgRealSize.x / 2) * this.scalePercent
          let top = e.clientY - (this.imgRealSize.y / 2) * this.scalePercent
          // 设置其位置
          img.style.position = 'fixed'
          img.style.opacity = '0.7'
          img.style.left = left + 'px'
          img.style.top = top + 'px'
          img.style.display = 'block'
          img.style.zIndex = 99999999
          // 挂载在页面上
          if (!this.activeTempImg) {
            document.body.appendChild(img)
          }
          this.activeTempImg = img
          this.mouseMoveEvent = this.mouseMoveFunc()
          window.addEventListener('mousemove', this.mouseMoveEvent)
          let that = this
          this.activeTempImg.onmouseup = function () {
            that.activeTempImg.style.display = 'none'
            window.removeEventListener('mousemove', that.mouseMoveEvent)
            let event = that.mouseE ? that.mouseE : e
            if (that.mouseE) {
              that.$emit('dealSurPlusCut', item, event)
            }
            // 鼠标抬起event对象置空，防止点击可以触发事件
            that.mouseE = null
          }
        })
      })
    },
    mouseMoveFunc() {
      return (e) => {
        if (this.activeTempImg) {
          e.preventDefault()
          this.mouseE = e
          // 鼠标指针放置在图片中心
          let left = e.clientX - (this.imgRealSize.x / 2) * this.scalePercent
          let top = e.clientY - (this.imgRealSize.y / 2) * this.scalePercent
          this.activeTempImg.style.left = left + 'px'
          this.activeTempImg.style.top = top + 'px'
        }
      }
    },
  },
  created() {
    this.getSurPlusTempData(this.page)
  },
}
</script>

<style lang="less" scoped>
.container {
  position: relative;
  padding: 0 16px;
  .temp_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    padding: 0 10px;
    .head_l {
      font-size: 16px;
    }
    .head_r {
      span {
        margin-left: 10px;
        color: rgb(9, 9, 238);
        cursor: pointer;
      }
    }
  }
  .temp_main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: calc(100vh - 64px - 60px - 346px);
    max-height: calc(100vh - 64px - 60px - 346px - 40px);
    padding: 8px;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid #ccc;
    &::-webkit-scrollbar {
      width: 3px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(24, 168, 199);
      border-radius: 10px;
    }
    .surplus_temp_item {
      position: relative;
      width: 49%;
      height: 200px;
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #ccc;
      &:hover {
        border-color: #0099ff;
      }
      .index {
        position: absolute;
        top: 0;
        left: 0;
      }
      .close {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 16px;
        cursor: pointer;
        &:hover {
          color: #ff0000;
        }
      }
      .plank {
        width: 100%;
        height: 130px;
        text-align: center;
      }
      .handle {
        text-align: center;
        .size {
          // width: calc(100% - 20px);
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          .el-input {
            width: 36px;
            height: 20px;
            border: 1px solid #000;
            border-radius: 3px;
            outline-style: none;
          }
          > span {
            font-size: 13px;
          }
          i {
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .el-drawer {
    .main {
      width: 100%;
      height: 100%;
      padding: 25px 15px;
    }
    .plank {
      text-align: center;
    }
  }
  .tip {
    height: 40px;
    margin-bottom: 20px;
    padding-left: 15px;
    line-height: 40px;
    background-color: rgb(250, 250, 250);
    border-left: 2px solid rgb(24, 168, 199);
  }
  // .handle {
  //   width: 100%;
  //   position: absolute;
  //   bottom: 25px;
  //   display: flex;
  //   justify-content: space-evenly;
  // }
}
.active {
  border-color: #0099ff !important;
}
</style>
<style>
.confirmBtn {
  background-color: #000;
}
</style>
