var specialData = [
  {
    plankNo: 1,
    name: '竖隔板01',
    code: '6282328945674',
    width: 120,
    height: 250.4,
    thick: 18,
    matCode: '多层实木',
    texture: 'T01',
    grain: 0,
    quantity: 1,
    holes: [
      {
        x: 32,
        y: 33,
        diameter: 15,
        depth: 11,
        face: 0,
      },
      {
        x: 64,
        y: 33,
        diameter: 15,
        depth: 11,
        face: 0,
      },
    ],
    slots: [
      {
        x1: 10,
        y1: 0,
        x2: 20,
        y2: 250.4,
        depth: 8,
        face: 5,
      },
    ],
    leftEdge: 0.5,
    rightEdge: 1.5,
    frontEdge: 0.5,
    backEdge: 0.5,
    shape: [
      {
        x: 0,
        y: 0,
      },
      {
        x: 120,
        y: 0,
      },
      {
        x: 120,
        y: 200.4,
      },
      {
        x: 70,
        y: 250.4,
        b: 90,
      },
      {
        x: 0,
        y: 250.4,
      },
      {
        x: 0,
        y: 0,
      },
    ],
    orderNo: '20210811-123',
    address: 'XXX小区',
    customer: '小张',
    designer: '李三',
    roomName: '主卧',
    wardrobeName: '榻榻米',
  },
]

export default specialData
