<template>
  <div class="top-nav">
    <div class="top-nav-content">
      <div class="content-left">
        <div class="logo-box">
          <span>{{ showTiangong ? '天工' : '云排版' }}</span>
          <span>省时，省料，省力气</span>
        </div>
        <div class="nav-list">
          <div
            v-for="(item, index) in navList"
            :key="index"
            :class="[
              'nav-list-item',
              activeNav == item.name ? 'nav-active-item' : '',
            ]"
            @click="changeNav(item)"
          >
            <a-popover
              v-if="item.name == 'detailedlist'"
              placement="bottomRight"
              overlayClassName="material-nav-popover"
            >
              <template slot="content">
                <div class="material-nav">
                  <span
                    v-for="extra in extraNav"
                    :key="extra.id"
                    :class="[
                      'extra-nav',
                      extraActiveNav == extra.name ? 'active-extra' : '',
                    ]"
                    @click="gotoExtraNav(extra)"
                  >
                    {{ extra.title }}
                  </span>
                </div>
              </template>
              <span class="nav-item-title">{{ item.title }}</span>
              <span
                v-if="item.name == 'detailedlist'"
                class="nav-item-title iconfont icon-xia"
              ></span>
            </a-popover>
            <span class="nav-item-title" v-else>{{ item.title }}</span>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="official-website">
          <span @click="gotoOfficialWebsite">进入官网</span>
          <!-- <a-icon type="right" style="color: #000;" /> -->
        </div>
        <div class="helpUsing">
          <span @click="gotoHelpingDocument">帮助</span>
        </div>
        <div class="user-box" v-if="!showTiangong" @click.stop="showPersonInfo">
          <div class="user-head">
            <img :src="userInfo.logo_url" alt="" />
          </div>
          <div class="user_name">
            <span>{{ userInfo.name }}</span>
            <i
              style="fontsize: 12px"
              class="iconfont icon-zhankai"
              v-if="!showPerson"
            ></i>
            <i
              style="fontsize: 12px"
              class="iconfont icon-arrow"
              v-else-if="showPerson"
            ></i>
          </div>
        </div>
        <!-- <userInfoBox v-show="showPerson"></userInfoBox> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import userInfoBox from './userInfo.vue'
export default {
  props: {
    showTiangong: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      // 导航栏
      navList: [
        {
          icon: 'assets/icon/detailedlist_20pt.svg',
          icon2: 'assets/icon/detailedlist_20pt_checked.svg',
          title: '排版生产',
          name: 'detailedlist',
          path: '/materialList',
        },
        {
          icon: 'assets/icon/detailedlist_20pt.svg',
          icon2: 'assets/icon/detailedlist_20pt_checked.svg',
          title: '排版历史',
          name: 'historylist',
          path: '/paibanHistory',
        },
        {
          icon: 'assets/icon/equipment_20pt.svg',
          icon2: 'assets/icon/equipment_20pt_checked.svg',
          title: '设备对接',
          name: 'equipment',
          path: '/equipment',
        },
      ],
      activeNav: 'equipment',
      // 显示排版生产额外的导航栏
      showExtraNav: false,
      // 记录额外的导航栏
      extraNav: [
        {
          id: 1,
          title: '开料清单',
          name: 'material',
          path: '/materialList',
        },
        {
          id: 2,
          title: '基材管理',
          name: 'baseMaterial',
          path: '',
        },
        {
          id: 3,
          title: '余料仓',
          name: 'supplus',
          path: '/supplusStorage',
        },
      ],
    }
  },
  computed: {
    ...mapState(['userInfo', 'hasLblAuth', 'showPerson']),
    extraActiveNav() {
      if (this.$route.meta.child) {
        let name = this.$route.meta.child
        return name
      } else {
        return ''
      }
    },
  },
  watch: {
    $route() {
      this.changeActiveNav()
    },
  },
  methods: {
    // 切换导航栏
    changeNav(item) {
      if (!this.hasLblAuth) {
        this.$message({
          message: '当前子账号只能查看排版!',
          type: 'info',
        })
        return
      }
      if (item.path != '') {
        if (item.path == '/equipment' && this.showTiangong) {
          this.$router.push('/equipment?from=tiangong')
          return
        }
        if (item.path == '/drawParts') {
          if (this.$store.state.paibanData.length > 0) {
            this.$message({
              message: '暂未进行过排版, 请先进行排版!',
              type: 'info',
            })
          }
          return
        }
        this.$router.push(item.path)
        window.localStorage.setItem('blnavstatus', item.name)
      } else {
        this.$message({
          type: 'info',
          message: '敬请期待',
          duration: 1000,
          offset: 60,
        })
      }
    },
    // 跳转到拍板生产中的选项
    gotoExtraNav(item) {
      if (item.path == '') {
        this.$message({
          type: 'info',
          message: '敬请期待',
          duration: 1000,
          offset: 60,
        })
        return
      }
      this.$router.push(item.path)
    },
    // 前往官网
    gotoOfficialWebsite() {
      if (this.showTiangong) {
        window.open('https://www.guigui.com.cn/tiangong_official/tiangong.html')
      } else {
        window.open('http://www.bluen.cn')
      }
    },
    //跳转到帮助文档
    gotoHelpingDocument() {
      _hmt.push(['_trackEvent', '帮助', 'click'])
      window.open('https://www.yuque.com/linyaoyu/vr6nhn/beohd8')
    },
    // 更改顶部导航选中
    changeActiveNav() {
      if (this.$route.meta.nav) {
        let navName = this.$route.meta.nav
        switch (navName) {
          case 'equip':
            this.activeNav = 'equipment'
            break
          case 'material':
            this.activeNav = 'detailedlist'
            break
          case 'draw':
            this.activeNav = 'typesetting'
            break
          case 'history':
            this.activeNav = 'historylist'
            break
        }
      }
    },
    // 显示个人中心悬浮框
    showPersonInfo() {
      this.$store.commit('setShowPerson', !this.showPerson)
    },
    // hidePerson(){
    //   this.showPerson = false

    // }
  },
  mounted() {
    this.changeActiveNav()
  },
}
</script>

<style scoped lang="less">
.top-nav {
  width: 100%;
  height: 60px;
  background: #fff;
  position: sticky;
  top: 0;
  // z-index: 9999999;
  padding: 0 24px;
  z-index: 2002;
  span {
    cursor: default;
  }
  .top-nav-content {
    // width: 1392px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .content-left {
      height: 100%;
      display: flex;
      align-items: center;
      .logo-box {
        span {
          display: block;
          color: #2e2e2e;
        }
        span:first-child {
          font-size: 20px;
          color: #2e2e2e;
        }
        span:last-child {
          font-size: 12px;
          color: #2e2e2e;
        }
      }
      .nav-list {
        margin-left: 100px;
        display: flex;
        .nav-list-item {
          display: flex;
          align-items: center;
          height: 60px;
          margin-right: 32px;
          cursor: pointer;
          border-bottom: 2px solid transparent;
          box-sizing: border-box;
          position: relative;
          .icon-box {
            width: 21px;
            height: 22px;
            position: relative;
            margin-right: 5px;
            img {
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
            }
          }
          span {
            cursor: pointer;
            color: #2e2e2e;
          }
        }
        .nav-list-item.nav-active-item {
          // border-bottom: 2px solid #18A8C7;
          .nav-item-title {
            color: #18a8c7;
          }
        }
      }
    }
    .content-right {
      font-size: 14px;
      font-weight: 400;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #333333;
      height: 100%;
      display: flex;
      align-items: center;
      .official-website {
        margin-right: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          cursor: pointer;
          line-height: 20px;
        }
      }
      .helpUsing {
        width: 28px;
        height: 20px;
        margin-right: 24px;
        span {
          cursor: pointer;
          line-height: 20px;
        }
      }
      .user-box {
        cursor: pointer;
        display: flex;
        align-items: center;
        .user-head {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          overflow: hidden;
          background: #979797;
          margin-right: 8px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .user_name {
          color: #5a5a5a;
          font-size: 14px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100px;
          span {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            width: 84px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>

<style lang="less">
.material-nav-popover.ant-popover {
  z-index: 2006;
  .ant-popover-content {
    .ant-popover-inner-content {
      padding: 0;
    }
    .material-nav {
      width: 90px;
      height: 102px;
      .extra-nav {
        height: 34px;
        display: block;
        width: 100%;
        line-height: 34px;
        color: rgba(0, 0, 0, 0.65);
        font-family: PingFangSC-Regular, PingFang SC;
        padding-left: 12px;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
          background: rgba(0, 0, 0, 0.06);
        }
      }
      .active-extra {
        background: rgba(0, 0, 0, 0.06);
      }
    }
  }
}
</style>
