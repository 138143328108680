import { Nav } from '@/util/anchorNavData'

export const preLayoutSettingNavs: Array<Nav> = [
  { href: 'knife-setting', title: 'preLayoutSetting.knifeDiameter' },
  {
    href: 'hole-slot-focus',
    title: 'cuttingDock.cuttingParams.holeSlotMainSide',
  },
  {
    href: 'cut-hole-slot-way',
    title: 'cuttingDock.cuttingParams.cutHoleWay',
  },
  {
    href: 'gloss-plank-setting',
    title: 'cuttingDock.cuttingParams.highGlossSetting',
  },
  {
    href: 'board-slot-place',
    title: 'cuttingDock.cuttingParams.sideSlotPut',
  },

  {
    href: 'table-board',
    title: 'cuttingDock.labelingSettings.countertopSetting.title',
  },
  {
    href: 'surplus-setting',
    title: 'cuttingDock.cuttingParams.surplusSetting',
  },
  {
    href: 'long-door-plank',
    title: 'cuttingDock.cuttingParams.longCut',
  },
  {
    href: 'decimals',
    title: 'cuttingDock.cuttingParams.numberFixed',
  },
  {
    href: 'interval-setting',
    title: 'cuttingDock.cuttingParams.layoutGap',
  },
  {
    href: 'plank-num-sole',
    title: 'cuttingDock.NCExportSettings.oneCodeSetting',
  },
  {
    href: 'newCutSequence',
    title: 'cuttingDock.cuttingParams.movePlankSetting',
  },
  {
    href: 'aio-setting',
    title: 'common.integration',
  },
  {
    href: 'saw-setting',
    title: 'cuttingDock.sawEngraving.preLayNav',
  },
]
