<template>
  <div class="customFile">
    <breadcrumb
      :title="$route.query.line || '默认生产线'"
      childTitle="文件自定义"
      @goBackPage="goBackPage"
    >
      <template>
        <div>
          <a-button @click.native="saveDockFiveSix" id="equipment_setting_save_btn"
            ><a-icon type="save" />保存</a-button
          >
        </div>
      </template>
    </breadcrumb>
    <div class="custom_file_content">
      <div class="equipment_choose">
        <span class="head-title">设备类型</span>
        <div>
          <span>设备类型：</span>
          <a-radio-group name="radioGroup" v-model="formData.equipment">
            <a-radio value="五面钻" ><span id="fiveSix_setting_five_radio_label">五面钻</span></a-radio>
            <a-radio value="六面钻" id="fiveSix_setting_six_radio"><span id="fiveSix_setting_six_radio_label">六面钻</span></a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="file_set">
        <span class="head-title">文件设置：</span>
        <div>
          <span>文件后缀设定：</span>
          <a-input v-model="formData.fileSuffix" id="fiveSix_setting_fileSuffix_input" />
        </div>
        <div>
          <span>文件编码格式：</span>
          <a-radio-group name="encodGroup" v-model="formData.fileEncoding">
            <a-radio value="utf-8" id="fiveSix_setting_fileEndCode_utf8_radio"><span id="fiveSix_setting_fileEndCode_utf8_radio_label">UTF-8格式</span></a-radio>
            <a-radio value="ansi" id="fiveSix_setting_fileEndCode_ansi_radio"><span id="fiveSix_setting_fileEndCode_ansi_radio_label">ANSI格式</span></a-radio>
          </a-radio-group>
        </div>
        <div>
          <span>文件生成方式：</span>
          <a-radio-group name="fileGroup" v-model="formData.fileType">
            <a-radio value="merge" id="fiveSix_setting_fileType_merge_radio"><span id="fiveSix_setting_fileType_merge_radio_label">合并为一个文件</span></a-radio>
            <a-radio value="split" id="fiveSix_setting_fileType_split_radio"><span id="fiveSix_setting_fileType_split_radio_label">拆分为多个文件</span></a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="params_set">
        <span class="head-title">参数设置：</span>
        <div class="params_set_wap">
          <div class="edit_code" id="aa" style="position: relative">
            <div
              class="code_item"
              v-for="(item, index) in formData.codeList"
              :key="index"
            >
              <div class="code_type">
                <span class="num">{{ index + 1 }}、</span>
                <a-textarea
                  placeholder=""
                  @input="changeCodeText"
                  :rows="4"
                  v-model="item.code"
                  @drop="dropDown($event, index)"
                  :id="`fiveSix_setting_codeList_code_${index}_textarea`"
                />
              </div>
              <div class="code_text">
                <a-cascader
                  ref="cascader"
                  :options="codeTypeOption"
                  placeholder=""
                  :value="item.type"
                  expandTrigger="hover"
                  :getPopupContainer="
                    (triggerNode) =>
                      triggerNode.parentNode.parentNode.parentNode
                  "
                  @change="onChange($event, index)"
                  :id="`fiveSix_setting_codeList_code_${index}_cascader`"
                />
                <div class="btn">
                  <a-button @click="moveUp(index)" v-if="index != 0"
                    ><i class="iconfont icon-move_up"></i>上移</a-button
                  >
                  <a-button
                    @click="moveDown(index)"
                    v-if="index != formData.codeList.length - 1"
                    ><i class="iconfont icon-move_down"></i>下移</a-button
                  >
                  <a-button
                    @click="delCodeCol(index)"
                    v-if="formData.codeList.length != 1"
                    ><i class="iconfont el-icon-delete"></i>删除数据项</a-button
                  >
                  <a-button @click="addCodeCol(index)"
                    ><i class="iconfont icon-add"></i>添加数据项</a-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="drag_tag">
            <div class="tag_head">
              <span class="tag_head_text">数据标签池</span>
              <span class="tag_head_dec">可拖拽标签池内标签</span>
            </div>
            <div class="tag_item" v-for="(item, index) in tagList" :key="index">
              <span>{{ item.title }}：</span>
              <div>
                <div class="tag_item_list">
                  <span
                    v-for="items in item.arr"
                    :key="items.id"
                    draggable="true"
                    @dragstart.stop="startDrag($event)"
                    >{{ items.label_name_show }}</span
                  >
                </div>
                <div class="start_num" v-if="item.symbol == 'hole_info'">
                  <span>生序起始值：</span>
                  <div>
                    <a-input-number
                      :min="0"
                      v-model="formData.hole_slot_start_num"
                    />
                  </div>
                </div>
                <div class="start_num" v-if="item.symbol == 'outline_info'">
                  <span>生序起始值：</span>
                  <div>
                    <a-input-number :min="0" v-model="formData.start_num" />
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="start_num">
                            <span>生序起始数值：</span>
                            <div>
                                <a-input v-model="formData.start_num" />
                            </div>
                        </div> -->
            <div class="texture_set">
              <span>纹理设置：</span>
              <div>
                <div>
                  <span>横纹=</span
                  ><a-input v-model="formData.textureSettings.reverse" />
                </div>
                <div>
                  <span>竖纹=</span
                  ><a-input v-model="formData.textureSettings.normal" />
                </div>
                <div>
                  <span>无纹理=</span
                  ><a-input v-model="formData.textureSettings.notcare" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="com_code">
        <span class="head-title">生成数据样例：</span>
        <div>
          <textarea v-model="comCode" disabled></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from '@/components/topBreadCrumb.vue'
export default {
  components: {
    breadcrumb,
  },
  data() {
    return {
      formData: {
        equipment: '六面钻',
        fileSuffix: '',
        fileEncoding: 'ansi',
        fileType: 'merge',
        start_num: 0,
        hole_slot_start_num: 1,
        textureSettings: {
          reverse: '',
          normal: '',
          notcare: '',
        },
        codeList: [
          {
            type: [],
            code: '',
          },
        ],
      },
      allTagInfo: [],
      tagList: [],
      codeTypeOption: [
        {
          value: 'program_begin',
          label: '程序开始',
          disabled: false,
        },
        {
          value: 'plank_info',
          label: '板件信息',
          disabled: false,
        },
        {
          value: 'plank_end',
          label: '板件结束',
          disabled: false,
        },
        {
          value: 'external_shape_info',
          label: '外部形状描述',
          children: [
            {
              value: 'type_info',
              label: '类型标识',
              disabled: false,
            },
            {
              value: 'external_outline_start',
              label: '轮廓起点',
              disabled: false,
            },
            {
              value: 'external_normal_point',
              label: '常规点',
              disabled: false,
            },
            {
              value: 'external_curve_start',
              label: '异形开始点',
              disabled: false,
            },
            {
              value: 'external_curve_end',
              label: '异形结束点',
              disabled: false,
            },
            {
              value: 'external_outline_end',
              label: '轮廓终点',
              disabled: false,
            },
          ],
        },
        {
          value: 'internal_shape_info',
          label: '内部形状描述',
          children: [
            {
              value: 'type_info',
              label: '类型标识',
              disabled: false,
            },
            {
              value: 'internal_outline_start',
              label: '轮廓起点',
              disabled: false,
            },
            {
              value: 'internal_normal_point',
              label: '常规点',
              disabled: false,
            },
            {
              value: 'internal_curve_start',
              label: '异形开始点',
              disabled: false,
            },
            {
              value: 'internal_curve_end',
              label: '异形结束点',
              disabled: false,
            },
            {
              value: 'internal_outline_end',
              label: '轮廓终点',
              disabled: false,
            },
          ],
        },
        {
          value: 'holes_info',
          label: '孔描述',
          children: [
            {
              value: 'type_info',
              label: '类型标识',
              disabled: false,
            },
            {
              value: 'front_holes',
              label: '正面孔',
              disabled: false,
            },
            {
              value: 'back_holes',
              label: '反面孔',
              disabled: false,
            },
            {
              value: 'left_holes',
              label: '左面孔',
              disabled: false,
            },
            {
              value: 'right_holes',
              label: '右面孔',
              disabled: false,
            },
            {
              value: 'above_holes',
              label: '上面孔',
              disabled: false,
            },
            {
              value: 'below_holes',
              label: '下面孔',
              disabled: false,
            },
          ],
        },
        {
          value: 'slots_info',
          label: '槽描述',
          children: [
            {
              value: 'type_info',
              label: '类型标识',
              disabled: false,
            },
            {
              value: 'front_slots',
              label: '正面槽',
              disabled: false,
            },
            {
              value: 'back_slots',
              label: '反面槽',
              disabled: false,
            },
            {
              value: 'left_slots',
              label: '左面槽',
              disabled: false,
            },
            {
              value: 'right_slots',
              label: '右面槽',
              disabled: false,
            },
            {
              value: 'above_slots',
              label: '上面槽',
              disabled: false,
            },
            {
              value: 'below_slots',
              label: '下面槽',
              disabled: false,
            },
          ],
        },
        {
          value: 'program_end',
          label: '程序结束',
          disabled: false,
        },
      ],
      comCode: '',
      codeTextRe: {
        '{项目地址}': '北京市东城区东长安街天安门广场',
        '{订单编号}': '222',
        '{订单创建时间}': '2020-10-11 19:28:52',
        '{订单创建日期}': '2020-10-11',
        '{客户名称}': '张某某',
        '{设计师}': '李设计',
        '{房间}': '主卧',
        '{柜体}': '衣柜',
        '{板件名称}': '横隔板01',
        '{板件条码}': '0000123456789',
        '{板件编号}': '11#',
        '{板件纹理}': '横纹',
        '{板件成品长度}': '2440',
        '{板件成品宽度}': '1220',
        '{板件打包码（转义）}': 'http://eggi.cn/%C3%B7a000001',
        '{大板长度}': '2750',
        '{大板宽度}': '1220',
        '{板件厚度}': '18',
        '{板件材质}': '多层实木',
        '{板件颜色}': 'T01',
        '{前封边}': '1.0',
        '{后封边}': '1.0',
        '{左封边}': '1.0',
        '{右封边}': '1.0',
        '{孔起点X坐标}': '0',
        '{孔起点Y坐标}': '0',
        '{孔起点Z坐标}': '0',
        '{孔终点X坐标}': '100',
        '{孔终点Y坐标}': '100',
        '{孔终点Z坐标}': '9',
        '{孔直径}': '15',
        '{孔深度}': '10',
        '{槽起点X坐标}': '0',
        '{槽起点Y坐标}': '0',
        '{槽起点Z坐标}': '0',
        '{槽终点X坐标}': '50',
        '{槽终点Y坐标}': '50',
        '{槽终点Z坐标}': '9',
        '{槽宽度}': '10',
        '{槽深度}': '10',
        '{轮廓起点X坐标}': '0',
        '{轮廓起点Y坐标}': '0',
        '{轮廓起点Z坐标}': '0',
        '{常规点X坐标}': '100',
        '{常规点Y坐标}': '100',
        '{常规点Z坐标}': '0',
        '{异形起点X坐标}': '200',
        '{异形起点Y坐标}': '200',
        '{异形起点Z坐标}': '0',
        '{异形终点X坐标}': '200',
        '{异形终点Y坐标}': '200',
        '{异形终点Z坐标}': '0',
        '{异形圆弧半径}': '100',
        '{轮廓终点X坐标}': '300',
        '{轮廓终点Y坐标}': '300',
        '{轮廓终点Z坐标}': '0',
        '{生成轮廓序号}': '0',
        '{生成孔槽序号}': '1',
      },
      recordCodeTypes: [],
      dragValue: '',
    }
  },
  mounted() {
    this.setting_id = this.$route.query.setting_id
    this.getTagList()
    this.getDockFiveSixSet()
  },
  methods: {
    saveDockFiveSix() {
      let data = this.formData
      let form = {
        fileEncoding: data.fileEncoding,
        fileSuffix: data.fileSuffix,
        textureSettings: data.textureSettings,
        start_num: Number(data.start_num),
        hole_slot_start_num: Number(data.hole_slot_start_num),
      }
      let codeList = JSON.parse(JSON.stringify(data.codeList))
      codeList.forEach((item) => {
        let code = {
          headerIds: {},
          templateString: item.code,
        }
        this.allTagInfo.forEach((items) => {
          if (item.code.indexOf(`{${items.label_name_show}}`) != -1) {
            code.headerIds[items.label_name_show] = items.id
          }
        })
        item.code = code
      })
      form.codeList = codeList
      if (data.equipment == '六面钻') {
        form.dock6FDrill = true
        form.dock5FDrill = false
      } else {
        form.dock6FDrill = false
        form.dock5FDrill = true
      }
      if (data.fileType == 'merge') {
        form.mergeFile = true
        form.splitFile = false
      } else {
        form.mergeFile = false
        form.splitFile = true
      }
      this.$token(
        '/save_dock56F_customize_setting',
        {
          setting_id: this.setting_id,
          data: form,
        },
        (res) => {
          if (res.status == 1) {
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 1000,
            })
          }
        }
      )
    },
    getDockFiveSixSet() {
      this.$token(
        '/load_dock56F_customize_setting',
        {
          setting_id: this.setting_id,
        },
        (res) => {
          let data = res.data
          let form = this.formData
          form.equipment = data.dock6FDrill ? '六面钻' : '五面钻'
          form.fileEncoding = data.fileEncoding
          form.fileSuffix = data.fileSuffix
          form.fileType = data.splitFile ? 'split' : 'merge'
          form.start_num = data.start_num
          form.hole_slot_start_num = data.hole_slot_start_num
          form.codeList = data.codeList.map((item) => {
            // 初始化记录已选择键值
            this.recordCodeTypes.push(item.type.join('$'))
            return {
              code: item.code.templateString,
              type: item.type,
            }
          })
          form.textureSettings = data.textureSettings
          this.countComCode()
        }
      )
    },
    changeCodeText() {
      this.countComCode()
    },
    moveUp(index) {
      let data = this.formData.codeList
      let up = JSON.parse(JSON.stringify(data[index - 1]))
      let down = JSON.parse(JSON.stringify(data[index]))
      data[index] = up
      data[index - 1] = down
      this.formData.codeList = data
      this.$forceUpdate()
      this.countComCode()
      // this.$set(this.formData,'codeList',data)
    },
    moveDown(index) {
      let data = this.formData.codeList
      let up = JSON.parse(JSON.stringify(data[index]))
      let down = JSON.parse(JSON.stringify(data[index + 1]))
      data[index + 1] = up
      data[index] = down
      this.formData.codeList = data
      this.$forceUpdate()
      this.countComCode()
    },
    addCodeCol(index) {
      let newCode = {
        type: [],
        code: '',
      }
      this.formData.codeList.splice(index + 1, 0, newCode)
    },
    delCodeCol(index) {
      this.$confirm('确定要删除该数据项吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let value = this.formData.codeList[index].type.join('$')
          this.formData.codeList.splice(index, 1)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          // 删除记录的键值
          let delIndex = this.recordCodeTypes.findIndex((v) => v == value)
          this.recordCodeTypes.splice(delIndex, 1)
          this.countComCode()
        })
        .catch(() => {})
    },
    onChange(value, index) {
      // 拼接父级和自己value，保证唯一值
      let recordValue = value.join('$')
      if (!this.recordCodeTypes.find((v) => v == recordValue)) {
        // 数组保存键值
        this.recordCodeTypes.push(recordValue)
      }
      // 切换新选项时，保存的键值数组中删除老的键值，使选项可以再被选择
      let oldV = this.formData.codeList[index].type
      let oldVIndex = this.recordCodeTypes.findIndex((v) => v == oldV.join('$'))
      if (oldVIndex != -1) {
        this.recordCodeTypes.splice(oldVIndex, 1)
      }
      this.formData.codeList[index].type = value
      this.$forceUpdate()
    },
    countComCode() {
      let codes = ''
      this.formData.codeList.forEach((item) => {
        codes += item.code + '\r\n'
      })
      let codeTextRe = this.codeTextRe
      for (let i in codeTextRe) {
        let reg = '/' + i + '/g'
        codes = codes.replace(eval(reg), codeTextRe[i])
      }
      this.comCode = codes
    },
    goBackPage() {
      this.$router.push({
        path: '/dockFiveSix',
        query: {
          setting_id: this.$route.query.setting_id,
          line: this.$route.query.line,
        },
      })
    },
    dropDown(e, index) {
      setTimeout(() => {
        // 使用定时器获取最新输入框值
        let value = e.target.value
        // 在末尾加上一个空 重新赋值，输入框的值才会刷新
        this.formData.codeList[index].code = value + ' '
        setTimeout(() => {
          // 使用定时器删除最后一个空格
          this.formData.codeList[index].code = this.formData.codeList[
            index
          ].code.substring(0, this.formData.codeList[index].code.length - 1)
        })
      })
    },
    startDrag(event) {
      event.dataTransfer.setData('Text', `{${event.target.innerHTML}}`)
    },
    textareaChange() {
      let el = document.getElementById('codeArea')
      this.makeExpandingArea(el)
    },
    makeExpandingArea(el) {
      var timer = null
      //由于ie8有溢出堆栈问题，故调整了这里
      var setStyle = function (el, auto) {
        if (auto) el.style.height = 'auto'
        el.style.height = el.scrollHeight + 'px'
      }
      var delayedResize = function (el) {
        if (timer) {
          clearTimeout(timer)
          timer = null
        }
        timer = setTimeout(function () {
          setStyle(el)
        }, 200)
      }
      if (el.addEventListener) {
        el.addEventListener(
          'input',
          function () {
            setStyle(el, 1)
          },
          false
        )
        setStyle(el)
      } else if (el.attachEvent) {
        el.attachEvent('onpropertychange', function () {
          setStyle(el)
        })
        setStyle(el)
      }
      if (window.VBArray && window.addEventListener) {
        //IE9
        el.attachEvent('onkeydown', function () {
          var key = window.event.keyCode
          if (key == 8 || key == 46) delayedResize(el)
        })
        el.attachEvent('oncut', function () {
          delayedResize(el)
        }) //处理粘贴
      }
    },
    getTagList() {
      this.$get(
        '/get_electronic_saw_label',
        {
          label_class: 'fiveSixFDrillCustomize',
        },
        (res) => {
          let data = res.data
          let tagList = []
          data['hole_info'] = data['hole_info'].concat(data['slot_info'])
          for (let i in data) {
            this.allTagInfo = this.allTagInfo.concat(data[i])
            let obj = {
              arr: [],
              title: '',
              symbol: i,
            }
            switch (i) {
              case 'edge_info':
                obj.title = '封边信息'
                obj.arr = data[i]
                tagList.push(obj)
                break
              case 'hole_info':
                obj.title = '孔槽信息'
                obj.arr = data[i]
                tagList.push(obj)
                break
              case 'other_info':
                obj.title = '其他'
                obj.arr = data[i]
                tagList.push(obj)
                break
              case 'outline_info':
                obj.title = '轮廓信息'
                obj.arr = data[i]
                tagList.push(obj)
                break
              case 'plank_info':
                obj.title = '板件信息'
                obj.arr = data[i]
                tagList.push(obj)
                break
              case 'room_info':
                obj.title = '订单及房间信息'
                obj.arr = data[i]
                tagList.push(obj)
                break
              default:
                break
            }
          }
          this.tagList = tagList
        }
      )
    },
  },
  watch: {
    recordCodeTypes: {
      deep: true,
      handler: function (newV, oldV) {
        this.codeTypeOption.forEach((item) => {
          if (item.children) {
            item.children.forEach((e) => {
              if (
                this.recordCodeTypes.indexOf(item.value + '$' + e.value) != -1
              ) {
                e.disabled = true
              } else {
                e.disabled = false
              }
            })
          } else {
            if (this.recordCodeTypes.indexOf(item.value) != -1) {
              item.disabled = true
            } else {
              item.disabled = false
            }
          }
        })
      },
    },
  },
  computed: {
    // comCode(){
    //     let codes = '';
    //     this.formData.codeList.forEach(item => {
    //         codes += item.code + '\r\n'
    //     })
    //     return codes
    // }
  },
}
</script>

<style lang="less">
.customFile {
  .custom_file_content {
    width: 1392px;
    min-width: 1392px;
    margin: 0 auto;
    padding-bottom: 30px;
    > div {
      box-sizing: border-box;
      background-color: #fff;
    }
    .equipment_choose {
      > div {
        padding: 10px 24px;
      }
    }
    .params_set {
      .params_set_wap {
        display: flex;
        justify-content: space-between;
        padding: 10px 24px;
        .edit_code {
          flex: 1;
          max-height: 800px;
          padding-right: 40px;
          overflow-y: auto;
          .code_item {
            margin-bottom: 10px;
            .code_type {
              display: flex;
              .num {
                display: inline-block;
                width: 20px;
              }
            }
            .code_text {
              display: flex;
              justify-content: space-between;
              padding-top: 10px;
              padding-left: 20px;
              .btn {
              }
            }
            .code_add_del {
              padding-top: 20px;
              padding-left: 24px;
            }
          }
        }
        .drag_tag {
          flex: 1;
          height: 800px;
          overflow-y: auto;
          .tag_head {
            position: sticky;
            top: 0;
            padding: 10px 24px;
            background-color: #f0f0f0;
            .tag_head_text {
              color: #000;
              font-weight: bold;
              font-size: 18px;
            }
          }
          .tag_item {
            display: flex;
            padding: 10px 10px 8px 10px;
            border: 1px solid #f0f0f0;
            border-top: none;
            > span {
              display: inline-block;
              width: 120px;
              font-weight: bold;
              text-align: right;
            }
            > div {
              width: calc(100% - 120px);
              .tag_item_list {
                display: flex;
                flex-wrap: wrap;
                span {
                  display: inline-block;
                  min-width: 100px;
                  margin: 0 10px 8px 0;
                  color: #333333;
                  line-height: 32px;
                  text-align: center;
                  background-color: #f6f6f6;
                }
              }
            }
          }
          .texture_set {
            display: flex;
            align-items: center;
            padding: 10px 10px 8px 10px;
            border: 1px solid #f0f0f0;
            border-top: none;
            > span {
              display: inline-block;
              width: 120px;
              font-weight: bold;
              text-align: right;
            }
            > div {
              display: flex;
              width: calc(100% - 120px);
              > div {
                margin-right: 10px;
                > span {
                  display: inline-block;
                }
                .ant-input {
                  width: 93px;
                }
              }
            }
          }
          .start_num {
            display: flex;
            align-items: center;
            .ant-input {
              width: 120px;
            }
          }
        }
      }
    }
    .com_code {
      > div {
        padding: 10px 24px;
      }
      textarea {
        width: 100%;
        height: 300px;
        outline: none;
      }
    }
    .file_set {
      > div {
        padding: 10px 24px;
        .ant-input {
          width: 120px;
        }
      }
    }
  }
}
</style>
