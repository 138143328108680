<template>
  <div class="tagTemp_wapper" style="margin-bottom: 20px">
    <canvas
      class="exportCanvas"
      :id="plankIndex + 'printTag' + index"
      :labelName="currentPlank ? currentPlank.plankNum : 1"
    ></canvas>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode'
import QRCode from 'qrcode'

import { getDeviceXDPI, mmToPx } from '../../util/exportFuncs'

// matCode
const textOption = [
  {
    att: '',
    type: 'FixedText',
    name: '客户：',
    x: 5,
    y: 5,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: 'customer_name',
    type: 'DataSource',
    name: '',
    x: 30,
    y: 5,
    fontSize: 10,
    fontWeight: '',
  },

  {
    att: '',
    type: 'FixedText',
    name: '订单：',
    x: 5,
    y: 20,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: 'orderNo',
    type: 'DataSource',
    name: '',
    x: 30,
    y: 20,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: '',
    type: 'FixedText',
    name: '房间：',
    x: 5,
    y: 35,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: 'roomName',
    type: 'DataSource',
    name: '',
    x: 30,
    y: 35,
    fontSize: 10,
    fontWeight: '',
  },

  {
    att: '',
    type: 'FixedText',
    name: '柜名：',
    x: 5,
    y: 50,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: 'loc',
    type: 'DataSource',
    name: '',
    x: 30,
    y: 50,
    fontSize: 10,
    fontWeight: '',
  },

  {
    att: '',
    type: 'FixedText',
    name: '成品尺寸：',
    x: 5,
    y: 65,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: 'oSize',
    type: 'DataSource',
    name: '',
    x: 50,
    y: 65,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: '',
    type: 'FixedText',
    name: '材质：',
    x: 5,
    y: 80,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: 'matCode_texture',
    type: 'DataSource',
    name: '',
    x: 30,
    y: 80,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: '',
    type: 'FixedText',
    name: '查看安装图',
    x: 5,
    y: 140,
    fontSize: 8,
    fontWeight: '',
  },
]
export default {
  data() {
    return {
      scale: 0.1,
      info: {},
      mycanvas: null,
      currentPlank: null,
      arrPI: [],
      plankScaleXY: 0,
    }
  },
  props: {
    tagData: {
      type: Object,
      default: {},
    },
    index: {
      // 哪一种小板
      type: Number,
      default: 0,
    },
    canvasColor: {
      type: String,
      default: '#fff',
    },
    plankIndex: {
      // 第几张大板
      type: Number,
      default: 1,
    },
    canvasScaleXY: {
      type: Number,
      default: 1,
    },
    // 小板总数
    allPage: {
      type: Number,
      default: 1,
    },
    nowTagTem: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.arrPI = getDeviceXDPI()
    this.info = this.tagData
    this.currentPlank = this.info.parts[this.index]
    let currentPlank = this.currentPlank
    // 成品尺寸，先取出字段组合
    this.currentPlank.oSize =
      currentPlank.oRect.height +
      '*' +
      currentPlank.oRect.width +
      '*' +
      currentPlank.thick
    // 成品尺寸 + 材质
    this.currentPlank.matCode_texture =
      currentPlank.matCode + '(' + currentPlank.texture + ')'
    if (this.nowTagTem) {
      this.drawTem()
    } else {
      this.plankScaleXY = 105 / this.info.plankHeight
      this.draw()
    }
  },
  computed: {},
  methods: {
    scaleXY(value) {
      return this.scale * value
    },
    draw() {
      let info = this.currentPlank
      this.mycanvas = new fabric.StaticCanvas(
        `${this.plankIndex}printTag${this.index}`,
        {
          width: mmToPx(60, this.arrPI) * this.canvasScaleXY,
          height: mmToPx(40, this.arrPI) * this.canvasScaleXY,
          backgroundColor: this.canvasColor,
        }
      )
      // this.mycanvas.setZoom(this.canvasScaleXY)
      textOption.forEach((item, index) => {
        let addTem
        if (item.type == 'FixedText') {
          addTem = new fabric.Text(`${item.name}`, {
            top: item.y * this.canvasScaleXY,
            left: item.x * this.canvasScaleXY,
            fontWeight: item.fontWeight,
            fontSize: item.fontSize * this.canvasScaleXY,
            selectable: false,
          })
        } else if (item.type == 'DataSource') {
          addTem = new fabric.Text(`${info[item.att] ? info[item.att] : ''}`, {
            top: item.y * this.canvasScaleXY,
            left: item.x * this.canvasScaleXY,
            fontWeight: item.fontWeight,
            fontSize: item.fontSize * this.canvasScaleXY,
            selectable: false,
          })
        }

        this.mycanvas.add(addTem)
      })
      QRCode.toDataURL(`http://eggi.cn/?ggid=${this.currentPlank.ggid}`, {
        height: 60 * this.canvasScaleXY,
        width: 60 * this.canvasScaleXY,
        margin: 0,
        quality: 1,
        format: 'jpeg',
      }).then((url) => {
        fabric.Image.fromURL(url, (img) => {
          img.set({
            top: 95 * this.canvasScaleXY,
            left: 5 * this.canvasScaleXY,
            width: 60 * this.canvasScaleXY,
            height: 60 * this.canvasScaleXY,
            scaleX: 0.7,
            scaleY: 0.7,
            selectable: false,
          })
          this.mycanvas.add(img)
          this.mycanvas.renderAll()
        })
      })
      let oneCode = this.textToBase64Barcode(this.currentPlank.plankNum)
      fabric.Image.fromURL(oneCode, (img) => {
        img.set({
          top: 95 * this.canvasScaleXY,
          left: 50 * this.canvasScaleXY,
          height: 60 * this.canvasScaleXY,
          // scaleY:0.8,
          // scaleX:0.8,
          selectable: false,
        })
        this.mycanvas.add(img)
        this.mycanvas.renderAll()
      })
      // 排版图
      let oTop = 10
      let oLeft = 160
      let rect = new fabric.Rect({
        width: this.info.plankWidth * this.plankScaleXY * this.canvasScaleXY,
        height: this.info.plankHeight * this.plankScaleXY * this.canvasScaleXY,
        top: oTop * this.canvasScaleXY,
        left: oLeft * this.canvasScaleXY,
        selectable: false,
        stroke: '#e4e4e4',
        strokeWidth: 1,
        fill: '#fff',
      })
      let parts = this.info.parts
      let partsArrs = []
      parts.forEach((item, index) => {
        if (item.path) {
          item.path.forEach((pathItem, i) => {
            if (i == 0) {
              let pointArr = pathItem.map((item) => {
                return {
                  x: (item.x * this.plankScaleXY + oLeft) * this.canvasScaleXY,
                  y: (item.y * this.plankScaleXY + oTop) * this.canvasScaleXY,
                }
              })
              let difShape = new fabric.Polygon(pointArr, {
                top:
                  (item.startY * this.plankScaleXY + oTop) * this.canvasScaleXY,
                left:
                  (item.startX * this.plankScaleXY + oLeft) *
                  this.canvasScaleXY,
                stroke: '#000',
                strokeWidth: 0.1,
                selectable: false,
                fill: '#fff',
              })
              if (index == this.index) {
                difShape.set({
                  fill: '#000',
                })
              }
              partsArrs.push(difShape)
              this.mycanvas.add(difShape)
            } else {
              let xs = []
              let ys = []
              let pointArr = pathItem.map((e) => {
                xs.push(e.x)
                ys.push(e.y)
                return {
                  x: (e.x * this.plankScaleXY + oLeft) * this.canvasScaleXY,
                  y: (e.y * this.plankScaleXY + oTop) * this.canvasScaleXY,
                }
              })
              let top =
                Math.min.apply(null, ys) * this.plankScaleXY +
                (parts[index].startY * this.plankScaleXY + oTop)
              let left =
                Math.min.apply(null, xs) * this.plankScaleXY +
                (parts[index].startX * this.plankScaleXY + oLeft)
              let difShape = new fabric.Polygon(pointArr, {
                top: top * this.canvasScaleXY,
                left: left * this.canvasScaleXY,
                stroke: '#000',
                strokeWidth: 0.1 * this.canvasScaleXY,
                selectable: false,
                fill: '#fff',
              })
              partsArrs.push(difShape)
              this.mycanvas.add(difShape)
            }
          })
        } else {
          let itemRect = new fabric.Rect({
            width: item.rect.width * this.plankScaleXY * this.canvasScaleXY,
            height: item.rect.height * this.plankScaleXY * this.canvasScaleXY,
            top: (item.startY * this.plankScaleXY + oTop) * this.canvasScaleXY,
            left:
              (item.startX * this.plankScaleXY + oLeft) * this.canvasScaleXY,
            stroke: '#000',
            strokeWidth: 0.5,
            fill: '#fff',
            selectable: false,
          })
          if (index == this.index) {
            itemRect.set({
              fill: '#000',
            })
          }
          partsArrs.push(itemRect)
          this.mycanvas.add(itemRect)
        }
      })
      this.mycanvas.add(rect)
      rect.sendToBack()
      // 左下右上
      const edgeInfo = this.currentPlank.edgeInfo
        .split(/\←|\↓|\→|\↑/)
        .filter((v) => v)
      let arr = [
        {
          value: edgeInfo[0],
          set: {
            top:
              (oTop + (this.info.plankHeight * this.plankScaleXY) / 2) *
              this.canvasScaleXY,
            left: (oLeft - 10) * this.canvasScaleXY,
          },
        },
        {
          value: edgeInfo[1],
          set: {
            top:
              (oTop + this.info.plankHeight * this.plankScaleXY) *
              this.canvasScaleXY,
            left:
              (oLeft + (this.info.plankWidth * this.plankScaleXY) / 2) *
              this.canvasScaleXY,
          },
        },
        {
          value: edgeInfo[2],
          set: {
            top:
              (oTop + (this.info.plankHeight * this.plankScaleXY) / 2) *
              this.canvasScaleXY,
            left:
              (oLeft + this.info.plankWidth * this.plankScaleXY - 2) *
              this.canvasScaleXY,
          },
        },
        {
          value: edgeInfo[3],
          set: {
            top: (oTop - 5) * this.canvasScaleXY,
            left:
              (oLeft + (this.info.plankWidth * this.plankScaleXY) / 2) *
              this.canvasScaleXY,
          },
        },
      ]
      let edgesArr = []
      arr.forEach((item) => {
        let edgeNum = new fabric.Text(item.value, {
          fontSize: 8 * this.canvasScaleXY,
          fontWeight: 'bold',
          selectable: false,
          ...item.set,
        })
        edgesArr.push(edgeNum)
        this.mycanvas.add(edgeNum)
      })
      // 大板序号、页码
      let PlankO = new fabric.Text(`第${this.plankIndex}张大板`, {
        fontSize: 10 * this.canvasScaleXY,
        top: 125 * this.canvasScaleXY,
        left: 170 * this.canvasScaleXY,
        selectable: false,
      })
      let tagPage = new fabric.Text(
        `第${this.currentPlank.partsIndex}/${this.allPage}页`,
        {
          fontSize: 8 * this.canvasScaleXY,
          top: 140 * this.canvasScaleXY,
          left: 175 * this.canvasScaleXY,
          selectable: false,
        }
      )
      this.mycanvas.add(PlankO)
      this.mycanvas.add(tagPage)
      this.mycanvas.renderAll()
      if (this.$listeners['callBack']) {
        this.$emit('callBack', this.currentPlank.plankNum)
      }
    },
    textToBase64Barcode(text) {
      let canvas = document.createElement('canvas')
      JsBarcode(canvas, text, {
        format: 'EAN13',
        width: 1.0 * this.canvasScaleXY,
        height: 40 * this.canvasScaleXY,
        margin: 0,
        fontSize: 14 * this.canvasScaleXY,
      })
      return canvas.toDataURL('image/png', {
        quality: 1,
        format: 'jpeg',
      })
    },
    countSourceData(type) {
      let req
      if (type) {
        switch (type) {
          case 'plank_index':
            req = `第${this.plankIndex}张大板`
            break
          case 'lable_index':
            req = `第${this.currentPlank.partsIndex}/${this.allPage}页`
            break
          case 'plankID':
            req = `${this.currentPlank[type]}#`
            break
          default:
            req = this.currentPlank[type]
            break
        }
      }
      return String(req)
    },
    drawTem() {
      let temData = this.nowTagTem
      let items = temData.tem_data
      let rectL = temData.rectInfo.left
      let rectT = temData.rectInfo.top
      this.mycanvas = new fabric.Canvas(
        `${this.plankIndex}printTag${this.index}`,
        {
          width: mmToPx(temData.tag_width, this.arrPI) * this.canvasScaleXY,
          height: mmToPx(temData.tag_height, this.arrPI) * this.canvasScaleXY,
          backgroundColor: this.canvasColor,
        }
      )
      items.forEach((item) => {
        let info = item.data
        switch (item.type) {
          case 'FixedText':
            let Text = new fabric.Text(info.text, {
              fontSize: info.fontSize * this.canvasScaleXY,
              selectable: false,
              fontWeight: info.fontWeight,
              left: (info.left - rectL) * this.canvasScaleXY,
              top: (info.top - rectT) * this.canvasScaleXY,
            })
            this.mycanvas.add(Text)
            break
          case 'Graphics':
            let points = info.points
            let newPoints = points.map((e) => {
              return {
                x: (e.x - rectL) * this.canvasScaleXY,
                y: (e.y - rectT) * this.canvasScaleXY,
              }
            })
            let Polygon = new fabric.Polygon(newPoints, {
              top: (info.top - rectT) * this.canvasScaleXY,
              left: (info.left - rectL) * this.canvasScaleXY,
              fill: info.fill,
              scaleX: info.scaleX,
              scaleY: info.scaleY,
              angle: info.angle,
              originX: 'center',
              originY: 'center',
              selectable: false,
            })
            this.mycanvas.add(Polygon)
            break
          case 'DataSource':
            let value = this.countSourceData(info.source_data)
            let Itext = new fabric.Text(value, {
              fontSize: info.fontSize * this.canvasScaleXY,
              selectable: false,
              fontWeight: info.fontWeight,
              left: (info.left - rectL) * this.canvasScaleXY,
              top: (info.top - rectT) * this.canvasScaleXY,
            })
            this.mycanvas.add(Itext)
            break
          case 'QRcode':
            let url
            if (info.source_data == 'ggid') {
              url = `http://eggi.cn/?ggid=${this.currentPlank.ggid}`
            } else {
              url = this.currentPlank.plankNum
            }
            QRCode.toDataURL(url, {
              height: mmToPx(info.mHeight, this.arrPI) * this.canvasScaleXY,
              width: mmToPx(info.mWidth, this.arrPI) * this.canvasScaleXY,
              margin: 0,
              quality: 1,
              format: 'jpeg',
            }).then((url) => {
              fabric.Image.fromURL(url, (img) => {
                img.set({
                  top: (info.top - rectT) * this.canvasScaleXY,
                  left: (info.left - rectL) * this.canvasScaleXY,
                  width: mmToPx(info.mWidth, this.arrPI) * this.canvasScaleXY,
                  height: mmToPx(info.mHeight, this.arrPI) * this.canvasScaleXY,

                  selectable: false,
                })
                this.mycanvas.add(img)
              })
            })
            break
          case 'Onecode':
            let Ocanvas = document.createElement('canvas')
            JsBarcode(Ocanvas, this.currentPlank[info.source_data], {
              format: info.code_type.toUpperCase(),
              width: 1.0 * this.canvasScaleXY,
              height: mmToPx(info.mHeight - 4, this.arrPI) * this.canvasScaleXY,
              margin: 0,
              fontWeight: info.fontWeight,
              fontSize: info.fontSize * this.canvasScaleXY,
            })
            let OneCodeUrl = Ocanvas.toDataURL('image/png', {
              quality: 1,
              format: 'jpeg',
            })
            fabric.Image.fromURL(OneCodeUrl, (img) => {
              img.set({
                width: mmToPx(info.mWidth, this.arrPI) * this.canvasScaleXY,
                top: (info.top - rectT) * this.canvasScaleXY,
                left: (info.left - rectL) * this.canvasScaleXY,
                height: mmToPx(info.mHeight, this.arrPI) * this.canvasScaleXY,
                scaleY: info.scaleY,
                scaleX: info.scaleX,
                selectable: false,
              })
              this.mycanvas.add(img)
              this.mycanvas.renderAll()
            })
            break
          case 'Typography':
            let gW = mmToPx(info.mWidth, this.arrPI)
            let gH = mmToPx(info.mHeight, this.arrPI)
            let PlankScale = (gH - 10) / this.currentPlank.plankHeight
            let plankW = this.currentPlank.plankWidth * PlankScale
            let plankH = this.currentPlank.plankHeight * PlankScale
            let rect = new fabric.Rect({
              selectable: false,
              stroke: '#e4e4e4',
              strokeWidth: 1,
              fill: '#fff',
              width: plankW * this.canvasScaleXY,
              height: plankH * this.canvasScaleXY,
              left: info.imgItem.left * this.canvasScaleXY,
              top: info.imgItem.top * this.canvasScaleXY,
            })
            let parts = this.info.parts
            let partsArrs = []
            parts.forEach((item, index) => {
              if (item.path) {
                item.path.forEach((pathItem, i) => {
                  if (i == 0) {
                    let pointArr = pathItem.map((item) => {
                      return {
                        x: (item.x * PlankScale + 10) * this.canvasScaleXY,
                        y: (item.y * PlankScale + 10) * this.canvasScaleXY,
                      }
                    })
                    let difShape = new fabric.Polygon(pointArr, {
                      top: (item.startY * PlankScale + 10) * this.canvasScaleXY,
                      left:
                        (item.startX * PlankScale + 10) * this.canvasScaleXY,
                      stroke: '#000',
                      strokeWidth: 0.1 * this.canvasScaleXY,
                      selectable: false,
                      fill: '#fff',
                    })
                    if (index == this.index) {
                      difShape.set({
                        fill: '#000',
                      })
                    }
                    partsArrs.push(difShape)
                    this.mycanvas.add(difShape)
                  } else {
                    let xs = []
                    let ys = []
                    let pointArr = pathItem.map((e) => {
                      xs.push(e.x)
                      ys.push(e.y)
                      return {
                        x: (e.x * PlankScale + 10) * this.canvasScaleXY,
                        y: (e.y * PlankScale + 10) * this.canvasScaleXY,
                      }
                    })
                    let top =
                      Math.min.apply(null, ys) * PlankScale +
                      (parts[index].startY * PlankScale + 10)
                    let left =
                      Math.min.apply(null, xs) * PlankScale +
                      (parts[index].startX * PlankScale + 10)
                    let difShape = new fabric.Polygon(pointArr, {
                      top: top * this.canvasScaleXY,
                      left: left * this.canvasScaleXY,
                      stroke: '#000',
                      strokeWidth: 0.1 * this.canvasScaleXY,
                      selectable: false,
                      fill: '#fff',
                    })
                    partsArrs.push(difShape)
                    this.mycanvas.add(difShape)
                  }
                })
              } else {
                let itemRect = new fabric.Rect({
                  width: item.rect.width * PlankScale * this.canvasScaleXY,
                  height: item.rect.height * PlankScale * this.canvasScaleXY,
                  top:
                    (item.startY * PlankScale + info.imgItem.top) *
                    this.canvasScaleXY,
                  left:
                    (item.startX * PlankScale + info.imgItem.left) *
                    this.canvasScaleXY,
                  stroke: '#000',
                  strokeWidth: 0.5,
                  fill: '#fff',
                  selectable: false,
                })
                if (index == this.index) {
                  itemRect.set({
                    fill: '#000',
                  })
                }
                partsArrs.push(itemRect)
                this.mycanvas.add(itemRect)
              }
            })
            let textItems = []
            info.textItems.forEach((e) => {
              let text = new fabric.Text(this.getEdgeValue(e.positionName), {
                positionName: e.positionName,
                fontSize: e.fontSize * this.canvasScaleXY,
                fontWeight: e.fontWeight,
              })
              let deviationW = (plankW + 5) * this.canvasScaleXY
              let deviationH = (plankH + 10) * this.canvasScaleXY
              switch (e.positionName) {
                case 'BE':
                  text.set({
                    top: deviationH,
                    left: deviationW / 2,
                  })
                  break
                case 'TE':
                  text.set({
                    top: e.top * this.canvasScaleXY,
                    left: deviationW / 2,
                  })
                  break

                case 'RE':
                  text.set({
                    top: e.top * this.canvasScaleXY,
                    left: deviationW + 5,
                  })
                  break
                case 'LE':
                  text.set({
                    top: e.top * this.canvasScaleXY,
                    left: e.left * this.canvasScaleXY,
                  })
                  break
              }
              textItems.push(text)
            })
            let Group = new fabric.Group(
              [rect].concat(partsArrs).concat(textItems),
              {
                width: gW * this.canvasScaleXY,
                height: gH * this.canvasScaleXY,
                left: (info.left - rectL) * this.canvasScaleXY,
                top: (info.top - rectT) * this.canvasScaleXY,
                selectable: false,
              }
            )
            this.mycanvas.add(Group)
            break
        }
      })
      this.mycanvas.renderAll()
      if (this.$listeners['callBack']) {
        this.$emit('callBack', this.currentPlank.plankNum)
      }
    },
    getEdgeValue(name) {
      const edgeInfo = this.currentPlank.edgeInfo
        .split(/\←|\↓|\→|\↑/)
        .filter((v) => v)
      switch (name) {
        case 'LE':
          return String(edgeInfo[0])
          break
        case 'BE':
          return String(edgeInfo[1])
          break
        case 'RE':
          return String(edgeInfo[2])
          break
        case 'TE':
          return String(edgeInfo[3])
          break
      }
    },
    createCanvas() {
      let doc = document.getElementById(
        `${this.plankIndex}printTag${this.index}`
      )
      let parent = doc.parentNode
      if (parent.className == 'canvas-container') {
        parent = doc.parentNode.parentNode
      }
      while (parent.firstChild) {
        parent.removeChild(parent.firstChild)
      }
      let newC = document.createElement('canvas')
      newC.className = 'exportCanvas'
      newC.id = `${this.plankIndex}printTag${this.index}`
      newC.setAttribute(
        'labelName',
        this.currentPlank ? this.currentPlank.plankNum : 1
      )
      parent.append(newC)
      this.mycanvas = null
    },
  },
  watch: {
    nowTagTem: {
      deep: true,
      handler: function (newV, oldV) {
        if (newV) {
          this.createCanvas()
          this.drawTem()
        } else {
          if (!this.plankScaleXY) {
            this.plankScaleXY = 105 / this.info.plankHeight
          }
          this.createCanvas()
          this.draw()
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
.tagTemp_wapper {
  overflow: hidden;
  border-radius: 4px;
  #qrCode {
    position: absolute;
    z-index: -1000;
  }
}
</style>
