import { nextTick, onMounted, onUnmounted } from 'vue'

export function useScroll(
  parentElement: string | HTMLElement,
  childrenElement: string | HTMLElement,
  cb: (type: 0 | 1, clear: (() => void) | null, e: MouseEvent) => void,
  scrollCb?: (clear: (() => void) | null, e: MouseEvent) => void,
  time?: number
) {
  let timer: undefined | NodeJS.Timeout = undefined
  const eventObj: Record<
    'clear' | 'handleScroll',
    ((...args: any) => void) | null
  > = {
    clear: null,
    handleScroll: null,
  }
  onMounted(() => {
    nextTick(() => {
      const parent =
        typeof parentElement === 'string'
          ? (document.body.querySelector(parentElement) as HTMLElement)
          : parentElement
      const children =
        typeof childrenElement === 'string'
          ? (parent?.querySelector(childrenElement) as HTMLElement)
          : childrenElement
      eventObj.handleScroll = (e: MouseEvent) => {
        const { scrollTop, offsetHeight } = parent
        const { offsetHeight: cHei } = children
        if (scrollTop + offsetHeight >= (cHei ?? -1)) {
          cb && cb(0, eventObj.clear, e)
        }
        if (scrollTop <= 0) {
          cb && cb(1, eventObj.clear, e)
        }
        if (time) {
          timer = timer
            ? (clearTimeout(timer) as undefined)
            : setTimeout(() => {
                scrollCb && scrollCb(eventObj.clear, e)
              }, time ?? 200)
        } else {
          scrollCb && scrollCb(eventObj.clear, e)
        }
      }
      // 取消监听，同时清除对象引用
      eventObj.clear = () => {
        parent?.removeEventListener('scroll', eventObj.handleScroll as any)
        eventObj.clear = null
        eventObj.handleScroll = null
        timer = undefined
      }
      parent?.addEventListener('scroll', eventObj.handleScroll)
    })
  })
  onUnmounted(() => {
    eventObj.clear && eventObj.clear()
  })
  return [eventObj]
}
