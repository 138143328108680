// 高光面/非高光面
export enum HighGlossSide {
  HighGloss = 1,
  NonHighGloss = 0,
}

// 正面孔槽位置信息
export enum HoleSlotPositionInfo {
  front = 1,
  back = -1,
}

// 侧面孔槽位置信息
export enum SideHoleSlotPositionInfo {
  left = 1,
  bottom = 2,
  right = 3,
  top = 4,
}
