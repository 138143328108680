import { httpRequest } from '@/apis/request'
import type { Page } from '@/apis/types'

enum Apis {
  trialFileRecord = 'production/trail/record',
  trialImageRecord = 'production/trail/image',
  lineSetting = 'get_production_line_setting',
  materialData = 'get_material_data',
}
// 获取试生产文件
export function getTrialFileRecord(params: { process_id: number } & Page) {
  return httpRequest.get(Apis.trialFileRecord, params)
}
export function getTrialImageRecord(params: { process_id: number } & Page) {
  return httpRequest.get(Apis.trialImageRecord, params)
}
export function getProductionLineSetting(isPreLayout?: number) {
  return httpRequest.get(
    Apis.lineSetting,
    isPreLayout ? { pre_layout: isPreLayout } : undefined
  )
}
export function getMaterialData(data: any) {
  return httpRequest.post(Apis.materialData, data)
}
