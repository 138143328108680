import { render, staticRenderFns } from "./home-old.vue?vue&type=template&id=15b89f60&scoped=true&"
import script from "./home-old.vue?vue&type=script&lang=js&"
export * from "./home-old.vue?vue&type=script&lang=js&"
import style0 from "./home-old.vue?vue&type=style&index=0&id=15b89f60&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15b89f60",
  null
  
)

export default component.exports