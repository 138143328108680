<template>
  <div class="msg-dialog">
    <div class="dialog-box">
      <div class="title">
        <span class="iconfont icon-warn"></span>
        <span class="warn-title">温馨提示</span>
      </div>
      <div class="msg-main">
        <span>确认要删除该数据项吗？</span>
      </div>
      <div class="msg-bottom">
        <div class="msg-bottom-btn">
          <a-button class="cancel-btn" @click="cancelBtn" id="material_cancel_delete_plank_btn">取消</a-button>
          <a-button class="confirm-btn" @click="confirmBtn" id="material_sure_delete_plank_btn">确定</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    confirmBtn() {
      this.$emit('confirm')
    },
    cancelBtn() {
      this.$emit('cancel')
    },
  },
}
</script>

<style scoped>
.msg-dialog {
  background: #0008;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.dialog-box {
  width: 424px;
  height: 170px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 33px 32px 24px 33px;
  border-radius: 2px;
}
.dialog-box .title {
  margin-bottom: 12px;
}
.dialog-box .title .warn-title {
  color: rgba(0, 0, 0, 0.85);
}
.dialog-box .title .icon-warn {
  color: rgba(250, 173, 20, 1);
  font-size: 16px;
}
.msg-main {
  margin-left: 39px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 24px;
}
.msg-bottom {
  display: flex;
  justify-content: flex-end;
}
.msg-bottom .cancel-btn {
  height: 32px;
  border-radius: 2px;
}
.msg-bottom .confirm-btn {
  height: 32px;
  border-radius: 2px;
  background: rgba(24, 168, 199, 1);
  color: #fff;
}
</style>
