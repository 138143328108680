/**
 * 检测夹手处是否存在小板
 */
import { HoleType, SlotType } from '@/partTypes'
import { rotatePart } from '@/util/LayoutTool'
import { dealPlankPoly } from '@/util/dealPaibanData'
import { PartType } from '@/views/materialsList/types/part'

type ClampHandDirectionType = 'L' | 'R' | 'T' | 'B'

type StartPositionType = '左下角' | '右上角' | '左上角' | '右下角'

interface PositionInfoType {
  x: number
  y: number
  width: number
  height: number
}

interface DealClampHandPartType {
  data: {
    clampHandHeight: number
    clampHandWidth: number
    clampHandMargin1: number
    clampHandMargin2: number
    clampHandDirection: ClampHandDirectionType
  }
  setting: {
    isXyReverse: boolean
    startPosition: StartPositionType
  }
  drawData: {
    data: {
      parts: PartType[]
      plankHeight: number
      plankWidth: number
      clampHandInfo: any[]
    }[]
  }[]
  options: { onlyCheck: boolean; diameter: number }
}

export function dealClampHandHasPart(payload: DealClampHandPartType) {
  const { data, drawData, setting, options } = payload
  const {
    clampHandDirection,
    clampHandHeight,
    clampHandWidth,
    clampHandMargin1,
    clampHandMargin2,
  } = data
  const { isXyReverse, startPosition } = setting
  const { onlyCheck, diameter } = options
  const conflictPart: PartType[] = []
  drawData.forEach((data) => {
    data.data.forEach((item, plankIndex) => {
      const { parts, plankHeight, plankWidth } = item
      const plankInfo = {
        plankWidth,
        plankHeight,
      }
      // 夹手1的位置信息
      const clamHand1Info = generateClampHandInfo({
        clampHandDirection,
        clampHandHeight,
        clampHandWidth,
        clampHandMargin: clampHandMargin1,
        plankWidth,
        plankHeight,
        isReverse: isXyReverse,
        startPosition,
      })
      // 夹手2的位置信息
      const clamHand2Info = generateClampHandInfo({
        clampHandDirection,
        clampHandHeight,
        clampHandWidth,
        clampHandMargin: clampHandMargin2,
        plankWidth,
        plankHeight,
        isReverse: isXyReverse,
        startPosition,
      })
      item.clampHandInfo = [clamHand1Info, clamHand2Info]
      // console.log('夹手1的位置信息', item.clampHandInfo[0])
      // console.log('夹手2的位置信息', item.clampHandInfo[1])
      parts?.forEach((part) => {
        const tempPart = {
          ...part,
        }
        const partPositionInfo = getPartPosition(tempPart, plankHeight)
        // 判断当前小板是否在夹手处
        if (clampHandHasTarget(partPositionInfo, [...item.clampHandInfo])) {
          // console.log(
          //   '第',
          //   plankIndex + 1,
          //   '块大板上的第',
          //   part.priority,
          //   '小板在夹手位置'
          // )
          const { needRotate, rotatedPart } = checkPartRotation(
            tempPart,
            plankInfo,
            item.clampHandInfo
          )
          // 旋转后的小板的孔槽不在夹手位置，满足要求合并旋转后的小板的孔槽和原来的小板的孔槽
          if (needRotate) {
            // console.log(
            //   `第${plankIndex + 1}块大板上的第${part.priority}块小板旋转了`
            // )
            // （下载nc时检测，不开启旋转）
            if (!onlyCheck) {
              Object.assign(part, rotatedPart)
            } else {
              // 由于是下载nc时做的检测，因此此时的小板无法旋转，所以将这个冲突小板做记录
              conflictPart.push(part)
            }
          } else {
            // 不需要旋转（1. 没有孔槽； 2. 存在孔槽，但是旋转后，夹手处也有孔槽。
            if (rotatedPart) {
              conflictPart.push(part)
            } else {
            }
          }
        } else {
        }
      })
    })
  })
  return conflictPart
}

// 根据小板信息，处理成需要的数据
function getPartPosition(part: PartType, plankHeight: number) {
  const { startX, startY, rect } = part
  return {
    x: startX + rect.width / 2,
    y: plankHeight - startY - rect.height / 2,
    width: rect.width,
    height: rect.height,
  }
}

// 生成夹手信息(用于绘制)
function generateClampHandInfo(params: ClampHandConfig) {
  const clampHandInfo = genClampHandPosition(params)
  return {
    // 用于碰撞检测
    width: clampHandInfo.width,
    height: clampHandInfo.height,
    x: clampHandInfo.x,
    y: clampHandInfo.y,
    // 用于绘制
    startX: clampHandInfo.x - clampHandInfo.width / 2,
    startY: params.plankHeight - clampHandInfo.y - clampHandInfo.height / 2,
    rect: {
      width: clampHandInfo.width,
      height: clampHandInfo.height,
    },
    realRect: {
      width: clampHandInfo.width,
      height: clampHandInfo.height,
    },
    isClampHand: true,
  }
}

interface ClampHandConfig {
  plankWidth: number // 大板宽度
  plankHeight: number // 大板高度
  clampHandHeight: number // 夹手高度
  clampHandWidth: number // 夹手宽度
  clampHandMargin: number // 夹手到边的距离
  clampHandDirection: ClampHandDirectionType
  isReverse: boolean
  startPosition: StartPositionType
}

// 生成夹手位置坐标
export function genClampHandPosition(data: ClampHandConfig) {
  // 需要转换的字段
  const needChangeField = [
    'clampHandHeight',
    'clampHandWidth',
    'clampHandMargin',
    'plankHeight',
    'plankWidth',
  ] as const
  const params = needChangeField.reduce(
    (acc, field) => {
      acc[field] = Number(data[field])
      return acc
    },
    { ...data } as ClampHandConfig
  )
  const { plankWidth, plankHeight, isReverse, clampHandMargin } = params
  const { clampHandHeight, clampHandWidth } = params

  // 开启xy互换，根据工位起始点的位置，分为两类(两条对角线)
  // 左下，右上对角线
  const diagonal1 = new Map([
    ['左下角', 'leftBottom'],
    ['右上角', 'rightTop'],
  ])
  // 左上，右下对角线
  const diagonal2 = new Map([
    ['左上角', 'leftTop'],
    ['右下角', 'rightBottom'],
  ])

  const diagonalMap1 = new Map<ClampHandDirectionType, ClampHandDirectionType>([
    ['L', 'B'],
    ['R', 'T'],
    ['T', 'R'],
    ['B', 'L'],
  ])
  const diagonalMap2 = new Map<ClampHandDirectionType, ClampHandDirectionType>([
    ['L', 'T'],
    ['R', 'B'],
    ['T', 'L'],
    ['B', 'R'],
  ])

  // 2. 确定实际方向
  const realDirection = isReverse
    ? diagonal1.has(params.startPosition)
      ? diagonalMap1.get(params.clampHandDirection)
      : diagonalMap2.get(params.clampHandDirection)
    : params.clampHandDirection
  // 3. 计算夹手位置和尺寸
  let finalWidth = clampHandWidth
  let finalHeight = clampHandHeight
  let x = 0,
    y = 0
  // 上下方向需要先交换宽高
  if (realDirection === 'T' || realDirection === 'B') {
    ;[finalHeight, finalWidth] = [finalWidth, finalHeight]
  }
  // 简化不同顶点时，对于位置的处理，统一使用中心点坐标
  switch (realDirection) {
    case 'L':
      x = 0 + finalWidth / 2
      y = clampHandMargin + finalHeight / 2
      break
    case 'R':
      x = plankWidth - finalWidth / 2
      y = clampHandMargin + finalHeight / 2
      break
    case 'T':
      x = clampHandMargin + finalWidth / 2
      y = plankHeight - finalHeight / 2
      break
    case 'B':
      x = clampHandMargin + finalWidth / 2
      y = 0 + finalHeight / 2
      break
  }
  return {
    x,
    y,
    width: finalWidth,
    height: finalHeight,
  }
}

// 检查小板是否需要旋转
function checkPartRotation(
  part: PartType,
  plankInfo: { plankWidth: number; plankHeight: number },
  clampHands: PositionInfoType[]
) {
  const { holes, slots } = part

  // 检查原始孔槽
  const hasHole = checkHoleSlots(
    part,
    plankInfo,
    holes,
    [...clampHands],
    'hole'
  )
  const hasSlot = checkHoleSlots(
    part,
    plankInfo,
    slots,
    [...clampHands],
    'slot'
  )

  if (!hasHole && !hasSlot) {
    // console.log('夹手位置没有孔,槽')
    return { needRotate: false }
  }

  // 检查旋转后的孔槽
  const rotatedPart = dealRotatePart(part, 2)
  const newHasHole = checkHoleSlots(
    part,
    plankInfo,
    rotatedPart.holes,
    [...clampHands],
    'hole'
  )
  const newHasSlot = checkHoleSlots(
    part,
    plankInfo,
    rotatedPart.slots,
    [...clampHands],
    'slot'
  )

  return {
    needRotate: !(newHasHole || newHasSlot),
    rotatedPart,
  }
}

// 判断夹手位置处是否有目标物
export function checkClampHand(
  targetPosition: PositionInfoType,
  clampHandPosition: PositionInfoType
) {
  const {
    x: targetX,
    y: targetY,
    width: targetWidth,
    height: targetHeight,
  } = targetPosition
  const {
    x: clampX,
    y: clampY,
    width: clampWidth,
    height: clampHeight,
  } = clampHandPosition

  // 计算两个矩形的边界
  const targetLeft = targetX - targetWidth / 2
  const targetRight = targetX + targetWidth / 2
  const targetTop = targetY + targetHeight / 2
  const targetBottom = targetY - targetHeight / 2

  const clampLeft = clampX - clampWidth / 2
  const clampRight = clampX + clampWidth / 2
  const clampTop = clampY + clampHeight / 2
  const clampBottom = clampY - clampHeight / 2

  // 检查是否没有重叠
  if (
    targetLeft > clampRight || // 目标在夹手右侧
    targetRight < clampLeft || // 目标在夹手左侧
    targetBottom > clampTop || // 目标在夹手上方
    targetTop < clampBottom // 目标在夹手下方
  ) {
    return false
  }

  return true
}

// 检测函数
export function clampHandHasTarget(
  targetPosition: PositionInfoType,
  clamHandsInfo: PositionInfoType[]
) {
  return (
    checkClampHand(targetPosition, clamHandsInfo[0]) ||
    checkClampHand(targetPosition, clamHandsInfo[1])
  )
}

// 孔槽检测
export function checkHoleSlots(
  part: PartType,
  plankInfo: {
    plankWidth: number
    plankHeight: number
  },
  target: Array<HoleType | SlotType>,
  clamHands: PositionInfoType[],
  type: 'hole' | 'slot'
) {
  const result = target.find((hole) => {
    const targetPosition = dealHoleSlotPosition(part, hole, plankInfo, type)
    if (clampHandHasTarget(targetPosition, [...clamHands])) return true
  })
  return !!result
}

// 旋转板子: 每次默认旋转90°
export function dealRotatePart(part: PartType, rorateTimes = 1) {
  const newPart = JSON.parse(JSON.stringify(part))
  const _recurDeal = (part: PartType, rorateTimes: number) => {
    rotatePart(part)
    dealPlankPoly(part)
    // 需要旋转的次数
    if (rorateTimes >= 1) {
      _recurDeal(part, rorateTimes - 1)
    }
  }
  _recurDeal(newPart, rorateTimes - 1)
  return newPart as PartType
}

// 处理孔槽坐标
export function dealHoleSlotPosition(
  part: PartType,
  data: HoleType | SlotType,
  plankInfo: {
    plankHeight: number
    plankWidth: number
  },
  type: 'hole' | 'slot'
) {
  if (type === 'hole') {
    return dealHolePosition(part, data as HoleType, plankInfo)
  } else {
    return dealSlotPosition(part, data as SlotType, plankInfo)
  }
}

// 处理孔坐标: 孔槽坐标都是相对于小板左上角的坐标，需要转换成相对于大板左下角的坐标
export function dealHolePosition(
  part: PartType,
  hole: HoleType,
  plankInfo: {
    plankHeight: number
    plankWidth: number
  }
) {
  const { plankHeight } = plankInfo
  const { startX, startY } = part
  const { center, diameter } = hole
  const targetPosition = {
    x: startX + center.x,
    y: plankHeight - startY - center.y,
    width: diameter,
    height: diameter,
  }
  // console.log('小孔相对大板坐标', targetPosition)
  return targetPosition
}

export function dealSlotPosition(
  part: PartType,
  slot: SlotType,
  plankInfo: {
    plankHeight: number
    plankWidth: number
  }
) {
  const { width: slotWidth, pt1, pt2 } = slot
  const { startX, startY } = part
  const { plankHeight } = plankInfo

  // 判断是否为竖槽
  const isVerticalSlot = pt1.x === pt2.x
  // 计算槽的长度
  const length = isVerticalSlot
    ? Math.abs(pt1.y - pt2.y)
    : Math.abs(pt1.x - pt2.x)

  // 根据槽的方向确定最终的宽度和高度
  const [finalWidth, finalHeight] = isVerticalSlot
    ? [slotWidth, length]
    : [length, slotWidth]

  const position = isVerticalSlot
    ? {
        x: startX + pt1.x,
        y: plankHeight - startY - Math.min(pt1.y, pt2.y) - length / 2,
        width: finalWidth,
        height: finalHeight,
      }
    : {
        x: startX + Math.min(pt1.x, pt2.x) + length / 2,
        y: plankHeight - startY - pt1.y,
        width: finalWidth,
        height: finalHeight,
      }

  // console.log('槽相对于大板坐标', position)
  return position
}
