import { render, staticRenderFns } from "./m-machine-pos.vue?vue&type=template&id=10ab5468&scoped=true&"
import script from "./m-machine-pos.vue?vue&type=script&setup=true&lang=ts&"
export * from "./m-machine-pos.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./m-machine-pos.vue?vue&type=style&index=0&id=10ab5468&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10ab5468",
  null
  
)

export default component.exports