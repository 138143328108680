import JsZip from '@/util/jszip'
import zipUtil from 'jszip-utils'

export function getZipJson(url) {
  return new Promise((resolve, error) => {
    zipUtil.getBinaryContent(url, function (err, data) {
      var zip = new JsZip()
      zip.loadAsync(data).then((res) => {
        let promises = []
        let fileArr = []
        for (let key in res.files) {
          //判断是否是目录
          if (!res.files[key].dir) {
            //找到我们压缩包所需要的json文件
            if (/\.(json)$/.test(res.files[key].name)) {
              let cahe = res
                .file(res.files[key].name)
                .async('string')
                .then((content) => {
                  //得到我们需要的JSON文件内容
                  fileArr.push(content)
                })
              promises.push(cahe)
            }
          }
        }
        Promise.all(promises).then((res) => {
          resolve(fileArr)
        })
      })
    })
  }).catch((err) => {
    console.error(err)
  })
}
