<template>
  <div>
    <div class="footer">
      <div class="container">
        <ul class="flex-container">
          <li>
            <div class="title">联系我们</div>
            <!-- <p>总部：江苏新格尔人居科技有限公司</p> -->
            <p>地址：江苏省无锡市滨湖区绣溪50号</p>
            <p style="padding-left: 42px">K-park服务中心1号楼16楼</p>
            <p>电话：0510-85186908</p>
            <!-- <p>子公司：成都亿格人居科技有限公司</p>
            <p>地址：成都市 &nbsp;&nbsp; 新都区龙桥柜柜云仓</p>
            <p>集团投诉：18651577877</p> -->
          </li>
          <li>
            <div class="title">新格尔核心业务</div>
            <p>
              <a href="http://menccc.com" target="_blank">门窗软件事业部</a>
            </p>
            <p>
              <a href="https://www.eggrj.com/" target="_blank"
                >家具软件事业部</a
              >
            </p>
            <p><a href="http://shybm.cn" target="_blank">装配建筑事业部</a></p>
            <!-- <p><a href="http://bl.eggi.cn" target="_blank">机器人事业部</a></p> -->
            <p>
              <a href="http://thinkerx.com" target="_blank">新格尔总部官网</a>
            </p>
          </li>
          <li>
            <div class="title">新格尔旗下品牌</div>
            <p>材神金服</p>
            <p>门图网</p>
            <p>鼎鑫投资</p>
            <p>三张图科技</p>
            <p>上海粉墨科技</p>
          </li>
          <li>
            <div class="title">新格尔全球分支机构</div>
            <p>新格尔-华南局（佛山分公司）</p>
            <p>新格尔-北方局（临沂分公司）</p>
            <p>新格尔-西南局（成都分公司）</p>
            <p>新格尔-柜柜西部服务中心</p>
            <p>新格尔-柜柜东部服务中心</p>
            <p>新格尔-代理商集群</p>
            <p>新格尔-国际市场部</p>
          </li>
          <li>
            <div class="title">扫扫"关注柜柜"</div>
            <img
              src="../assets/image/guiguicode.jpg"
              style="width: 140px; height: 140px"
            />
          </li>
        </ul>
        <p class="friend-link">友情链接</p>
        <p class="friend-link">
          <a href="http://www.thinkerx.com/offical/home/index" target="_blank"
            >新格尔软件</a
          >
          <a href="http://kevke.com/kshow/index.php?r=main" target="_blank"
            >K秀导购软件</a
          >
          <a href="http://www.mentuwang.com" target="_blank">门图网</a>
          <a href="http://www.thinkerx.com/offical/guigui" target="_blank"
            >全铝柜软件</a
          >
          <a href="http://xiazai.zol.com.cn/" target="_blank" rel="nofollow"
            >ZOL软件下载</a
          >
          <a href="https://www.onlinedown.net/" target="_blank" rel="nofollow"
            >华军软件园</a
          >
          <a href="http://www.downza.cn" target="_blank" rel="nofollow"
            >下载之家</a
          >
          <a href="http://xiazai.zol.com.cn/" target="_blank" rel="nofollow"
            >ZOL软件下载</a
          >
          <a href="http://www.downcc.com/" target="_blank" rel="nofollow"
            >绿色资源网</a
          >
          <a href="http://yigui.jiameng.com/" target="_blank">衣柜加盟</a>
          <a href="http://jiaju.91jm.com/" target="_blank">家居加盟</a>
          <a href="https://meibiao.chinabm.cn/" target="_blank">美标卫浴加盟</a>
          <a href="https://www.thinkerx.com/newsDetail/4948" target="_blank">清朗行动(新闻)</a>
        </p>
        <p class="text-center bottom-text">
          <span>2018</span>
          <span>SiteStar,All Rights Reserved.</span>
          <span>江苏新格尔人居科技有限公司</span>
          <span
            ><a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              target="_blank"
              >苏ICP备14034668号</a
            ></span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
@media screen and (max-width: 1024px) {
  .footer {
    display: none;
  }
}
.footer {
  padding: 45px 0 20px;
  color: #ffffff;
  font-size: 14px;
  background: #19233e;
  .container {
    min-width: 1024px;
    max-width: 1440px;
    margin: 0 auto;
    li {
      list-style: none;
      .title {
        margin-bottom: 22px;
        padding-left: 5px;
        font-size: 16px;
        border-left: 4px solid #3399ff;
        cursor: default;
      }
    }
    p {
      margin: 0;
      line-height: 42px;
      cursor: default;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
    .friend-link {
      line-height: 30px;
      > * {
        margin-right: 15px;
        color: #ffffff;
      }
      a {
        text-decoration: none;
      }
    }
    .bottom-text {
      margin-top: 30px;
      color: #3e4a5a;
      span + span {
        margin-right: 20px;
      }
      a {
        color: #3e4a5a;
      }
    }
    .text-center {
      text-align: center;
    }
    .flex-container {
      display: flex;
      display: -webkit-flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: space-between;
      justify-content: space-between;
    }
  }
}
</style>
