import { render, staticRenderFns } from "./g-detail-pop.vue?vue&type=template&id=a814c5d0&scoped=true&"
import script from "./g-detail-pop.vue?vue&type=script&lang=ts&setup=true&"
export * from "./g-detail-pop.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./g-detail-pop.vue?vue&type=style&index=0&id=a814c5d0&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a814c5d0",
  null
  
)

export default component.exports