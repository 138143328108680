import { dealPlankPoly } from '@/util/dealPaibanData'
import {
  checkPlankOutlineKey,
  judgePart1ContainPart2,
} from '@/util/plankCommonFuncs'
import { PartType } from '@/views/materialsList/types/part'

// 判断小板是否嵌套在另一块板中(画矩形判断)
export const isInOtherPart = (part: PartType, otherPart: PartType) => {
  const { startX, startY, specHeight, specWidth } = part
  const {
    startX: partStartX,
    startY: partStartY,
    specHeight: partSpecHeight,
    specWidth: partSpecWidth,
  } = otherPart
  if (
    partStartX < startX &&
    partStartX + partSpecWidth > startX + specWidth &&
    partStartY < startY &&
    partStartY + partSpecHeight > startY + specHeight
  )
    return true
  return false
}

// 对单块大板上的小板的嵌套进行判断
// export const isInOtherParts = (part: PartType, parts: PartType[]) => {
//   const result: PartType[] = []
//   for (const otherPart of parts) {
//     const res = isInOtherPart(part, otherPart)
//     if (res) result.push(res)
//   }
//   return result
// }

// 判断小板是否存在深度嵌套,找到最近的一层嵌套
export const deepIsInOtherParts = (parts: PartType[]) => {
  // 获取所有的priority
  const priorities = parts.map((part) => part.priority)
  // 获取所有的InPlankStockNum
  const inPlankStockNums = parts.map((part) => part.inPlankStockNum)

  // 由于每块小板的inPlankStockNum都指向了最近一层嵌套父级的priority
  // 因此只需要将inPlankStockNums和priorities合并，然后去重即可
  const res = Array.from(new Set([...inPlankStockNums, ...priorities])).find(
    (item) => item
  )
  return parts.find((part) => part.priority === res)
}

// 筛选大板上嵌套的小板
export function filterNestedParts(parts: PartType[]) {
  const hasEffectPartIds = []
  const map = new Map<string, string[]>()
  for (const part of parts) {
    hasEffectPartIds.push(part.partUniqueId)
    for (const _part of parts) {
      if (!checkPlankOutlineKey(_part)) {
        dealPlankPoly(_part)
      }
      let innerPart: PartType | null = null
      let outerPart: PartType | null = null
      if (
        part.partUniqueId === _part.partUniqueId ||
        hasEffectPartIds.includes(_part.partUniqueId) ||
        (!isInOtherPart(_part, part) && !isInOtherPart(part, _part))
      )
        continue
      if (judgePart1ContainPart2(part, _part)) {
        innerPart = _part
        outerPart = part
      } else if (judgePart1ContainPart2(_part, part)) {
        innerPart = part
        outerPart = _part
      }
      if (innerPart && outerPart) {
        const innerPartUniqueId = innerPart.partUniqueId
        const outerPartUniqueId = outerPart.partUniqueId
        if (!map.get(innerPartUniqueId)) map.set(innerPartUniqueId, [])
        map.set(
          innerPartUniqueId,
          map.get(innerPartUniqueId)!.concat(outerPartUniqueId)
        )
      }
    }
  }
  getParentPart(parts, map)
}

// 根据获取到的嵌套关系，得到每块小板的最近一层的父级
export const getParentPart = (
  parts: PartType[],
  relationMap: Map<string, string[]>
) => {
  relationMap.forEach((value, key) => {
    const parentKey = findParentKey([...value, key], relationMap)
    const part = parts.find((part) => part.partUniqueId === key)
    const parentPart = parts.find((part) => part.partUniqueId === parentKey)
    if (parentPart && part) {
      part.inPlankStockNum = parentPart.priority as number
    }
  })
}

// 根据小板上的嵌套关系，获取到这一次关系层级上的所有的父级，然后去重
export const findParentKey = (
  keys: string[],
  relationMap: Map<string, string[]>
) => {
  const value: string[] = []
  keys.forEach((key) => {
    const val = relationMap.get(key)
    if (val) value.push(...val)
  })
  // 寻找value中只存在一个的key
  return value.find((key) => value.filter((item) => item === key).length === 1)
}
